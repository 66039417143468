import { LocalizationProvider, MomentUtils, TextField, MuiTimePicker } from "@toolkit/ui";
import React, { FC } from "react";

type AppointmentDateProps = {
  label: string;
  value: string | null;
  onChange: (time: string | null) => void;
};

export const AppointmentDateTime: FC<AppointmentDateProps> = props => {
  const { label, value, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <MuiTimePicker
        label={label}
        value={value}
        onChange={newVal => onChange(newVal)}
        views={["hours", "minutes"]}
        renderInput={params => <TextField {...params} sx={{ width: "100%" }} />}
      />
    </LocalizationProvider>
  );
};
