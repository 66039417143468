import { useOpenState } from "@toolkit/core";
import { AuditHistoryDrawer, Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { OrderEventsTimeline, OrdersEventsType } from "./OrderEventsTimeline";
import { useTranslation } from "@toolkit/i18n";

export const OrderEventsDrawer: FC<{ orderId?: string; events: OrdersEventsType }> = ({ orderId, events }) => {
  const { handleClose, handleOpen, open } = useOpenState();
  const { t } = useTranslation();
  return (
    <AuditHistoryDrawer open={open} onClickOpen={handleOpen} onClickClose={handleClose}>
      <Box padding='14px' height='100vh' overflow='auto' id='scrollableDiv'>
        <Typography fontWeight='bold' fontSize={20} gutterBottom>
          {t("Order ID")}: {orderId || ""}
        </Typography>

        <OrderEventsTimeline events={events} />
      </Box>
    </AuditHistoryDrawer>
  );
};
