import { FC } from "react";
import { BenefitItem, useBenefitsListColumns } from "./useBenefitsListColumns";
import { CustomTable } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const BenefitsList: FC<{
  benefits: BenefitItem[];
}> = props => {
  const { benefits } = props;
  const { t } = useTranslation("optima");
  const benefitsListColumns = useBenefitsListColumns();
  return <CustomTable data={benefits || []} columns={benefitsListColumns} title={t("Benefits")} />;
};
