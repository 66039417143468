import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CallInstance, CallParticipant } from "../ZoomVideo";
import { useAddSnapshotToMeetingMutation } from "../../gql/mutations";
import { getMediaLink, useFileUpload } from "@toolkit/core";
import { CallContext } from "./CallContext";

export const useParticipantTakePhoto = ({ call, participant }: { call: CallInstance; participant?: CallParticipant }) => {
  const [snapshotFile, setSnapshotFile] = useState<Blob | null>(null);
  const [addSnapshotToMeetingMutation] = useAddSnapshotToMeetingMutation();
  const snapshotUrl = useMemo(() => (snapshotFile ? URL.createObjectURL(snapshotFile) : null), [snapshotFile]);
  const { accessToken } = useContext(CallContext);
  const { fetchUploadFile } = useFileUpload({ token: accessToken });

  const participantId = participant?.id;
  const userIdentity = participant?.userIdentity;
  const takeSnapshot = useCallback(() => {
    if (!participantId) {
      return null;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return null;
    }

    const viewId = `participant_view_${participant.id}`;
    const video = document.querySelector<HTMLVideoElement | HTMLCanvasElement>(`#${viewId} video, #${viewId} canvas`);
    if (!video) {
      return null;
    }

    const source = {
      video,
      width: video.clientWidth ?? video.width,
      height: video.clientHeight ?? video.height,
    };

    canvas.width = Math.max(source.width, source.width < source.height ? 480 : 640);
    canvas.height = (canvas.width * source.height) / source.width;
    ctx.drawImage(source.video, 0, 0, canvas.width, canvas.height);

    return new Promise(r => {
      canvas.toBlob(blob => {
        setSnapshotFile(blob);
        r(blob);
      }, "image/png");
    });
  }, [participantId]);

  const saveSnapshot = useCallback(async () => {
    try {
      if (!snapshotFile || !call.callId) {
        return;
      }

      const file = new File([snapshotFile], `snapshot_${call.callId}_${userIdentity}.png`, { type: "image/png" });
      const fileName = await fetchUploadFile({ files: [file] });
      const response = await addSnapshotToMeetingMutation({
        variables: {
          input: {
            meetingId: call.callId,
            snapshot: getMediaLink(fileName),
          },
        },
      });

      return response.data?.addSnapshotToMeeting;
    } catch (e) {
      console.error(e);
    }
  }, [addSnapshotToMeetingMutation, call.callId, userIdentity, snapshotFile]);

  useEffect(() => {
    if (!participantId) {
      return;
    }

    takeSnapshot();
  }, [takeSnapshot, participantId]);

  return { snapshot: snapshotUrl, takeSnapshot, saveSnapshot };
};
