import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyActionsStyle = makeStyles()(() => ({
  root: {
    display: "flex",
    gap: 16,
  },
  iconButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
}));
