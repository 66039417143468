import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { ExtractNodeType } from "@toolkit/apollo";
import { useSiteSettingsGetQuery } from "shared/gql/queries";
import { OptimaRemittanceActivitiesQuery } from "pages/OptimaClaims/gql";
import { toTitleCase } from "@toolkit/core";
export const useRemittanceActivitiesListColumns = (): CustomTableColumnProps<ExtractNodeType<OptimaRemittanceActivitiesQuery>>[] => {
  const { t } = useTranslation("domains");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";
  const theme = useTheme();

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code, codeLookup }) => <TruncateTypography text={toTitleCase(codeLookup?.name || code || "-")} />,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type, typeLookup }) => <TruncateTypography text={typeLookup?.name || (type ?? "").toString() || "-"} />,
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: "quantity",
      },
      {
        key: "netAmount",
        header: t("Net Amount"),
        accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
      },
      {
        key: "paymentAmount",
        header: t("Payment Amount"),
        accessor: ({ paymentAmount }) => (paymentAmount ? paymentAmount + " " + defaultCurrency : "-"),
      },
      {
        key: "patientShare",
        header: t("patientShare"),
        accessor: ({ patientShare }) => (patientShare ? patientShare + " " + defaultCurrency : "-"),
      },
      {
        key: "activityStatus",
        header: t("status"),
        accessor: ({ denialCode }) =>
          denialCode ? (
            <Typography color={theme.palette.error.main}>{t("Rejected")}</Typography>
          ) : (
            <Typography color={theme.palette.success.main}>{t("Approved")}</Typography>
          ),
      },
      {
        key: "denialCode",
        header: t("Denial Code"),
        accessor: ({ denialLookup, denialCode }) => <TruncateTypography text={denialLookup?.name || denialCode || "-"} />,
      },
    ];
  }, [t]);
};
