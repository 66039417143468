import { GuidedCareHealthProgramRiskFactorTemplateEvaluation } from "@/schema/types";
import React, { FC } from "react";
import { Button, CircularProgress, Grid, useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useGuidedCareProgramEvaluateMutation } from "../../gql";
import { convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues } from "../../others";
import { GuidedCareProgramEvaluationResult } from "../GuidedCareProgramEvaluationResult/GuidedCareProgramEvaluationResult";
import {
  GuidedCareProgramEvaluationFields,
  IGuidedCareProgramEvaluationFieldsEvent,
} from "../GuidedCareProgramEvaluationFields/GuidedCareProgramEvaluationFields";
import { IGuidedCareProgramMemberAddFormValues } from "../GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddFormSchema";

export type IGuidedCareProgramEvaluationFormEvent =
  | IGuidedCareProgramEvaluationFieldsEvent
  | {
      type: "EVALUATE_RESULT";
      payload: {
        value: GuidedCareHealthProgramRiskFactorTemplateEvaluation;
      };
    };

type GuidedCareProgramEvaluationFormProps = {
  visitId?: string;
  memberId?: string;
  isEvaluateButtonShown?: boolean;
  evaluationResult?: GuidedCareHealthProgramRiskFactorTemplateEvaluation;
  onChange: (event: IGuidedCareProgramEvaluationFormEvent) => void;
};

export const GuidedCareProgramEvaluationForm: FC<GuidedCareProgramEvaluationFormProps> = props => {
  const { visitId, memberId, isEvaluateButtonShown, evaluationResult, onChange } = props;

  const { t } = useTranslation("provider");

  const { failed } = useAddToast();

  const form = useCustomFormContext<IGuidedCareProgramMemberAddFormValues>();

  const { watch, trigger, isFormDisabled } = form;

  const program = watch("program");
  const riskFactorTemplateFields = watch("riskFactorTemplateFields");

  const [evaluateGuidedCareProgram, { loading }] = useGuidedCareProgramEvaluateMutation({
    onCompleted: mutationData => {
      if (mutationData?.evaluateGuidedCareProgramRiskFactorTemplate) {
        onChange({
          type: "EVALUATE_RESULT",
          payload: {
            value: mutationData?.evaluateGuidedCareProgramRiskFactorTemplate,
          },
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onEvaluateClick = async () => {
    const isValid = await trigger(["riskFactorTemplateFields"]);

    if (!isValid) return;

    evaluateGuidedCareProgram({
      variables: {
        input: {
          visitId,
          memberId,
          programId: program?.value?.id,
          result: convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues(riskFactorTemplateFields),
        },
      },
    });
  };

  const onGuidedCareProgramEvaluationFieldsChange = (event: IGuidedCareProgramEvaluationFieldsEvent) => {
    if (event.type === "EDIT_CLICK") {
      onChange(event);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GuidedCareProgramEvaluationFields
          fields={riskFactorTemplateFields}
          hasEvaluationResult={!!evaluationResult}
          onChange={onGuidedCareProgramEvaluationFieldsChange}
        />
      </Grid>

      {evaluationResult && (
        <Grid item xs={12}>
          <GuidedCareProgramEvaluationResult evaluationResult={evaluationResult} />
        </Grid>
      )}

      {isEvaluateButtonShown && (
        <Grid item xs={12}>
          <Button
            variant={"contained"}
            disabled={loading || !!evaluationResult || isFormDisabled}
            endIcon={loading ? <CircularProgress color={"inherit"} size={18} /> : null}
            onClick={onEvaluateClick}
          >
            {t("Evaluate")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
