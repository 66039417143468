import { PatientEligibilityRequestHistoryCountableConnection } from "@/schema/types";
import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
type BenefitItem = NonNullable<
  ExtractNodeType<PatientEligibilityRequestHistoryCountableConnection>["patientEligibility"]["benefits"]
>[number];
export type CoverageItem = NonNullable<NonNullable<NonNullable<BenefitItem>["coverage"]>[number]>;

export const useCoverageListColumns = (): CustomTableColumnProps<CoverageItem>[] => {
  const { t } = useTranslation("optima");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Id"),
        accessor: "id",
      },
      {
        key: "roomType",
        header: t("Room Type"),
        accessor: "roomType",
      },
      {
        key: "deductiblePercent",
        header: t("Deductible Percent"),
        accessor: "deductiblePercent",
      },
      {
        key: "deductibleMax",
        header: t("Deductible Max"),
        accessor: "deductibleMax",
      },
      {
        key: "deductibleAmount",
        header: t("Deductible Amount"),
        accessor: "deductibleAmount",
      },
      {
        key: "copayPercent",
        header: t("Copay Percent"),
        accessor: "copayPercent",
      },
      {
        key: "copayMax",
        header: t("Copay Max"),
        accessor: "copayMax",
      },
      {
        key: "copayAmount",
        header: t("Copay Amount"),
        accessor: "copayAmount",
      },
      {
        key: "allowedUnits",
        header: t("Allowed Units"),
        accessor: "allowedUnits",
      },
      {
        key: "allowedMoney",
        header: t("Allowed Money"),
        accessor: "allowedMoney",
      },
    ];
  }, [t]);
};
