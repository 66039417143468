/* eslint-disable max-lines */
import { GuidedCareHealthProgramRiskFactorTemplateEvaluation } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import {
  IGuidedCareProgramMemberAddFormValues,
  guidedCareProgramMemberAddFormDefaultValues,
  guidedCareProgramMemberAddFormSchema,
} from "./GuidedCareProgramMemberAddFormSchema";
import { FormProvider } from "react-hook-form";
import { Box, Button, CircularProgress, Grid, useAddToast } from "@toolkit/ui";
import { GuidedCareProgramsAutocomplete, GuidedCareTeamsAutocomplete } from "@health/autocompletes";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useGuidedCareProgramInviteMutation, useGuidedCareProgramGetQuery, useGuidedCareProgramTeamEnrollMutation } from "../../gql";
import { convertRiskFactorTemplateFieldsToFormValues } from "../../others";
import {
  GuidedCareProgramEvaluationForm,
  IGuidedCareProgramEvaluationFormEvent,
} from "../GuidedCareProgramEvaluation/GuidedCareProgramEvaluationForm";
import { useGuidedCareProgramMemberAddFormStyle } from "./useGuidedCareProgramMemberAddFormStyle";

type GuidedCareProgramMemberAddFormProps = {
  patientId: string;
  patientUserId: string;
  visitId?: string;
  payerId: string;
  patientDefaultMemberId?: string;
  isFormDisabled?: boolean;
  onChange?: () => void;
};

export const GuidedCareProgramMemberAddForm: FC<GuidedCareProgramMemberAddFormProps> = props => {
  const { payerId, visitId, patientId, patientUserId, patientDefaultMemberId, isFormDisabled, onChange } = props;

  const [evaluationResult, setEvaluationResult] = useState<GuidedCareHealthProgramRiskFactorTemplateEvaluation>();

  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const { classes } = useGuidedCareProgramMemberAddFormStyle();

  const form = useCustomForm<IGuidedCareProgramMemberAddFormValues>({
    defaultValues: guidedCareProgramMemberAddFormDefaultValues,
    schema: guidedCareProgramMemberAddFormSchema,
  });

  const { watch, setValue, handleSubmit, setError, reset, setFormDisabled } = form;

  const program = watch("program")?.value;
  const suggestedProgram = watch("suggestedProgram")?.value;
  const team = watch("team")?.value;
  const riskFactorTemplateFields = watch("riskFactorTemplateFields");

  const selectedProgram = suggestedProgram || program;

  const isEvaluateButtonShown = !!riskFactorTemplateFields?.length && (!evaluationResult || (evaluationResult && !suggestedProgram));

  const { data, loading } = useGuidedCareProgramGetQuery({
    variables: {
      id: program?.id,
    },
    skip: !program?.id,
  });

  const guidedCareProgramTemplateRiskFactorFields =
    data?.guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.riskFactorTemplate?.fields;

  const isDoctorDirectEnrollmentAllowed =
    !!suggestedProgram?.allowDoctorDirectEnrollment || !!data?.guidedCareHealthProgram?.allowDoctorDirectEnrollment;

  const [inviteGuidedCareProgram, { loading: isInvitingLoadingSubmitting }] = useGuidedCareProgramInviteMutation({
    onCompleted: mutationData => {
      if (mutationData?.addMemberToProgram?.id) {
        succeeded(t("Added to program successfully"));
        reset();
        onChange?.();
      } else {
        failed(t("Failed to add to program"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [enrollGuidedCareProgramTeam, { loading: isTeamPatientEnrollSubmitting }] = useGuidedCareProgramTeamEnrollMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.enrollPatientToTeam?.providerGuidedCareHealthProgramTeamErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Patient enrolled to the team successfully"));
        reset();
        onChange?.();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const isSubmitting = isInvitingLoadingSubmitting || isTeamPatientEnrollSubmitting;

  const onGuidedCareProgramEvaluationFormChange = (event: IGuidedCareProgramEvaluationFormEvent) => {
    if (event.type === "EVALUATE_RESULT") {
      setEvaluationResult(event.payload.value);
    } else if (event.type === "EDIT_CLICK") {
      setEvaluationResult(undefined);
      setValue("suggestedProgram", undefined);
      setValue("team", undefined);
    }
  };

  const onSubmit = (values: IGuidedCareProgramMemberAddFormValues) => {
    if (values?.suggestedProgram?.value?.allowDoctorDirectEnrollment || isDoctorDirectEnrollmentAllowed) {
      if (!team) {
        return setError("team", {
          type: "required",
          message: t("Required"),
        });
      }

      enrollGuidedCareProgramTeam({
        variables: {
          input: {
            teamId: team?.id!,
            patientId,
            patientUserId,
          },
        },
      });
    } else {
      inviteGuidedCareProgram({
        variables: {
          input: {
            visitId,
            memberId: visitId ? undefined : patientDefaultMemberId,
            guidedCareProgramId: values?.suggestedProgram ? values?.suggestedProgram?.value?.id! : values?.program?.value?.id,
          },
        },
      });
    }
  };

  useEffect(() => {
    setFormDisabled(!!isFormDisabled);
  }, [isFormDisabled, setFormDisabled]);

  useEffect(() => {
    if (guidedCareProgramTemplateRiskFactorFields) {
      const _riskFactorTemplateFields = convertRiskFactorTemplateFieldsToFormValues(guidedCareProgramTemplateRiskFactorFields);
      setValue("riskFactorTemplateFields", _riskFactorTemplateFields);
    } else {
      setValue("riskFactorTemplateFields", []);
      setEvaluationResult(undefined);
    }
  }, [guidedCareProgramTemplateRiskFactorFields, setValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GuidedCareProgramsAutocomplete name={"program"} skip={!payerId} filter={{ payerId: payerId, isActive: true }} />
          </Grid>

          {loading ? (
            <Box className={classes.loadingWrapper}>
              <CircularProgress color={"inherit"} size={30} />
            </Box>
          ) : (
            <>
              {!!riskFactorTemplateFields?.length && (
                <Grid item xs={12}>
                  <GuidedCareProgramEvaluationForm
                    visitId={visitId}
                    memberId={patientDefaultMemberId}
                    isEvaluateButtonShown={isEvaluateButtonShown}
                    evaluationResult={evaluationResult}
                    onChange={onGuidedCareProgramEvaluationFormChange}
                  />
                </Grid>
              )}
            </>
          )}

          {!isEvaluateButtonShown && (
            <>
              {isDoctorDirectEnrollmentAllowed && (
                <Grid item xs={12}>
                  <GuidedCareTeamsAutocomplete
                    name={"team"}
                    disabled={!selectedProgram?.id}
                    filter={{ guidedCareHealthProgramId: selectedProgram?.id }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  variant={"contained"}
                  type={"submit"}
                  disabled={isSubmitting || isFormDisabled}
                  endIcon={isSubmitting ? <CircularProgress color={"inherit"} size={18} /> : null}
                >
                  {isDoctorDirectEnrollmentAllowed ? t("Enroll") : t("Invite")}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};
