import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useGetUserChatGroupMutation } from "./gql";
import { AnonymousChatGroup } from "@/schema/types";
import { useOpenState } from "@toolkit/core";

export const useChatHooks = () => {
  const auth = useAuth();
  const [chatGroup, setChatGroup] = useState<AnonymousChatGroup>();
  const { open, handleOpen, handleToggle } = useOpenState();

  const authenticated = auth.isAuthenticated || false;

  const { failed } = useAddToast();

  const [getChatLinkMutation, { loading: isGetChatLinkLoading }] = useGetUserChatGroupMutation({
    onCompleted: async response => {
      const error = response?.saveOrFetchExistedAnonymousChatGroup?.error;
      if (error?.length) {
        failed(error);
      } else {
        const value = response?.saveOrFetchExistedAnonymousChatGroup as AnonymousChatGroup;
        if (value) {
          setChatGroup(value);
          handleOpen();
        }
      }
    },
  });

  const startChat = (userId: string) => {
    if (authenticated) {
      userId &&
        getChatLinkMutation({
          variables: {
            userIds: [userId],
          },
        });
    } else {
      auth.signinRedirect();
    }
  };

  return { startChat, chatGroup, open, handleToggle, isGetChatLinkLoading };
};
