import { MedicalForm } from "@/schema/types";
import { formGirdSpacing, useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { MedicalFormClassificationsForm, MedicalFormInformationForm, MedicalFormQuestionsForm } from "pages/MedicalForms/forms";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertMedicalFormToFormValues } from "../../others";
import { IMedicalFormUpsertFormEvent } from "../../types";
import {
  IMedicalFormUpsertFormValues,
  medicalFormUpsertFormDefaultValues,
  medicalFormUpsertFormSchema,
} from "./MedicalFormUpsertFormSchema";
import { useMedicalFormUpsertFormStyle } from "./useMedicalFormUpsertFormStyle";

type MedicalFormUpsertFormProps = {
  submitButtonLabel: string;
  isAdmin?: boolean;
  medicalForm?: MedicalForm;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  handleCancel: () => void;
  onChange: (event: IMedicalFormUpsertFormEvent) => void;
};

export const MedicalFormUpsertForm: FC<MedicalFormUpsertFormProps> = props => {
  const { isAdmin, medicalForm, submitButtonLabel, isLoading, isSubmitDisabled, handleCancel, onChange } = props;

  const { t } = useTranslation("domains");
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useMedicalFormUpsertFormStyle();

  const form = useCustomForm<IMedicalFormUpsertFormValues>({
    defaultValues: medicalFormUpsertFormDefaultValues,
    schema: medicalFormUpsertFormSchema,
  });

  const { setValues, handleSubmit, reset } = form;

  const onSubmit = (values: IMedicalFormUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onResetFieldsClick = () => {
    handleOpen();
  };

  const onWarningConfirmationDialogConfirm = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (medicalForm) {
      const _medicalForm = convertMedicalFormToFormValues(medicalForm);
      setValues(_medicalForm);
    }
  }, [medicalForm, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box className={classes.formActionsWrapper}>
              <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                disabled={isSubmitDisabled}
                startIcon={isSubmitDisabled && <CircularProgress size={15} />}
              >
                {submitButtonLabel}
              </Button>

              <Button variant={"outlined"} className={classes.resetFieldsButton} onClick={onResetFieldsClick}>
                {t("Reset Fields")}
              </Button>

              <Button variant={"outlined"} onClick={handleCancel}>
                {t("Cancel")}
              </Button>
            </Box>
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Medical Form Information")} loading={isLoading} doYouHaveData>
                <MedicalFormInformationForm isAdmin={!!isAdmin} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <MedicalFormQuestionsForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <MedicalFormClassificationsForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>

        <CustomDialog
          type={"warning"}
          text={{
            body: t("Are you sure you want to reset all fields?"),
            title: t("Reset Fields"),
          }}
          isOpen={open}
          onClose={handleClose}
          onConfirm={onWarningConfirmationDialogConfirm}
        />
      </form>
    </FormProvider>
  );
};
