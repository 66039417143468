import { getBranchesAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";
import { appRoleTypeOptionsMap, getAutocompleteEnumFilter, vendorUserTypeOptionsMap } from "@health/enum-options";
import { OrderDirection, UserSortField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { ActivationSwitch, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { CredentialDialog } from "../../../../components";
import { useUserActivationMutation } from "../../gql";
import { IUserProviderListNode } from "../../types";

export const useUserProviderListContainerColumns = (): CustomTableColumnProps<IUserProviderListNode>[] => {
  const { t } = useTranslation("domains");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ fullName }) => <TruncateTypography text={fullName || "-"} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.FirstName,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: ({ email }) => email || "-",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.Email,
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: ({ mobile }) => mobile || "-",
      },
      {
        key: "appRole",
        header: t("App Role"),
        accessor: ({ appRole }) => appRoleTypeOptionsMap[appRole!]?.label,
        filter: getAutocompleteEnumFilter("AppRoleType", "appRole"),
      },
      {
        key: "vendorUserType",
        header: t("Vendor User Type"),
        accessor: ({ vendorUserType }) => vendorUserTypeOptionsMap[vendorUserType!]?.label || "",
        filter: getAutocompleteEnumFilter("VendorUserType", "vendorUserType"),
      },
      {
        key: "eRxHubCredential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => (
          <CredentialDialog title={t("eRxHub Credential")} targetId={id} type={"clinician"} isDisabled={!isActive} />
        ),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <ActivationSwitch id={id} isActive={isActive} mutation={useUserActivationMutation} />,
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserStatus", "status"),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branchId" }),
      },
      {
        key: "Vendor",
        header: t("Vendor"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoined",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ],
    [t]
  );
};
