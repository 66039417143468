import { createAutocompleteOption } from "@toolkit/ui";
import { IGuidedCareTeamUserAutocomplete } from "./schema";

export const createGuidedCareTeamUserAutocompleteOption = (teamMember: IGuidedCareTeamUserAutocomplete) => {
  return createAutocompleteOption(
    {
      id: teamMember?.id!,
      user: {
        id: teamMember?.user?.id!,
        fullName: teamMember?.user?.fullName!,
      },
    },
    "id",
    item => item?.user?.fullName
  );
};
