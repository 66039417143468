import { AssigneeType, GuidedCareTask, GuidedCareTaskUpdateInput, TaskDefinitionStatus } from "@/schema/types";
import { createGuidedCareTeamUserAutocompleteOption } from "@health/autocompletes";
import { taskDefinitionStatusOptionsMap } from "@health/enum-options";
import { IGuidedCareTaskUpdateFormValues } from "../forms/GuidedCareTaskUpdateForm/GuidedCareTaskUpdateFormSchema";

export const isGuidedCareTaskActionable = (status: TaskDefinitionStatus) => {
  return status === TaskDefinitionStatus.Pending || status === TaskDefinitionStatus.InProgress || status === TaskDefinitionStatus.Missed;
};

export const convertGuidedCareTaskToFormValues = (task: GuidedCareTask): IGuidedCareTaskUpdateFormValues => {
  return {
    status: taskDefinitionStatusOptionsMap[task?.status!],
    assignees: task?.assignee?.map(item => createGuidedCareTeamUserAutocompleteOption(item?.teamMember!)) || [],
  };
};

export const convertGuidedCareTaskFormValuesToBackEndValues = (
  values: IGuidedCareTaskUpdateFormValues,
  guidedCareTask: GuidedCareTask
): GuidedCareTaskUpdateInput => {
  return {
    status: values?.status?.value,
    assignee: values?.assignees?.map(item => {
      const updatedItem = guidedCareTask?.assignee?.find(assignee => assignee?.teamMember?.id === item?.value?.id);

      return {
        id: updatedItem ? updatedItem?.id : undefined,
        assigneeUserId: item?.value?.user?.id!,
        assigneeType: AssigneeType.TeamMember,
        teamMember: { id: item?.value?.id! },
      };
    }),
  };
};
