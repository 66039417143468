import { useOptimaPriorRequestKpIsTotalCountsQuery } from "pages/OptimaPriorRequests/gql";
import { IOptimaRequestKpi, requestsKpiMode, optimaRequestKpi } from "pages/OptimaPriorRequests/utils";
import { useState } from "react";

export const useOptimaPriorRequestListKpiController = ({ activeFilters }) => {
  const [selectedKpiMode, setSelectedKpiMode] = useState<IOptimaRequestKpi>(optimaRequestKpi.all);
  const { data, loading } = useOptimaPriorRequestKpIsTotalCountsQuery({
    variables: {
      filter: activeFilters || {},
    },
  });
  const hasFilter = selectedKpiMode?.type === "all";

  const totalCounts = {
    all: data?.optimaPriorRequests?.totalCount || 0,
    rejected: data?.optimaPriorRequestsRejectedAuth?.totalCount || 0,
    withoutClaim: data?.optimaPriorRequestsWithoutClaim?.totalCount || 0,
  };

  const handleKpiModeChange = (mode: requestsKpiMode) => {
    setSelectedKpiMode(optimaRequestKpi[mode]);
  };
  return {
    selectedKpiMode,
    handleKpiModeChange,
    totalCounts,
    isCountsLoading: loading,
    hasFilter,
  };
};
