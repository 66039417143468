/* eslint-disable max-lines */
import { Medication, Order, OrderDirection, OrderSortField, OrderType, PermissionEnum } from "@/schema/types";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { capitalize, hasPermission } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, MedicationsModel, OrderStatusTypography, TruncateTypography } from "@toolkit/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import { LifeCycleModel } from "pages/OrdersTracking/components/LifeCycle.components";
import { getOrderStatusColor } from "pages/OrdersTracking/components/utils";
import { useMemo } from "react";
import { OrderHandlingModal } from "../components";
import { GridCellText } from "components";

export type PrescriptionsOrdersTrackingColumnsTypes = CustomTableColumnProps<Order>[];

export const usePrescriptionsOrdersTrackingColumns = ({ isAdmin }): PrescriptionsOrdersTrackingColumnsTypes => {
  const { t } = useTranslation("domains");
  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Order Number"),
        accessor: ({ number }) => number,
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: OrderSortField.Number,
        filter: {
          type: "string",
          name: "numbers",
        },
      },
      {
        key: "Number",
        header: t("eRx Number"),
        accessor: ({ prescription }) => prescription?.referenceNumber,
        isHidden: false,
        hideFromSettings: true,
        disableToggleVisibility: true,
        filter: {
          type: "string",
          name: "referenceNumber",
        },
      },
      {
        key: "type",
        header: t("Order Type"),
        accessor: ({ type }) => (type?.includes("_") ? capitalize(type.toLowerCase().split("_")) : capitalize([String(type)])),
      },
      {
        key: "patientId",
        header: t("Customer Id"),
        accessor: ({ prescription }) => <TruncateTypography text={String(prescription?.patientIdentifier ?? "-")} />,
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: OrderSortField.Customer,
      },
      {
        key: "patient name",
        header: t("Customer Name"),
        accessor: ({ prescription }) => (
          <TruncateTypography
            width={180}
            text={String(prescription?.patient?.firstName ?? "") + " " + String(prescription?.patient?.lastName ?? "")}
          />
        ),
      },
      {
        key: "contactEmail",
        header: t("Customer Email"),
        accessor: ({ prescription }) => <GridCellText text={prescription?.patient?.email} />,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ prescription }) => <span className='mobile-phone-rtl-fix'>{String(prescription?.patient?.contactNumber ?? "")}</span>,
      },
      {
        header: t("Payment Price"),
        key: "totalAmount",
        accessor: ({ totalNetAmount }) => totalNetAmount,
      },
      {
        key: "Medications",
        header: t("Medications"),
        accessor: ({ prescription }) => <MedicationsModel<Medication> medications={prescription?.medications} />,
      },
      {
        key: "createdTo",
        header: t("Created Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "created.lte",
        },
      },
      {
        key: "Order Status",
        header: t("Order Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} />,
        filter: getAutocompleteEnumFilter("OrderStatusActive", "status", { multiple: true }),
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => <TruncateTypography text={branch?.name || "-"} />,
      },
      {
        key: "vendor",
        header: t("Vendor"),
        accessor: ({ branch }) => <TruncateTypography text={branch?.vendor?.name || "-"} />,
        hideFromPreferencesAndTable: !isAdmin,
      },
      {
        key: "Created",
        header: t("Created Date From"),
        accessor: ({ created }) => {
          moment.localeData("ar");
          const createdTz = moment.tz.guess();
          moment.locale(i18n.language);
          return <GridCellText text={moment(created).tz(createdTz).format("YYYY-MM-DD HH:mm")} />;
        },
        filter: {
          type: "date",
          name: "created.gte",
        },
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: OrderSortField.CreationDate,
      },
      {
        key: "auditHistory",
        header: t("Audit History"),
        accessor: ({ number, prescription }) => (
          <LifeCycleModel
            number={number!}
            prescriptionID={prescription?.id!}
            referenceNumber={prescription?.referenceNumber!}
            dispenseStatus={prescription?.dispenseStatus!}
          />
        ),
      },
      {
        key: "orderHandling",
        header: t("Order Handling"),
        accessor: row => <OrderHandlingModal order={row} />,
        hideFromPreferencesAndTable: !isAdmin && !hasPermission(PermissionEnum.CustomerSupportManageOrders),
      },
      {
        key: "orderHandling",
        header: t("Order Type"),
        filter: {
          ...getAutocompleteEnumFilter("DeliveryMethosType", "isDelivery"),
          getValueForBackend: option => {
            switch (option?.value) {
              case OrderType.Delivery:
                return true;
              case OrderType.Pickup:
                return false;
              default:
                return undefined;
            }
          },
        },
        showOnlyForFilterField: true,
      },
      {
        key: "Branches",
        header: t("Branches"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "patient-id-filter",
        header: t("Patient ID"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "nationalId",
        },
      },
    ];
  }, []);
};
