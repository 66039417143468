import { Theme } from "@toolkit/ui";

export const getAdherenceColor = (value: number, theme: Theme) => {
  let color = theme.palette.success.main;
  let themeColor: "success" | "error" | "warning" = "success";

  if (value >= 50 && value < 80) {
    color = theme.palette.warning.main;
    themeColor = "warning";
  } else if (value < 50) {
    color = theme.palette.error.main;
    themeColor = "error";
  }

  return { color, themeColor };
};
