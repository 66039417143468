import { MarketplaceOrder, MarketplaceOrderSortingField, MarketplaceOrderType, OrderDirection } from "@/schema/types";
import { getVendorsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderStatusTypography, TruncateTypography, Typography, useFormatDate } from "@toolkit/ui";
import "moment/locale/ar";
import { getOrderStatusColor } from "pages/OrdersTracking/components/utils";
import { useMemo } from "react";
import { ProductsModal } from "../components/ProductsModal";
import { OrderCancel } from "../../components/OrderCancel/OrderCancel";
import { OrderEventsDrawer } from "../components/OrderEventsDrawer";

export type MarketplaceOrdersTrackingColumnsTypes = CustomTableColumnProps<MarketplaceOrder>[];
type MarketPlaceColumnProps = {
  isAdmin?: boolean;
};
export const useMarketplaceOrdersTrackingColumns = (props: MarketPlaceColumnProps): MarketplaceOrdersTrackingColumnsTypes => {
  const { t } = useTranslation("domains");
  const { isAdmin } = props;
  const { formatLocalizedDate } = useFormatDate();

  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Order Number"),
        accessor: "id",
        filter: {
          type: "string",
          name: "ids",
        },
      },
      {
        key: "vendor",
        header: t("Vendor"),
        accessor: ({ vendor }) => <TruncateTypography width={100} text={vendor?.name || "-"} />,
        hideFromPreferencesAndTable: !isAdmin,
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "patient-id",
        header: t("Customer ID"),
        accessor: ({ user }) => user?.nationalId,
      },
      {
        key: "customer-name",
        header: t("Customer Name"),
        accessor: ({ user }) => <TruncateTypography text={user?.fullName || "-"} />,
      },
      {
        key: "contactEmail",
        header: t("Customer Email"),
        accessor: ({ user }) => <Typography width={200}>{String(user?.email ?? "")}</Typography>,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.mobile ?? "")}</span>,
      },
      {
        header: t("Payment Price"),
        key: "totalAmount",
        accessor: "totalNetAmount",
      },
      {
        key: "Order Status",
        header: t("Order Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} />,
        filter: getAutocompleteEnumFilter("MarketplaceOrderStatus", "statuses", { multiple: true }),
      },
      {
        key: "type",
        header: t("Order Type"),
        accessor: ({ type }) => (type === MarketplaceOrderType.HealthPackage ? t("Health Package") : t("Product")),
        filter: getAutocompleteEnumFilter("MarketplaceOrderType", "type"),
      },
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        accessor: ({ cancellationReason }) => <TruncateTypography text={cancellationReason || "-"} />,
        isHidden: !isAdmin,
        hideFromPreferencesAndTable: !isAdmin,
      },
      {
        key: "orderItems",
        header: t("Order Items"),
        accessor: ({ id }) => <ProductsModal orderId={id} title={t("Items")} />,
      },
      {
        key: "orderEvents",
        header: t("Order Events"),
        accessor: ({ id, orderEvents }) => (
          <OrderEventsDrawer
            orderId={id}
            events={orderEvents.map(event => ({
              date: event?.date,
              id: event?.id,
              status: event?.status,
              firstName: event?.user?.firstName!,
              lastName: event?.user?.lastName!,
              defaultBranchName: event?.user?.defaultBranch?.name! || "-",
            }))}
          />
        ),
      },
      {
        key: "created",
        header: t("Created"),
        accessor: ({ createdDate }) => <Typography width={100}>{formatLocalizedDate(createdDate)}</Typography>,
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: MarketplaceOrderSortingField.Created,
      },
      {
        key: "cancel",
        header: t("Cancel"),
        accessor: ({ id, status }) => <OrderCancel id={id} status={status} />,
        isHidden: !isAdmin,
        hideFromPreferencesAndTable: !isAdmin,
      },
      {
        key: "toDate",
        header: t("To Date"),
        accessor: "toDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "fromDate",
        header: t("From Date"),
        accessor: "fromDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
    ];
  }, []);
};
