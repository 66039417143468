import { Chat, ChatMessageInput } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useCallback, useState } from "react";
import { ChatMessageDraft, useChatDraft, useChats, useChatSender } from "../ChatProvider";
import { useEditChatMessageMutation, useSendChatMessageMutation } from "../../gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { getMediaLink, useFileUpload } from "@toolkit/core";

export const useChatInput = (chatGroupId?: string | null) => {
  const { failed } = useAddToast();
  const { t } = useTranslation();
  const { draft, updateDraft, clearDraft } = useChatDraft(chatGroupId);
  const { accessToken } = useChatSender();

  const [uploading, setUploading] = useState(false);
  const [sendChatMessageMutation, { loading: isSendLoading }] = useSendChatMessageMutation();
  const [editChatMessageMutation, { loading: isEditLoading }] = useEditChatMessageMutation();
  const { fetchUploadFile } = useFileUpload({ token: accessToken });
  const { addNewChat } = useChats();

  const updateDraftText = useCallback(
    (message: string) => {
      updateDraft({ ...(draft || {}), message });
    },
    [draft, updateDraft]
  );

  const submitDraft = useCallback(
    // eslint-disable-next-line max-statements
    async (value?: ChatMessageDraft) => {
      try {
        value && updateDraft(value);
        if ((!value && !draft) || !chatGroupId) {
          return;
        }
        const { action, chat, ...inputValue } = value || draft || {};
        const input: ChatMessageInput = { ...inputValue, chatGroupId };
        let newChat: Chat | undefined;
        if (action === "edit") {
          const response = await editChatMessageMutation({ variables: { editChatMessageId: chat!.id, message: input.message! } });
          newChat = response.data?.editChatMessage as Chat;
        } else {
          const response = await sendChatMessageMutation({ variables: { input } });
          newChat = response.data?.sendChatMessage as Chat;
        }

        if (newChat) {
          clearDraft();
          addNewChat(newChat);
        }
      } catch (e) {
        console.error(e);
        failed(t("An error has occurred, please try again later."));
      }
    },
    [addNewChat, chatGroupId, clearDraft, draft, editChatMessageMutation, failed, sendChatMessageMutation, t, updateDraft]
  );

  const submitAttachment = useCallback(
    async ({ file, type }: { file: File; type: string }) => {
      try {
        setUploading(true);
        const fileName = await fetchUploadFile({ files: [file] });
        await submitDraft({
          attachments: [
            {
              url: getMediaLink(fileName),
              type: type,
              name: fileName,
            },
          ],
        });
      } catch (error) {
        console.error(error);
        failed(t("An error has occurred, please try again later."));
      } finally {
        setUploading(false);
      }
    },
    [failed, fetchUploadFile, submitDraft, t]
  );

  return {
    enabled: !!chatGroupId,
    loading: isSendLoading || isEditLoading || uploading,
    draft,
    submitAttachment,
    submitDraft,
    updateDraft,
    updateDraftText,
    clearDraft,
  };
};
