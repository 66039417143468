import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientEligibilityRequestCreateMutationVariables = Types.Exact<{
  input: Types.PatientEligibilityRequestInput;
}>;


export type PatientEligibilityRequestCreateMutation = { __typename?: 'Mutation', patientEligibilityRequest?: boolean | null };


export const PatientEligibilityRequestCreateDocument = gql`
    mutation PatientEligibilityRequestCreate($input: PatientEligibilityRequestInput!) {
  patientEligibilityRequest(input: $input)
}
    `;
export type PatientEligibilityRequestCreateMutationFn = Apollo.MutationFunction<PatientEligibilityRequestCreateMutation, PatientEligibilityRequestCreateMutationVariables>;

/**
 * __usePatientEligibilityRequestCreateMutation__
 *
 * To run a mutation, you first call `usePatientEligibilityRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientEligibilityRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientEligibilityRequestCreateMutation, { data, loading, error }] = usePatientEligibilityRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientEligibilityRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<PatientEligibilityRequestCreateMutation, PatientEligibilityRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientEligibilityRequestCreateMutation, PatientEligibilityRequestCreateMutationVariables>(PatientEligibilityRequestCreateDocument, options);
      }
export type PatientEligibilityRequestCreateMutationHookResult = ReturnType<typeof usePatientEligibilityRequestCreateMutation>;
export type PatientEligibilityRequestCreateMutationResult = Apollo.MutationResult<PatientEligibilityRequestCreateMutation>;
export type PatientEligibilityRequestCreateMutationOptions = Apollo.BaseMutationOptions<PatientEligibilityRequestCreateMutation, PatientEligibilityRequestCreateMutationVariables>;