import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { PromotionInformationForm } from "../PromotionFromInformation";
import { PromotionUpsertFormSchema, promotionUpsertFormSchemaDefaultValues, IPromotionUpsertFormValues } from "./PromotionUpsertFormSchema";
import { convertPromotionToFormValues } from "../../utils";
import { PromotionsQueryNode } from "../../types";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

type PromotionUpsertFormProps = {
  promotion?: PromotionsQueryNode;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IPromotionUpsertFormValues) => void;
};

export const PromotionUpsertForm: FC<PromotionUpsertFormProps> = props => {
  const { promotion, isLoading, isSubmitting, submitButtonLabel, onChange } = props;

  const form = useCustomForm<IPromotionUpsertFormValues>({
    schema: PromotionUpsertFormSchema,
    defaultValues: promotionUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    setValues,
    formState: { errors },
  } = form;

  const { t } = useTranslation("domains");
  const isButtonDisabled = isLoading || isSubmitting;
  const { accessToken } = useOidcUserProfile();

  const onSubmit = (values: IPromotionUpsertFormValues) => {
    onChange(values);
  };

  useEffect(() => {
    if (promotion) {
      const _promotion = convertPromotionToFormValues(promotion);

      setValues(_promotion);
    }
  }, [promotion, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Promotion Information")} loading={isLoading} doYouHaveData>
                <PromotionInformationForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Photo")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"photo"}
                      validationRation
                      error={!!errors?.photo}
                      helperText={errors?.photo?.message}
                      token={accessToken!}
                      defaultValue={promotion?.photo}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
