import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.common.white,
    position: "relative",
    minWidth: 200,
    // maxHeight: 600,
    padding: "0.2rem",
    overflow: "hidden",
    border: "0.15rem solid transparent",
    borderRadius: "4px",
    width: "100%",
    height: "100%",
    ["&:hover"]: {
      backgroundColor: "#171717",
    },
  },

  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "0.5rem",
    left: "0.5rem",
    fontSize: "1.1rem",
    borderRadius: "0.5rem",
    padding: "0.25rem 0.5rem",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: theme.palette.common.white,
    maxWidth: "calc(100% - 1rem)",

    ["& > *:not(:last-child)"]: {
      marginRight: "0.5rem",
    },
  },

  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: "1 1 auto",
  },
}));
