import { OptimaAction } from "@/schema/types";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaEditActionsStyle } from "./useOptimaEditActionsStyle";
import { useTranslation } from "@toolkit/i18n";

type OptimaEditActionsProps = {
  actions: OptimaAction[];
};

export const OptimaEditActions: FC<OptimaEditActionsProps> = props => {
  const { actions } = props;

  const { t } = useTranslation("provider");

  const { classes } = useOptimaEditActionsStyle();

  return (
    <Box className={classes.root}>
      {actions?.length ? (
        <>
          {actions?.map(item => (
            <Box key={item.id} className={classes.summaryWrapper}>
              <Typography className={classes.summary}> - {item?.summary}</Typography>
            </Box>
          ))}
        </>
      ) : (
        <Typography> {t("There are no available actions")} </Typography>
      )}
    </Box>
  );
};
