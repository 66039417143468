import { FC, MutableRefObject, PropsWithChildren, createContext, useCallback, useMemo, useRef, useState } from "react";
import { Chat, ChatMessageInput } from "../../schema/types";

export type ChatMessageDraft = Partial<ChatMessageInput> & { action?: "edit" | "reply"; chat?: Chat };

export type ChatAudioPlayer = { pause: () => void };
export const ChatContext = createContext<{
  audioRef?: MutableRefObject<ChatAudioPlayer | undefined>;
  senderId?: number;
  accessToken?: string;
  draft?: ChatMessageDraft;
  chats: Chat[];
  updateChats: (chats: Chat[]) => void;
  updateDraft: (draft?: ChatMessageDraft) => void;
}>({
  chats: [],
  updateChats: () => {},
  updateDraft: () => {},
});

type ChatProviderProps = PropsWithChildren<{ accessToken?: string; senderId?: number }>;

export const ChatProvider: FC<ChatProviderProps> = ({ children, accessToken, senderId }) => {
  const audioRef = useRef<ChatAudioPlayer>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [draft, setDraft] = useState<ChatMessageDraft>();
  const updateDraft = useCallback((value?: ChatMessageDraft) => setDraft(value), []);
  const updateChats = useCallback((value: Chat[]) => setChats(value), []);

  const value = useMemo(
    () => ({ audioRef, senderId, accessToken, draft, chats, updateDraft, updateChats }),
    [senderId, accessToken, chats, draft, updateChats, updateDraft]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
