import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Divider, MuiDrawer, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { EligibilityScanResultWidget } from "../EligibilityScanResultWidget/EligibilityScanResultWidget";

export const EligibilityScanResultModal: FC<{ id: string; patientNationalId: string }> = props => {
  const { t } = useTranslation("optima");
  const theme = useTheme();
  const { id, patientNationalId } = props;
  const { open, handleClose, handleOpen } = useOpenState();
  return (
    <>
      <Button onClick={handleOpen} variant='contained' color='primary'>
        {t("Show Details")}
      </Button>
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleClose}
        keepMounted={false}
        PaperProps={{
          sx: {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            [theme.breakpoints.up("sm")]: {
              width: "80%",
            },
            padding: theme.spacing(2),
          },
        }}
      >
        <Typography fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
          {t("Eligibility Scan Result")}
        </Typography>
        <Divider sx={{ mx: 1, mb: 2 }} />
        {open && id && <EligibilityScanResultWidget id={id} nationalId={patientNationalId} />}
      </MuiDrawer>
    </>
  );
};
