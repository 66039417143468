import { OperationVariables } from "@apollo/client";
import { isEmpty, set } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageInfo } from "../utils/countableConnection";

export const useDataGridVariablesState = <TVar extends OperationVariables = Record<string, any>>({
  input,
  hasSavedInput,
}: {
  input: TVar;
  hasSavedInput?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const getSearchParamsVariables = () => {
    const defaultFilter =
      params.get("filter") && params.get("filter") !== "undefined"
        ? {
            ...input.filter,
            ...JSON.parse(params.get("filter") || "{}"),
          }
        : input.filter;
    const defaultSort =
      params.get("sort") && params.get("sort") !== "undefined"
        ? {
            ...input.sort,
            ...JSON.parse(params.get("sort") || "{}"),
          }
        : input.sort;

    return {
      after: params.get("cursor") || undefined,
      filter: isEmpty(defaultFilter) ? undefined : defaultFilter,
      sortBy: isEmpty(defaultSort) ? undefined : defaultSort,
    };
  };

  const [variables, setVariables] = useState<TVar>(() => {
    return {
      ...input,
      ...getSearchParamsVariables(),
    };
  });

  const updateSearchParams = (startCursor: PageInfo["startCursor"], nextVariables: TVar) => {
    if (startCursor) {
      params.set("cursor", startCursor);
    } else {
      params.delete("cursor");
    }

    if (nextVariables?.filter && !isEmpty(nextVariables?.filter)) {
      params.set("filter", JSON.stringify(nextVariables?.filter));
    } else {
      params.delete("filter");
    }

    if (nextVariables?.sortBy && !isEmpty(nextVariables?.sortBy)) {
      params.set("sort", JSON.stringify(nextVariables?.sortBy));
    } else {
      params.delete("sort");
    }

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  useEffect(() => {
    if (params.get("cursor") && !input.after) {
      const searchParamsVariables = getSearchParamsVariables();
      set(input, "after", searchParamsVariables.after);
      set(input, "filter", searchParamsVariables.filter);
      set(input, "sortBy", searchParamsVariables.sortBy);
    }

    setVariables(
      hasSavedInput
        ? {
            ...input,
            filter: {
              ...variables?.filter,
              ...input?.filter,
            },
            sortBy: {
              ...variables?.sortBy,
              ...input?.sortBy,
            },
          }
        : input
    );
  }, [JSON.stringify(input)]);

  return {
    variables,
    setVariables,
    updateSearchParams,
  };
};
