import { i18n } from "@toolkit/i18n";
import { ChartType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const chartTypeOptionsMap: IEnumToOptionsMap<ChartType> = {
  [ChartType.Bar]: {
    key: ChartType.Bar,
    get label() {
      return i18n.t("Bar", { ns: "domains" });
    },
    value: ChartType.Bar,
  },
  [ChartType.HorizontalBar]: {
    key: ChartType.HorizontalBar,
    get label() {
      return i18n.t("Horizontal Bar", { ns: "domains" });
    },
    value: ChartType.HorizontalBar,
  },
  [ChartType.Line]: {
    key: ChartType.Line,
    get label() {
      return i18n.t("Line", { ns: "domains" });
    },
    value: ChartType.Line,
  },
  [ChartType.Table]: {
    key: ChartType.Table,
    get label() {
      return i18n.t("Table", { ns: "domains" });
    },
    value: ChartType.Table,
  },
};

export const chartTypeOptions = Object.values(chartTypeOptionsMap);
