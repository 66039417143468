import { FC } from "react";
import { Box, TruncateTypography, ValueWithLabel } from "@toolkit/ui";
import { ExtractNodeType } from "@toolkit/apollo";
import { OptimaClaimsListQuery } from "pages/OptimaClaims/gql";
import { useTranslation } from "@toolkit/i18n";
import { useRemittanceDrawerStyles } from "../RemittanceDrawer/RemittanceDrawerStyles";
import { DATE_FORMAT_FOR_TABLE_LONG, toTitleCase } from "@toolkit/core";
import { transactionSourceTypeOptionsMap } from "@health/enum-options";
import moment from "moment";

type TransactionDetailsProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const ClaimTransactionDetails: FC<TransactionDetailsProps> = ({ claim }) => {
  const { classes } = useRemittanceDrawerStyles();
  const { t } = useTranslation("domains");

  return (
    <Box className={classes.sectionContainer}>
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Transaction Date")}
        value={claim.transactionDate ? moment(claim.transactionDate).format(DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Transaction Type")}
        value={claim.transactionType || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Source")}
        value={transactionSourceTypeOptionsMap[claim.source].label || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Payer")}
        value={<TruncateTypography className={classes.value} text={toTitleCase(claim.payer?.name || "-")} />}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Receiver")}
        value={<TruncateTypography className={classes.value} text={toTitleCase(claim.receiver?.name || "-")} />}
      />
    </Box>
  );
};
