import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { OptimaValidatedRequestsRoutesBreadcrumb } from "../../constants";
import { useRcmOptimaValidationRequestsListQuery } from "../../gql";
import { useOptimaValidatedRequestsListContainerColumns } from "./useOptimaValidatedRequestsListContainerColumns";

export const OptimaValidatedRequestsListContainer: FC = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: OptimaValidatedRequestsRoutesBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName={"optimaValidationRequests"}
      query={useRcmOptimaValidationRequestsListQuery}
      columns={useOptimaValidatedRequestsListContainerColumns()}
      hasTableSetting
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
