import { useTranslation } from "@toolkit/i18n";
import { AnonymousChatGroup, ChatGroup } from "../../schema/types";
import { FC } from "react";

type ChatTitleProps = {
  value: ChatGroup | AnonymousChatGroup | undefined;
};

export const ChatTitle: FC<ChatTitleProps> = ({ value }) => {
  const { t } = useTranslation();
  switch (value?.__typename) {
    case "ChatGroup":
      return value.name ?? t("Chat Group");
    case "AnonymousChatGroup":
      return value.title ?? t("Anonymous Chat Group");
    default:
      return null;
  }
};
