import { Box, ByPatientIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import moment from "moment";
import { useOptimaPatientProfileCardStyle } from "./useOptimaPatientProfileCardStyle";
import { useTranslation } from "@toolkit/i18n";

type OptimaPatientProfileCardProps = {
  name: string;
  dob: string;
  patientId: string;
  gender: string;
  extraInfoNode?: React.ReactNode;
  withPaper?: boolean;
};

export const OptimaPatientProfileCard: FC<OptimaPatientProfileCardProps> = props => {
  const { name, dob, gender, patientId, extraInfoNode, withPaper } = props;

  const { t } = useTranslation("domains");

  const { classes, cx } = useOptimaPatientProfileCardStyle();

  const age = dob ? `${moment().diff(moment(dob), "years")} ${t("Years")}` : "";

  return (
    <Box className={cx(classes.root, withPaper && classes.rootWithPaper)}>
      <Box className={classes.iconWrapper}>
        <ByPatientIcon />
      </Box>

      <Box className={classes.infoWrapper}>
        <Typography className={classes.name}> {name?.toLowerCase()} </Typography>
        <Typography className={classes.patientId}> {patientId} </Typography>
        <Typography className={classes.age}>
          ( {age}, {gender?.toLowerCase()} )
        </Typography>
        {extraInfoNode}
      </Box>
    </Box>
  );
};
