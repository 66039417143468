import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { optimaValidationRequestsPaths, optimaValidationRequestsRoute } from "./OptimaRcmValidationRequestsPaths";
import RouteItem from "@/shared/components/Root/routeTypes";
import { OptimaValidationRequestsListContainer } from "../containers";

export const optimaValidationRequestsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-validation-requests-routes",
    text: t("Active Claims", { ns: "provider" }),
    route: optimaValidationRequestsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageValidationRequests),
    subItems: [
      {
        id: "optima-validation-requests-list-route",
        route: optimaValidationRequestsPaths.list.route,
        fullPath: optimaValidationRequestsPaths.list.fullPath,
        element: <OptimaValidationRequestsListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
