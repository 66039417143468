import { VisitStatus } from "@/schema/types";
import { CallContent } from "./CallContent";
import { CallError } from "./CallError";
import { useMeetingInfo } from "./useMeetingInfo";
import { CallVisitStatus } from "./CallVisitStatus";
import { useTranslation } from "@toolkit/i18n";
import { useCallback } from "react";

type CallProps = {
  visitId: string;
  invitationLink?: string;
  isEndVisitOnLeave?: boolean;
  renderDoctorConsole?: () => JSX.Element;
  onClose?: () => void;
  onGoBack?: () => void;
};

export const Call = ({ invitationLink, visitId, isEndVisitOnLeave, renderDoctorConsole, onClose, onGoBack }: CallProps) => {
  const { visitInfo, ...meetingInfo } = useMeetingInfo(visitId);
  const { t } = useTranslation();

  const isLoading = visitInfo.loading || meetingInfo.loading;
  const hasError = !!visitInfo.error || !!meetingInfo.error;
  const handleRetry = useCallback(() => {
    if (!visitInfo.visit || visitInfo.error) {
      return visitInfo.handleRetry();
    }
    if (!meetingInfo.call || meetingInfo.error) {
      return meetingInfo.handleRetry();
    }
  }, [meetingInfo, visitInfo]);

  if (!visitInfo.visit || !meetingInfo.call || isLoading || hasError) {
    return (
      <CallError isLoading={isLoading} hasError={hasError} onClick={handleRetry} errorMessage={!visitId ? t("Invalid meeting ID.") : ""} />
    );
  }

  if (!meetingInfo.joined && visitInfo.visit?.status !== VisitStatus.CallInProgress) {
    return <CallVisitStatus visit={visitInfo.visit} call={meetingInfo.call} onClose={onClose} />;
  }

  return (
    <CallContent
      call={meetingInfo.call}
      visit={visitInfo.visit}
      invitationLink={invitationLink}
      onClose={onClose}
      onGoBack={onGoBack}
      renderDoctorConsole={renderDoctorConsole}
      isEndVisitOnLeave={isEndVisitOnLeave}
    />
  );
};
