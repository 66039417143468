import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { HeaderDetailsTabMap } from "../constants";
import { HeaderDetailsTab } from "../types";
import { useHealthPackageOrdersHeaderStyles } from "./useHealthPackageOrdersHeaderStyles";

interface HealthPackageOrdersHeaderProps {
  selectedTab: HeaderDetailsTab;
  onChangeTab: (tab: HeaderDetailsTab) => void;
}

export const HealthPackageOrdersHeader: FC<HealthPackageOrdersHeaderProps> = ({ selectedTab, onChangeTab }) => {
  const { classes, cx } = useHealthPackageOrdersHeaderStyles();

  return (
    <Box className={classes.root}>
      {HeaderDetailsTabMap.map(tab => (
        <Button
          key={tab.getLabel()}
          variant='text'
          className={cx(classes.button, { [classes.selected]: selectedTab === tab })}
          onClick={() => onChangeTab(tab)}
        >
          {tab.getLabel()}
        </Button>
      ))}
    </Box>
  );
};
