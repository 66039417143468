import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchActiveStatusUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BranchActiveStatusUpdateMutation = { __typename?: 'Mutation', branchActiveStatusUpdate?: { __typename?: 'BranchActiveStatusUpdate', branch?: { __typename?: 'Branch', id: string, isActive: boolean } | null, branchErrors: Array<{ __typename?: 'BranchError', message?: string | null, field?: string | null, code: Types.VendorErrorCode }> } | null };


export const BranchActiveStatusUpdateDocument = gql`
    mutation BranchActiveStatusUpdate($id: ID!) {
  branchActiveStatusUpdate(id: $id) {
    branch {
      id
      isActive
    }
    branchErrors {
      message
      field
      code
    }
  }
}
    `;
export type BranchActiveStatusUpdateMutationFn = Apollo.MutationFunction<BranchActiveStatusUpdateMutation, BranchActiveStatusUpdateMutationVariables>;

/**
 * __useBranchActiveStatusUpdateMutation__
 *
 * To run a mutation, you first call `useBranchActiveStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBranchActiveStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [branchActiveStatusUpdateMutation, { data, loading, error }] = useBranchActiveStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchActiveStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BranchActiveStatusUpdateMutation, BranchActiveStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BranchActiveStatusUpdateMutation, BranchActiveStatusUpdateMutationVariables>(BranchActiveStatusUpdateDocument, options);
      }
export type BranchActiveStatusUpdateMutationHookResult = ReturnType<typeof useBranchActiveStatusUpdateMutation>;
export type BranchActiveStatusUpdateMutationResult = Apollo.MutationResult<BranchActiveStatusUpdateMutation>;
export type BranchActiveStatusUpdateMutationOptions = Apollo.BaseMutationOptions<BranchActiveStatusUpdateMutation, BranchActiveStatusUpdateMutationVariables>;