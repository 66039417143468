import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitPatientGetQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitPatientGetQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', patient?: { __typename?: 'Patient', id: string, user?: { __typename?: 'User', id: string } | null } | null } | null };


export const VisitPatientGetDocument = gql`
    query VisitPatientGet($id: ID) {
  visit(id: $id) {
    patient {
      id
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useVisitPatientGetQuery__
 *
 * To run a query within a React component, call `useVisitPatientGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPatientGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPatientGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisitPatientGetQuery(baseOptions?: Apollo.QueryHookOptions<VisitPatientGetQuery, VisitPatientGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitPatientGetQuery, VisitPatientGetQueryVariables>(VisitPatientGetDocument, options);
      }
export function useVisitPatientGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitPatientGetQuery, VisitPatientGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitPatientGetQuery, VisitPatientGetQueryVariables>(VisitPatientGetDocument, options);
        }
export function useVisitPatientGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitPatientGetQuery, VisitPatientGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitPatientGetQuery, VisitPatientGetQueryVariables>(VisitPatientGetDocument, options);
        }
export type VisitPatientGetQueryHookResult = ReturnType<typeof useVisitPatientGetQuery>;
export type VisitPatientGetLazyQueryHookResult = ReturnType<typeof useVisitPatientGetLazyQuery>;
export type VisitPatientGetSuspenseQueryHookResult = ReturnType<typeof useVisitPatientGetSuspenseQuery>;
export type VisitPatientGetQueryResult = Apollo.QueryResult<VisitPatientGetQuery, VisitPatientGetQueryVariables>;