import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { IOptimaRequestKpi, requestsKpiMode } from "pages/OptimaPriorRequests/utils";
import { RejectIcon, SandTimerIcon } from "@toolkit/ui";
import { CustomIcon } from "@toolkit/ui";
import { KpiItem, KpiList } from "components/KpiList/KpiList";

interface RequestListKpisProps {
  totalCounts: Record<requestsKpiMode, number>;
  handleKpiModeChange: (mode: requestsKpiMode) => void;
  selectedKpiMode: IOptimaRequestKpi;
}

export const RequestListKpis: FC<RequestListKpisProps> = props => {
  const { totalCounts, selectedKpiMode, handleKpiModeChange } = props;
  const { t } = useTranslation("domains");

  const subtitleFormat = "{{count}} requests";

  const kpis: KpiItem[] = [
    {
      title: t("Show all requests"),
      subtitle: t(subtitleFormat, { count: totalCounts?.all || 0 }),
      icon: <CustomIcon margin={"0 0 0 2px"} icon='insurance' />,
      type: "all",
      color: "success",
      isSelected: selectedKpiMode.type === "all",
    },
    {
      title: t("Today's Rejected Requests"),
      subtitle: t(subtitleFormat, { count: totalCounts?.rejected || 0 }),
      icon: <RejectIcon />,
      type: "rejected",
      color: "error",
      isSelected: selectedKpiMode.type === "rejected",
    },
    {
      title: t("Today's Requests without claim"),
      subtitle: t(subtitleFormat, { count: totalCounts?.withoutClaim || 0 }),
      icon: <SandTimerIcon />,
      type: "withoutClaim",
      color: "warning",
      isSelected: selectedKpiMode.type === "withoutClaim",
    },
  ] as const;

  return <KpiList kpis={kpis} onKpiClick={type => handleKpiModeChange(type as requestsKpiMode)} />;
};
