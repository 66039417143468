import { UserProviderCreateContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { useSetUsersBreadcrumbs } from "../../hooks";
import { usersPaths } from "../../constants";
import { useCurrentUserQuery } from "@/gql/queries";

export const ProviderUserCreateContainer = () => {
  const navigate = useNavigate();

  const { data } = useCurrentUserQuery();

  const vendorId = data?.me?.vendor?.id;

  const onUserVendorUpsertFormChange = () => {
    navigate(usersPaths.list.fullPath);
  };

  useSetUsersBreadcrumbs("CREATE");

  return <UserProviderCreateContainer vendorId={vendorId} onChange={onUserVendorUpsertFormChange} />;
};
