import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { SettingsHeaderProps } from "./types";
import { useSettingsHeaderStyle } from "./useSettingsHeaderStyle";

export const SettingsHeader: FC<SettingsHeaderProps> = ({ selectedTab, onChangeTab, shouldShowRemindersTab }) => {
  const { t } = useTranslation("provider");
  const { classes, cx } = useSettingsHeaderStyle();

  return (
    <Box className={classes.root}>
      <Button
        variant='text'
        className={cx(classes.button, { [classes.selected]: selectedTab === "General" })}
        onClick={() => onChangeTab("General")}
      >
        {t("General")}
      </Button>
      {shouldShowRemindersTab && (
        <Button
          variant='text'
          className={cx(classes.button, { [classes.selected]: selectedTab === "AppointmentsReminder" })}
          onClick={() => onChangeTab("AppointmentsReminder")}
        >
          {t("Appointments Reminders")}
        </Button>
      )}
    </Box>
  );
};
