import { CustomTable } from "@toolkit/ui";
import { mapMessage } from "pages/OptimaVisitValidationRequest/utils";
import { FC } from "react";
import { ContributingFactor } from "../../types";

type OptimaContributingFactorsAiSuggestionsTableProps = {
  dataSets: ContributingFactor[];
  isLoading?: boolean;
};

export const OptimaContributingFactorsAiSuggestionsTable: FC<OptimaContributingFactorsAiSuggestionsTableProps> = props => {
  const { dataSets, isLoading } = props;

  return (
    <CustomTable
      data={dataSets}
      isLoading={isLoading}
      TableContainerProps={{
        sx: {
          maxWidth: "100%",
          "& thead": {
            display: "none",
          },
        },
      }}
      columns={[
        {
          key: "Suggestions",
          header: "Suggestions",
          accessor: ({ display }) => mapMessage(display),
        },
      ]}
    />
  );
};
