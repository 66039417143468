/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionDefinitionField, ActionField, ActionFieldInput, ActionFieldType } from "@/schema/types";
import { toSentenceCase } from "@toolkit/core";
import { pickLocalizedValue } from "@toolkit/i18n";
import { createAutocompleteOption, mapStringsToAutocompleteOptions } from "@toolkit/ui";
import { isObject, sortBy } from "lodash";
import { IDecisionActionFieldUpsertFormValues } from "pages/Decisions/forms";
import { isCodeInDecisionActionFieldListCode } from "./utils";
import {
  DecisionActionFieldListCode,
  IDecisionActionFieldBackEndValues,
  IDecisionActionFieldsExtraValue,
  IDecisionActionFieldsValues,
  IDecisionHealthParameterRiskParameter,
  IDecisionPlanActionFieldsKeyedBy,
} from "../types";

export const convertDecisionActionFieldsFormValuesToBackEndValues = (
  actionFields: IDecisionActionFieldUpsertFormValues[]
): ActionFieldInput[] => {
  return actionFields
    ?.map(item => {
      let extra: string | undefined = undefined;

      const actionFieldsBackEndValues: IDecisionActionFieldBackEndValues = {
        valueBoolean: undefined,
        valueNumber: undefined,
        valueString: undefined,
        valueList: undefined,
      };

      if (item?.type === ActionFieldType.Boolean) {
        actionFieldsBackEndValues.valueBoolean = item?.valueBoolean!;
      } else if (item?.type === ActionFieldType.Number) {
        actionFieldsBackEndValues.valueNumber = item?.valueNumber!;
      } else if (item?.type === ActionFieldType.String) {
        if (isCodeInDecisionActionFieldListCode(item?.code!) && isObject(item?.valueString)) {
          extra = JSON.stringify(item?.valueString?.value);
          actionFieldsBackEndValues.valueString = item?.valueString?.key!;
        } else if (item?.allowedValues?.length && isObject(item?.valueString)) {
          actionFieldsBackEndValues.valueString = item?.valueString?.value! as string;
        } else {
          actionFieldsBackEndValues.valueString = item?.valueString! as string;
        }
      } else if (item?.type === ActionFieldType.List) {
        extra = JSON.stringify(item?.valueList?.value);
        actionFieldsBackEndValues.valueList = [item?.valueList?.value?.id!];
      }

      const nonNullableValues = Object.entries(actionFieldsBackEndValues)
        .filter(([key, value]) => value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      if (Object.keys(nonNullableValues).length > 0) {
        return {
          ...nonNullableValues,
          code: item?.code,
          type: item?.type,
          extra,
        };
      }

      return undefined;
    })
    .filter(Boolean) as ActionFieldInput[];
};

export const sortActionFields = (actionDefinitionFields: IDecisionActionFieldUpsertFormValues[]) => {
  return sortBy(actionDefinitionFields || [], item => {
    switch (item?.type) {
      case ActionFieldType.List:
        return 1;
      case ActionFieldType.String:
        if (isCodeInDecisionActionFieldListCode(item?.code!)) {
          return 2;
        } else if (item?.allowedValues?.length) {
          return 3;
        } else {
          return 4;
        }
      case ActionFieldType.Number:
        return 5;
      case ActionFieldType.Date:
        return 6;
      default:
        return 7;
    }
  });
};

export const convertDecisionPlanActionFieldsToFormValues = (
  decisionPlanActionFields: ActionDefinitionField[],
  healthRiskParameter?: IDecisionHealthParameterRiskParameter
): IDecisionActionFieldUpsertFormValues[] => {
  const decisionPlanFields =
    decisionPlanActionFields?.map(item => {
      const mappedField: IDecisionActionFieldUpsertFormValues = {
        code: item?.code!,
        display: item?.display!,
        type: item?.type!,
        allowedValues: mapStringsToAutocompleteOptions(item?.allowedValues as string[], toSentenceCase),
        isRequired: item?.isRequired!,
        valueString: undefined,
        valueNumber: undefined,
        valueBoolean: false,
        valueList: undefined,
      };

      if (healthRiskParameter && item?.code === DecisionActionFieldListCode.RiskParameter) {
        mappedField.valueList = createAutocompleteOption(
          {
            id: healthRiskParameter?.id!,
            name: healthRiskParameter?.name!,
            nameAr: healthRiskParameter?.nameAr!,
          },
          "id",
          param => pickLocalizedValue(param?.name!, param?.nameAr!)
        );
      }

      return mappedField;
    }) || [];

  return sortActionFields(decisionPlanFields);
};
export const resetDecisionActionFieldsValues = (actionValues: IDecisionActionFieldsValues, except: keyof IDecisionActionFieldsValues) => {
  for (const key in actionValues) {
    if (key !== except) {
      actionValues[key] = undefined;
    }
  }
};

export const convertDecisionActionFieldsToFormValues = (
  actionFields: ActionField[],
  decisionPlanActionsFields: IDecisionPlanActionFieldsKeyedBy
): IDecisionActionFieldUpsertFormValues[] => {
  const result: IDecisionActionFieldUpsertFormValues[] = [];

  result.push(
    ...(actionFields?.map(item => {
      const decisionActionsField = decisionPlanActionsFields[item?.code!];
      const extra: IDecisionActionFieldsExtraValue = item?.extra ? JSON.parse(item?.extra) : undefined;

      const actionValues: IDecisionActionFieldsValues = {
        valueBoolean: undefined,
        valueNumber: undefined,
        valueString: undefined,
        valueList: undefined,
        valueDate: undefined,
      };

      if (item?.type === ActionFieldType.Boolean) {
        actionValues.valueBoolean = item?.valueBoolean!;
        resetDecisionActionFieldsValues(actionValues, "valueBoolean");
      } else if (item?.type === ActionFieldType.Number) {
        actionValues.valueNumber = item?.valueNumber!;
        resetDecisionActionFieldsValues(actionValues, "valueNumber");
      } else if (item?.type === ActionFieldType.String) {
        if (Object.values(DecisionActionFieldListCode).includes(item?.code as DecisionActionFieldListCode) && extra) {
          actionValues.valueString = createAutocompleteOption(
            {
              id: extra?.id!,
              name: extra?.name!,
              nameAr: extra?.nameAr!,
            },
            "id",
            _item => pickLocalizedValue(_item?.name, _item?.nameAr!)
          );
        } else if (decisionActionsField?.allowedValues?.length) {
          const allowedValuesOptions = mapStringsToAutocompleteOptions(decisionActionsField?.allowedValues as string[], toSentenceCase);
          actionValues.valueString = allowedValuesOptions.find(option => option?.value === item?.valueString)!;
        } else {
          actionValues.valueString = item?.valueString!;
        }

        resetDecisionActionFieldsValues(actionValues, "valueString");
      } else if (item?.type === ActionFieldType.List && extra) {
        actionValues.valueList = createAutocompleteOption(
          {
            id: extra?.id!,
            name: extra?.name!,
            nameAr: extra?.nameAr!,
          },
          "id",
          _item => pickLocalizedValue(_item?.name, _item?.nameAr!)
        );

        resetDecisionActionFieldsValues(actionValues, "valueList");
      } else if (item?.type === ActionFieldType.Date) {
        actionValues.valueDate = item?.valueDate!;
        resetDecisionActionFieldsValues(actionValues, "valueDate");
      }

      return {
        ...actionValues,
        code: item?.code!,
        display: decisionActionsField?.display!,
        type: item?.type!,
        isRequired: !!decisionActionsField?.isRequired,
        allowedValues: mapStringsToAutocompleteOptions(decisionActionsField?.allowedValues as string[], toSentenceCase),
      };
    }) || [])
  );

  Object.entries(decisionPlanActionsFields).forEach(([code, decisionActionsField]) => {
    if (!actionFields?.some(item => item?.code === code)) {
      result.push({
        code,
        display: decisionActionsField?.display,
        type: decisionActionsField?.type!,
        isRequired: !!decisionActionsField?.isRequired,
        allowedValues: mapStringsToAutocompleteOptions(decisionActionsField?.allowedValues as string[], toSentenceCase),
      });
    }
  });

  return sortActionFields(result);
};
