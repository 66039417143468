import { Maybe, OptimaItemEdit } from "@/schema/types";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { OptimaEditCard } from "../OptimaEditCard/OptimaEditCard";
import { isEmpty } from "lodash";
import { useTranslation } from "@toolkit/i18n";

interface OptimaEditsListProps {
  display?: string;
  edits?: Maybe<OptimaItemEdit>[] | null;
  isLikeDislikeEnabled?: boolean;
  onLikeClick: (id?: string) => () => void;
  onDislikeClick: (id?: string) => () => void;
  isIdBeingUpdated: (id?: string) => boolean;
}

export const OptimaEditsList: FC<OptimaEditsListProps> = ({
  display = "",
  edits,
  isLikeDislikeEnabled = false,
  onLikeClick,
  onDislikeClick,
  isIdBeingUpdated,
}) => {
  const { t } = useTranslation("provider");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, marginTop: 1.5 }}>
      {!isEmpty(edits) && (
        <Typography textAlign='start'>{t("As per medical coding and insurance guidelines, the following has been flagged")}:</Typography>
      )}
      {edits?.map(edit => (
        <OptimaEditCard
          key={edit?.id}
          display={display}
          edit={edit?.edit}
          isLikeStatusUpdating={isIdBeingUpdated(edit?.id)}
          likeStatus={edit?.likeDislike || null}
          onLikeClick={onLikeClick(edit?.id)}
          onDislikeClick={onDislikeClick(edit?.id)}
          isLikeDislikeEnabled={isLikeDislikeEnabled}
        />
      ))}
    </Box>
  );
};
