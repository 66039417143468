import { EntityType } from "@/schema/types";
import { zodEnumSchema } from "@health/enum-options";
import { createZodAutocomplete } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";
import { hideDecisionConditionOperator } from "../../others";

export const decisionConditionsFormSchema = z
  .object({
    id: z.string(),
    category: createZodAutocomplete(z.object({ code: z.string(), display: z.string() })),
    field: createZodAutocomplete(
      z.object({
        id: z.string(),
        code: z.string(),
        display: z.string(),
        type: z.nativeEnum(EntityType),
      })
    ),
    operator: zodEnumSchema.operator,
    valueNumber: z.number().nonnegative().nullish(),
    valueDate: z.string().nullish(),
    valueString: z
      .string()
      .or(createZodAutocomplete())
      .or(
        createZodAutocomplete(
          z.object({
            id: z.string(),
            display: z.string(),
          })
        )
      )
      .nullish(),
    valueID: createZodAutocomplete(
      z.object({
        id: z.string(),
        name: z.string(),
        nameAr: z.string().nullish(),
      })
    ).nullish(),
    valueList: createZodAutocomplete(
      z.object({
        id: z.string(),
        display: z.string(),
      })
    ).nullish(),
  })
  .superRefine((data, ctx) => {
    if (!hideDecisionConditionOperator(data?.operator?.value)) {
      if (data?.field?.value?.type === EntityType.String && !data.valueString) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "domains" }),
          path: ["valueString"],
        });
      } else if (data?.field?.value?.type === EntityType.Number && (data.valueNumber === undefined || isNaN(data.valueNumber!))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "domains" }),
          path: ["valueNumber"],
        });
      } else if (data?.field?.value?.type === EntityType.Id && !data.valueID) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "domains" }),
          path: ["valueID"],
        });
      } else if (data?.field?.value?.type === EntityType.List && !data.valueList) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "domains" }),
          path: ["valueList"],
        });
      } else if (data?.field?.value?.type === EntityType.Date && !data.valueDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "domains" }),
          path: ["valueDate"],
        });
      }
    }
  });

export type IDecisionConditionsFormValues = z.infer<typeof decisionConditionsFormSchema>;
