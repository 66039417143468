import { makeStyles } from "@toolkit/ui";

export const usePatientEligibilityPageStyles = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    overflowY: "auto",
  },
  cardsStack: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    paddingInline: 8,
  },
  eligibilityAndPlanCodeWrap: {
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    paddingInline: 16,
  },
  cardBase: {
    padding: 16,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
  },
  screenShotCard: {
    padding: 16,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    alignContent: "center",
    justifyContent: "center",
  },
  cardTitle: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    marginBottom: 8,
  },
  cardlessText: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  loadingText: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  disclaimer: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: -4,
    marginBottom: 8,
    textAlign: "start",
  },
  cardBodyText: {
    textAlign: "start",
  },
  benefitsCard: {
    minHeight: 600,
  },
  snapshotsWrapper: {
    display: "flex",
    flexDirection: "row", // Ensures items are laid out horizontally
    gap: "4px", // Space between items
    overflowX: "auto", // Enable horizontal scrolling
    overflowY: "hidden", // Prevent vertical scroll
    whiteSpace: "nowrap", // Prevents wrapping of child elements
    scrollBehavior: "smooth", // Smooth scrolling experience
  },
  imageDisplayContainer: {
    flex: "0 0 auto", // Prevent shrinking and allow horizontal scrolling
    scrollSnapAlign: "center", // Enable snapping behavior
  },
}));
