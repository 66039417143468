import { FC, MouseEvent, PropsWithChildren, useCallback, useState } from "react";
import { useSelectCall } from "@health/meeting";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CustomIcon,
  Grid,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  useTheme,
} from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { VisitsView } from "../VisitStartingNowNotification/VisitsView";
import { DeclineModel } from "../VisitStartingNowNotification/DeclineModel";

type VisitCallJoinStatusBarProps = PropsWithChildren<{
  visitId: string;
  onDecline: () => void;
  onClose: () => void;
}>;

export const VisitCallJoinStatusBar: FC<VisitCallJoinStatusBarProps> = ({ visitId, onDecline, onClose }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("provider");
  const { call } = useSelectCall(visitId) || {};

  const { muteAudio, muteVideo } = call || {};
  const { audioMuted, videoMuted } = call?.state || {};
  const handleToggleAudio = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteAudio?.(m => !m);
    },
    [muteAudio]
  );
  const handleToggleVideo = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteVideo?.(m => !m);
    },
    [muteVideo]
  );
  const [loading, setLoading] = useState(false);
  const handleJoin = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      try {
        e.stopPropagation();
        setLoading(true);
        await call?.joinCall();
        navigate(`/visit-call?visitId=${visitId}`);
      } finally {
        setLoading(false);
      }
    },
    [call, navigate, visitId]
  );

  if (!call) {
    return null;
  }

  return (
    <>
      <Box display={"flex"} bgcolor={theme.palette.common.white} width='100vw' boxShadow={theme.mixins.shadows.md}>
        <Container fixed>
          <Grid container spacing={2} justifyContent='center' alignItems='center' height='100px'>
            <VisitsView number={visitId!} />

            <Grid item xs='auto'>
              <Box height='100%' display='flex' alignItems='center'>
                <Button title={!videoMuted ? t("Stop video") : t("Start video")} variant='text' onClick={handleToggleVideo}>
                  {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
                </Button>

                <Button title={!audioMuted ? t("Mute") : t("Unmute")} variant='text' onClick={handleToggleAudio}>
                  {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
                </Button>
                <Button disabled={!call || loading} onClick={handleJoin}>
                  {loading ? <CircularProgress color='inherit' size={20} /> : t("Join")}
                </Button>
                <DeclineModel onDecline={onDecline} />
              </Box>
            </Grid>

            <Grid item xs='auto'>
              <Button variant='text' onClick={onClose} startIcon={<CustomIcon icon='close' />}>
                {t("Close")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
