/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import { TransactionClaimSortField, TransactionSortDirection } from "@/schema/types";
import { transactionSourceTypeOptionsMap, transactionStatusTypeOptionsMap } from "@health/enum-options";
import { ExtractNodeType } from "@toolkit/apollo";
import { DATE_FORMAT_FOR_TABLE_LONG, toTitleCase } from "@toolkit/core";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, format, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { ClaimActivitiesDrawer } from "pages/OptimaClaims/Components/ClaimActivitiesDrawer/ClaimActivitiesDrawer";
import { RemittanceDrawer } from "pages/OptimaClaims/Components/RemittanceDrawer/RemittanceDrawer";
import { ResubmissionDrawer } from "pages/OptimaClaims/Components/ResubmissionDrawer/ResubmissionDrawer";
import { OptimaClaimsListQuery } from "pages/OptimaClaims/gql";
import { useMemo } from "react";
import { GridCellText } from "../../../../components";
import { formatFilterDateForBackend, getOptimaAuthorizationStatusColor } from "../../../../utils";

type OptimaClaimListContainerColumnsProps = {
  isProviderAdmin?: boolean;
  formattedCurrency: string;
};

export const useOptimaClaimListContainerColumns = (
  props: OptimaClaimListContainerColumnsProps
): CustomTableColumnProps<ExtractNodeType<OptimaClaimsListQuery>>[] => {
  const { isProviderAdmin, formattedCurrency } = props;
  const { t } = useTranslation("domains");
  const theme = useTheme();

  return useMemo(() => {
    return [
      {
        key: "transactionId",
        header: t("Transaction Id"),
        accessor: ({ transactionId }) => <GridCellText text={transactionId} />,
        filter: {
          type: "string",
          name: "transactionId",
        },
      },
      {
        key: "transactionDate",
        header: t("Transaction Date"),
        isSortable: true,
        sortDirection: TransactionSortDirection.Desc,
        sortColumnEnum: TransactionClaimSortField.TransactionDate,
        accessor: ({ transactionDate }) => (
          <Box sx={{ width: "max-content" }}>{transactionDate && format(transactionDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language)}</Box>
        ),
      },
      {
        key: "authorizationStatus",
        header: t("Status"),
        accessor: ({ remittance }) => (
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            color={getOptimaAuthorizationStatusColor(remittance?.status || "", theme)}
          >
            {transactionStatusTypeOptionsMap[remittance?.status || ""]?.label || t("Pending")}
          </Typography>
        ),
      },
      {
        key: "transactionType",
        header: t("Transaction Type"),
        accessor: ({ transactionType }) => transactionType || "-",
      },
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ provider }) => <TruncateTypography text={toTitleCase(provider?.name || "-")} />,
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ Branch }) => pickLocalizedValue(Branch?.name, Branch?.nameAr) || "-",
        hideFromPreferencesAndTable: !isProviderAdmin,
      },
      {
        key: "patientId",
        header: t("Patient Id"),
        accessor: ({ patientId }) => <GridCellText text={patientId} />,
        filter: {
          type: "string",
          name: "patientId",
        },
      },
      {
        key: "memberId",
        header: t("Member Id"),
        accessor: ({ memberId }) => <GridCellText text={memberId} />,
        filter: {
          type: "string",
          name: "memberId",
        },
      },
      {
        key: "emiratesIDNumber",
        header: t("Emirate ID"),
        accessor: ({ emiratesIDNumber }) => <GridCellText text={emiratesIDNumber} />,
        filter: {
          type: "string",
          name: "emiratesIDNumber",
        },
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => <TruncateTypography text={toTitleCase(payer?.name || "-")} />,
      },
      {
        key: "payerId",
        header: t("Payer Id"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "string",
          name: "payerId",
        },
      },
      {
        key: "receiver",
        header: t("Receiver"),
        accessor: ({ receiver }) => <TruncateTypography text={toTitleCase(receiver?.name || "-")} />,
      },
      {
        key: "receiverId",
        header: t("Receiver Id"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "string",
          name: "receiverId",
        },
      },
      {
        key: "encounterStartType",
        header: t("Encounter Start Type"),
        accessor: ({ encounterStartType, encounterStartTypeLookup }) => encounterStartTypeLookup?.name || encounterStartType || "-",
      },
      {
        key: "encounterStart",
        header: t("Encounter Start Date"),
        accessor: ({ encounterStart }) => (
          <GridCellText text={encounterStart ? format(encounterStart, "DD MMMM YYYY | HH:mm A", i18n.language) : "-"} />
        ),
      },
      {
        key: "encounterEndType",
        header: t("encounter End Type"),
        accessor: ({ encounterEndType, encounterEndTypeLookup }) => encounterEndTypeLookup?.name || encounterEndType || "-",
      },
      {
        key: "encounterEnd",
        header: t("Encounter End Date"),
        accessor: ({ encounterEnd }) => (
          <GridCellText text={encounterEnd ? format(encounterEnd, "DD MMMM YYYY | HH:mm A", i18n.language) : "-"} />
        ),
      },
      {
        key: "gross",
        header: t("Gross"),
        accessor: ({ gross }) => (gross ? gross + formattedCurrency : "-"),
      },
      {
        key: "net",
        header: t("Net"),
        accessor: ({ net }) => (net ? net + formattedCurrency : "-"),
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => transactionSourceTypeOptionsMap[source].label,
      },
      {
        key: "claimActivities",
        header: t("Activities"),
        accessor: claim => <ClaimActivitiesDrawer claim={claim} />,
      },
      {
        key: "Remittance",
        header: t("Remittance"),
        accessor: claim => <RemittanceDrawer claim={claim} />,
      },
      {
        key: "Resubmission",
        header: t("Resubmission"),
        accessor: claim => <ResubmissionDrawer claim={claim} />,
      },
      {
        key: "transactionDateInputGte",
        header: t("Transaction Date From"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "transactionDateInput.gte",
        },
      },
      {
        key: "transactionDateInputLte",
        header: t("Transaction Date To"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "transactionDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
      {
        key: "encounterStartDateInputGte",
        header: t("Encounter Start Date From"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "encounterStartDateInput.gte",
        },
      },
      {
        key: "encounterStartDateInputLte",
        header: t("Encounter Start Date To"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "encounterStartDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
      {
        key: "encounterEndDateInputGte",
        header: t("Encounter End Date From"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "encounterEndDateInput.gte",
        },
      },
      {
        key: "encounterEndDateInputLte",
        header: t("Encounter End Date To"),
        showOnlyForFilter: true,
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "encounterEndDateInput.lte",
          getValueForBackend: formatFilterDateForBackend,
        },
      },
    ];
  }, [t]);
};
