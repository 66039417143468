import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { CoverageItem, useCoverageListColumns } from "./useCoverageListColumns";

export const BenefitCoverageModal: FC<{ data: CoverageItem[] }> = props => {
  const { t } = useTranslation("optima");
  const { data = [] } = props;
  const { open, handleClose, handleOpen } = useOpenState();
  return (
    <CustomDialog
      type='base'
      open={open}
      onClose={handleClose}
      maxWidth='md'
      button={
        <Button onClick={handleOpen} variant='contained' color='primary'>
          {t("Show Coverage")}
        </Button>
      }
      DialogTitleProps={{
        title: t("Coverage"),
      }}
    >
      <CustomTable columns={useCoverageListColumns()} data={data} />
    </CustomDialog>
  );
};
