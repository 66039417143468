import { useNavigate } from "react-router-dom";
import { IUserProviderListContainerEvent, UserProviderListContainer } from "@health/domains";
import { usersPaths } from "../../constants";
import { useSetUsersBreadcrumbs } from "../../hooks";

export const ProviderUserListContainer = () => {
  const navigate = useNavigate();

  const onUserProviderListContainerChange = (event: IUserProviderListContainerEvent) => {
    if (event.type === "ADD_NEW_CLICK") {
      navigate(usersPaths.create.fullPath);
    } else if (event.type === "EDIT_CLICK") {
      navigate(usersPaths.update.fullPathWithParams({ userId: event.payload?.item?.id }));
    }
  };

  useSetUsersBreadcrumbs("LIST");

  return <UserProviderListContainer onChange={onUserProviderListContainerChange} />;
};
