import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderGetInsuranceApprovalMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type OrderGetInsuranceApprovalMutation = { __typename?: 'Mutation', orderGetErxHubApproval?: { __typename?: 'Order', id: string, number: string, status: Types.OrderStatus } | null };


export const OrderGetInsuranceApprovalDocument = gql`
    mutation orderGetInsuranceApproval($orderId: ID!) {
  orderGetErxHubApproval(orderId: $orderId) {
    id
    number
    status
  }
}
    `;
export type OrderGetInsuranceApprovalMutationFn = Apollo.MutationFunction<OrderGetInsuranceApprovalMutation, OrderGetInsuranceApprovalMutationVariables>;

/**
 * __useOrderGetInsuranceApprovalMutation__
 *
 * To run a mutation, you first call `useOrderGetInsuranceApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderGetInsuranceApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderGetInsuranceApprovalMutation, { data, loading, error }] = useOrderGetInsuranceApprovalMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderGetInsuranceApprovalMutation(baseOptions?: Apollo.MutationHookOptions<OrderGetInsuranceApprovalMutation, OrderGetInsuranceApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderGetInsuranceApprovalMutation, OrderGetInsuranceApprovalMutationVariables>(OrderGetInsuranceApprovalDocument, options);
      }
export type OrderGetInsuranceApprovalMutationHookResult = ReturnType<typeof useOrderGetInsuranceApprovalMutation>;
export type OrderGetInsuranceApprovalMutationResult = Apollo.MutationResult<OrderGetInsuranceApprovalMutation>;
export type OrderGetInsuranceApprovalMutationOptions = Apollo.BaseMutationOptions<OrderGetInsuranceApprovalMutation, OrderGetInsuranceApprovalMutationVariables>;