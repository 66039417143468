import { makeStyles } from "@toolkit/ui";

export const useCreateEligibilityRequestFormStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  submitButton: {},
}));
