/* eslint-disable sonarjs/cognitive-complexity */
import { patientEligibilityBenefitsEnumOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import {
  AutocompleteComponent,
  Box,
  Button,
  Card,
  CheckMarkIcon,
  CircularProgress,
  ImagesViewer,
  MDTextEditor,
  MuiErrorIcon,
  Typography,
} from "@toolkit/ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePatientEligibilityRequestHistoryLazyQuery } from "../../gql";
import { BenefitsList } from "../BenefitsList/BenefitsList";
import { BenefitItem } from "../BenefitsList/useBenefitsListColumns";
import { OptimaCreateEligibilityRequestModal, OptimaCreateEligibilityRequestModalApi } from "../OptimaCreateEligibilityRequestModal";
import { usePatientEligibilityPageStyles } from "./usePatientEligibilityPageStyles";

let optimisticInProgressIntervalId: number | null = null;

interface IEligibilityScanResultWidgetProps {
  id: string;
  nationalId: string;
}
export const EligibilityScanResultWidget: React.FC<IEligibilityScanResultWidgetProps> = props => {
  const { id, nationalId } = props;
  const { classes, cx } = usePatientEligibilityPageStyles();
  const [benefit, setBenefit] = useState<(typeof patientEligibilityBenefitsEnumOptions)[number]>(patientEligibilityBenefitsEnumOptions[0]);
  const [fetchPatientEligibilityRequestHistory, { loading, data, refetch }] = usePatientEligibilityRequestHistoryLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [isDoingOptimisticScanCheck, setIsDoingOptimisticScanCheck] = useState<boolean>(false);

  const { t } = useTranslation("optima");

  useEffect(() => {
    fetchPatientEligibilityRequestHistory({
      variables: {
        patientId: id,
      },
    });
  }, []);

  const handleBenefitAutocompleteChange = useCallback((_, value) => {
    setBenefit(value);
  }, []);

  const nodeData = data?.patientEligibilityRequestHistory;
  const nodeDataRef = useRef<typeof nodeData>(nodeData);

  nodeDataRef.current = nodeData;
  const isScanCompleted = !!nodeData?.isCompleted;
  const isFounded = !!nodeData?.isFounded;
  const eligibilityData = nodeData?.patientEligibility;
  const payerEligibilityRequestHistories = nodeData?.payerEligibilityRequestHistories;
  const benefitData = eligibilityData?.benefitEligibility?.find(b => b?.benefit?.toUpperCase() === benefit.value.toString().toUpperCase());

  const snapshotList = eligibilityData?.snapshots?.map(item => item!);

  const handleOpenCreateModal = useCallback(() => {
    OptimaCreateEligibilityRequestModalApi.open({
      nationalId: nationalId,
    });
  }, [nationalId]);

  const handleSubmitSuccess = useCallback(() => {
    optimisticInProgressIntervalId = setInterval(() => {
      if (!nodeDataRef.current?.isCompleted) {
        refetch();
      } else {
        clearInterval(optimisticInProgressIntervalId!);
        setIsDoingOptimisticScanCheck(false);
      }
    }, 5000) as unknown as number;
    setIsDoingOptimisticScanCheck(true);
    refetch();
  }, [nodeDataRef.current?.isCompleted, refetch]);

  useEffect(() => {
    isDoingOptimisticScanCheck && setIsDoingOptimisticScanCheck(false);
  }, [nodeData, isDoingOptimisticScanCheck]);

  return loading ? (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CircularProgress size={40} />
      <Typography className={classes.loadingText}>{t("Loading...")}</Typography>
    </Box>
  ) : (
    <>
      <AutocompleteComponent
        options={patientEligibilityBenefitsEnumOptions}
        getOptionLabel={option => option?.label}
        TextFieldProps={{
          placeholder: t("Benefit"),
          sx: { maxWidth: 200 },
        }}
        sx={{ maxWidth: 200 }}
        value={benefit}
        onChange={handleBenefitAutocompleteChange}
      />

      <Box className={classes.cardsStack}>
        {((nodeData && !isScanCompleted) || isDoingOptimisticScanCheck) && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={40} />
            <Typography className={classes.loadingText}>{t("Scan in Progress...")}</Typography>
          </Box>
        )}
        {isFounded && eligibilityData && (
          <>
            <Box className={classes.eligibilityAndPlanCodeWrap}>
              <Typography className={classes.cardlessText} color={benefitData?.isEligible ? "success.main" : "error.main"}>
                {benefitData?.isEligible ? t("Active") : t("Not Active")}
              </Typography>
              <Typography className={classes.cardlessText}>-----</Typography>
            </Box>
            <Card className={classes.cardBase}>
              <Typography className={classes.cardTitle}>{t("Auto Coverage")}</Typography>
              {benefitData?.aiJustification && (
                <>
                  <Typography className={classes.disclaimer} color={"error.main"}>
                    {t("Disclaimer")}:{" "}
                    {t("The system is still in the testing phase; it's essential to double-check the results from the screenshots.")}
                  </Typography>
                  <Typography className={classes.cardBodyText}>{benefitData?.aiJustification}</Typography>
                </>
              )}
            </Card>
            <Card className={cx(classes.cardBase, classes.screenShotCard)}>
              <Typography className={classes.cardTitle}>{t("Screenshot")}</Typography>
              <Box className={classes.snapshotsWrapper}>
                {snapshotList?.length ? (
                  <ImagesViewer images={snapshotList} />
                ) : (
                  eligibilityData?.snapshots?.length === 0 && <Typography>{t("No Screenshot")}</Typography>
                )}
              </Box>
            </Card>
            <Card className={cx(classes.cardBase, classes.benefitsCard)}>
              <Typography className={classes.cardTitle}>{t("Benefits Extracted Texts")}</Typography>
              {eligibilityData?.mdSummary ? (
                <MDTextEditor value={eligibilityData?.mdSummary || ""} height={600} readonly />
              ) : (
                <Typography className={classes.cardBodyText}>{eligibilityData?.summary}</Typography>
              )}
            </Card>
            <Card sx={{ padding: 2 }}>
              <Typography className={classes.cardTitle}>{t("Benefits")}</Typography>
              <BenefitsList benefits={eligibilityData?.benefits as BenefitItem[]} />
            </Card>
          </>
        )}
        {!loading && !nodeData && (
          <>
            <Typography>{t("Not scanned yet, you can scan now.")}</Typography>
            <Button onClick={handleOpenCreateModal} sx={{ maxWidth: 200, mx: "auto" }}>
              {t("Scan Now")}
            </Button>
          </>
        )}
        {payerEligibilityRequestHistories && (
          <Card className={cx(classes.cardBase)} sx={{ mb: 2 }}>
            <Typography className={classes.cardTitle}>{t("Patient Scans History")}</Typography>
            <Box sx={{ rowGap: 1, display: "flex", flexDirection: "column" }}>
              {payerEligibilityRequestHistories?.map(item => (
                <Box key={item?.id} sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                  <Typography>
                    {item?.failed ? (
                      <MuiErrorIcon />
                    ) : (
                      <> {item?.isFounded ? <CheckMarkIcon color='success' /> : <CheckMarkIcon color='warning' />}</>
                    )}
                  </Typography>
                  <Typography>{item?.insuranceName}</Typography>
                  {item?.isFounded ? <Typography>{t("Found")}</Typography> : <Typography>{t("Not Found")}</Typography>}
                </Box>
              ))}
            </Box>
            {payerEligibilityRequestHistories.every(payer => payer?.isFounded === false) && isScanCompleted && (
              <>
                <Typography>{t("Not found yet, you can scan other insurances.")}</Typography>
                <Button onClick={handleOpenCreateModal} sx={{ maxWidth: 200, mx: "auto" }}>
                  {t("Scan Now")}
                </Button>
              </>
            )}
          </Card>
        )}
        <OptimaCreateEligibilityRequestModal onSubmitSuccess={handleSubmitSuccess} />
      </Box>
    </>
  );
};
