import { makeStyles } from "@toolkit/ui";

export const useOptimaTabCurrentVisitStyle = makeStyles()(theme => ({
  drawer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
    padding: theme.spacing(2),
  },
}));
