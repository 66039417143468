import { AppointmentPaymentRequest, AppointmentStatus, Maybe, Patient, PaymentStatus } from "@/schema/types";
import { appointmentStatusOptionsMap } from "@health/enum-options";
import { Box, Button, CircularProgress, CustomDialog, Divider, Stack, Tooltip, Typography } from "@toolkit/ui";
import {
  AppointmentDate,
  AppointmentReschedulePaymentInformation,
  AvailableTimeSlots,
  InsuranceInformation,
} from "pages/appointments/components";
import React, { FC, ReactElement } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { getCodedDoctorId, isDoctorUser, isVendorAdmin } from "utils";
import { useAppointmentReschedule } from "./useAppointmentReschedule";
import { useAppointmentRescheduleStyle } from "./useAppointmentRescheduleStyle";

type AppointmentRescheduleProps = {
  id: string;
  doctorId?: string;
  status: AppointmentStatus;
  patient?: Maybe<Patient>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  buttonEl?: ReactElement;
  isAppointmentComingFromGuidedCareTask?: boolean;
  onChange?: () => void;
};

export const AppointmentReschedule: FC<AppointmentRescheduleProps> = props => {
  const {
    id,
    doctorId: comingDoctorId,
    status,
    buttonEl,
    patient,
    paymentStatus,
    paymentInfo,
    isAppointmentComingFromGuidedCareTask = false,
    onChange,
  } = props;

  const { userInfo } = useOidcUserProfile();
  const isDoctor = isDoctorUser(userInfo);
  const isVendorAdminUser = isVendorAdmin(userInfo);
  const currentDoctorId = getCodedDoctorId(userInfo);
  const doctorId = comingDoctorId ?? (isDoctor && !isVendorAdminUser ? currentDoctorId : undefined);

  const {
    t,
    selectedDate,
    timeSlots,
    isLoading,
    open,
    handleToggle,
    handleOpen,
    selectedTimeSlots,
    isSubmitting,
    isDisable,
    isExpiredDate,
    handleSubmit,
    handleSelectTimeSlot,
    handleChangeDate,
  } = useAppointmentReschedule({
    id,
    doctorId,
    paymentInfo,
    status,
    isAppointmentComingFromGuidedCareTask,
    onChange,
  });

  const { classes } = useAppointmentRescheduleStyle();

  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Appointment Reschedule"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={isDisable ? `${appointmentStatusOptionsMap[status]?.label} ${t("is not allow to reschedule")}` : ""}>
          <span>
            {buttonEl ? (
              React.cloneElement(buttonEl, { onClick: handleOpen, disabled: isDisable })
            ) : (
              <Button variant='outlined' sx={{ width: "120px" }} disabled={isDisable} onClick={handleOpen}>
                {t("Reschedule")}
              </Button>
            )}
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <>
            <Tooltip title={!selectedTimeSlots?.length ? t("Please Select Time Slots") : ""}>
              <span>
                <Button onClick={handleSubmit} disabled={!selectedTimeSlots?.length || isSubmitting}>
                  {t("Reschedule")}
                </Button>
              </span>
            </Tooltip>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={2}
        divider={<Divider sx={{ width: "100%" }} />}
      >
        <Box className={classes.expiredDateWrapper}>
          <AppointmentDate date={selectedDate} onChangeDate={handleChangeDate} />

          {isExpiredDate && (
            <Typography color='error' className={classes.expiredDateMessage}>
              {t("The Date for this Appointment is Expired")}
            </Typography>
          )}
        </Box>

        {isLoading ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          <AvailableTimeSlots
            isMulti
            availableTimeSlots={timeSlots}
            selectedTimeSlots={selectedTimeSlots}
            onSelect={handleSelectTimeSlot}
          />
        )}

        <Box>
          <InsuranceInformation
            activeHealthProgramMember={patient?.activeHealthProgramMembers?.[0]}
            healthProgramsByActiveMember={patient?.healthProgramsByActiveMembers?.[0]}
          />
        </Box>

        {paymentInfo?.totalAmount && <AppointmentReschedulePaymentInformation paymentInfo={paymentInfo} paymentStatus={paymentStatus} />}
      </Stack>
    </CustomDialog>
  );
};
