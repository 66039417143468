import React, { useState } from "react";
import { IconButton, Tooltip, Button } from "../../base/mui";
import { MuiContentCopyIcon } from "../../base/mui-icons";
import { useTranslation } from "@toolkit/i18n";

interface CopyToClipboardButtonProps {
  textToCopy: string;
  buttonVariant?: "contained" | "outlined" | "text";
  tooltipText?: string;
  buttonCopyText?: string;
  successTooltipText?: string;
  showIconOnly?: boolean;
  resetTimeInMs?: number;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = props => {
  const {
    textToCopy,
    buttonVariant = "contained",
    tooltipText = "Copy to clipboard",
    successTooltipText = "Copied!",
    buttonCopyText = "Copy",
    showIconOnly = false,
    resetTimeInMs = 2000,
  } = props;
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), resetTimeInMs);
    });
  };

  return (
    <Tooltip title={t(copied ? successTooltipText : tooltipText)} arrow>
      {showIconOnly ? (
        <IconButton onClick={handleCopy} color='primary'>
          <MuiContentCopyIcon />
        </IconButton>
      ) : (
        <Button onClick={handleCopy} variant={buttonVariant} startIcon={<MuiContentCopyIcon />}>
          {t(buttonCopyText)}
        </Button>
      )}
    </Tooltip>
  );
};
