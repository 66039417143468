import { useCallback, useMemo } from "react";
import { useFlipCamera } from "./useFlipCamera";
import { useZoom } from "./useZoom";
import { CallState, useCallState } from "./useCallState";
import { useCallParticipants } from "./useCallParticipants";
import { useMuteSpeaker } from "./useMuteSpeaker";
import { Meeting } from "@/schema/types";
import { ExecutedResponse } from "./types";
import { useTranslation } from "@toolkit/i18n";

export type CallInstance = ReturnType<typeof useCall>;
export function useCall({ meeting, user }: { meeting?: Meeting; user?: { userId?: number; userName?: string } }) {
  const { t } = useTranslation();
  const { userId, userName } = user || {};
  const { id: callId, topic, jwtToken } = meeting || {};
  const { flipCamera } = useFlipCamera();
  const { joinSession, leaveSession } = useZoom();
  const { muteSpeaker, speakerMuted } = useMuteSpeaker();

  const { currentParticipant, participants, muteAudio, muteVideo, muteScreen } = useCallParticipants({
    userId: userId,
    displayName: userName,
    meeting,
  });
  const { displayName } = currentParticipant;
  const { audioMuted, videoMuted } = currentParticipant.state;

  const { callState, updateCallStatus, resetCallState } = useCallState(meeting);

  const joinCall = useCallback(async (): Promise<ExecutedResponse> => {
    if (!callId) {
      return;
    }

    if (!topic || !jwtToken || !displayName) {
      return;
    }

    const result = await joinSession?.({
      topic: topic,
      token: jwtToken,
      userName: displayName,
      sessionPassword: "",
      audioOptions: { mute: audioMuted },
      videoOptions: { localVideoOn: !videoMuted },
    });

    if (result.success) {
      updateCallStatus(CallState.InCall);
    }

    return result;
  }, [callId, topic, jwtToken, displayName, joinSession, audioMuted, videoMuted, updateCallStatus]);

  const dropCall = useCallback(async () => {
    updateCallStatus(CallState.Dropped);
    return leaveSession?.();
  }, [leaveSession, updateCallStatus]);

  return useMemo(() => {
    return {
      callId,
      callInfo: meeting,
      callInvitationToken: "",
      visitId: meeting?.chatGroup?.visitId,
      title: meeting?.chatGroup?.name || t("Meeting"),
      participants,
      currentParticipant,
      state: {
        ...currentParticipant.state,
        callState,
        speakerMuted,
      },
      flipCamera,
      muteAudio,
      muteVideo,
      muteScreen,
      muteSpeaker,
      joinCall,
      dropCall,
      updateCallStatus,
      resetCallState,
    };
  }, [
    callId,
    meeting,
    t,
    participants,
    currentParticipant,
    callState,
    speakerMuted,
    flipCamera,
    muteAudio,
    muteVideo,
    muteScreen,
    muteSpeaker,
    joinCall,
    dropCall,
    updateCallStatus,
    resetCallState,
  ]);
}
