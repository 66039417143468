import { Appointment, GuidedCareTask, TaskWorkflowType } from "@/schema/types";
import { Box, CircularProgress } from "@toolkit/ui";
import { client } from "@/shared/configs/apollo";
import { isGuidedCareTaskActionable } from "../../others";
import { FC } from "react";
import { useGuidedCareJourneyItemGetQuery } from "../../gql";
import { GuidedCareTaskActionsAppointment } from "../GuidedCareTaskActionsAppointment/GuidedCareTaskActionsAppointment";
import { GuidedCareTaskActionsMedicalForm } from "../GuidedCareTaskActionsMedicalForm/GuidedCareTaskActionsMedicalForm";
import { GuidedCareTaskActionsEnrollmentJourney } from "../GuidedCareTaskActionsEnrollmentJourney/GuidedCareTaskActionsEnrollmentJourney";
import { GuidedCareTaskUpdate } from "../GuidedCareTaskUpdate/GuidedCareTaskUpdate";
import { useGuidedCareTaskActionsStyle } from "./useGuidedCareTaskActionsStyle";

type GuidedCareTaskActionsProps = {
  guidedCareTask: GuidedCareTask;
  isUpdateHidden?: boolean;
};

export const GuidedCareTaskActions: FC<GuidedCareTaskActionsProps> = props => {
  const { guidedCareTask, isUpdateHidden = false } = props;

  const { classes } = useGuidedCareTaskActionsStyle();

  const taskWorkflowType = guidedCareTask?.taskDefinition?.taskWorkflowType;
  const guidedCareTaskActionable = isGuidedCareTaskActionable(guidedCareTask?.status!);

  const { data, loading } = useGuidedCareJourneyItemGetQuery({
    variables: {
      guidedCareJourneyItemId: guidedCareTask?.guidedCareJourneyItemId!,
    },
    skip: !guidedCareTask?.guidedCareJourneyItemId,
  });

  const guidedCareJourneyItem = data?.guidedCareJourneyItem;

  const handleUpdateCache = () => {
    client.cache.evict({ fieldName: isUpdateHidden ? "guidedCareTask" : "guidedCareTasks" });
    client.cache.gc();
  };

  return (
    <Box className={classes.root}>
      {loading ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <>
          {guidedCareTaskActionable && (
            <>
              {taskWorkflowType === TaskWorkflowType.ConfirmAppointmentDate && (
                <GuidedCareTaskActionsAppointment
                  appointment={guidedCareJourneyItem?.appointment as Appointment}
                  onChange={handleUpdateCache}
                />
              )}

              {taskWorkflowType === TaskWorkflowType.ReviewMedicalFormAnswer && (
                <GuidedCareTaskActionsMedicalForm
                  medicalFormAnswers={guidedCareJourneyItem?.medicalFormAnswers?.edges?.[0]?.node!}
                  guidedCareJourneyItemId={guidedCareTask?.guidedCareJourneyItemId!}
                  taskWorkflowType={guidedCareTask?.taskDefinition?.taskWorkflowType!}
                  assigneeUserId={guidedCareTask?.assignee?.[0]?.assigneeUserId!}
                  onChange={handleUpdateCache}
                />
              )}

              {taskWorkflowType === TaskWorkflowType.EnrollmentJourneyCompleted && (
                <GuidedCareTaskActionsEnrollmentJourney patient={guidedCareTask?.patient!} onChange={handleUpdateCache} />
              )}
            </>
          )}

          {!isUpdateHidden && <GuidedCareTaskUpdate guidedCareTask={guidedCareTask} />}
        </>
      )}
    </Box>
  );
};
