import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientEnrollmentRequestsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientEnrollmentRequestFilterInput>;
}>;


export type PatientEnrollmentRequestsQuery = { __typename?: 'Query', patientEnrollmentRequests?: { __typename?: 'PatientEnrollmentRequestCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'PatientEnrollmentRequestCountableEdge', node: { __typename?: 'PatientEnrollmentRequest', id: string, status?: Types.PatientEnrollmentRequestStatus | null, error?: string | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null } | null, team?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, createdDate?: any | null, provider: { __typename?: 'Vendor', id: string, name: string }, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } } | null } }> } | null };


export const PatientEnrollmentRequestsDocument = gql`
    query patientEnrollmentRequests($first: Int, $after: String, $filter: PatientEnrollmentRequestFilterInput) {
  patientEnrollmentRequests(first: $first, after: $after, filter: $filter) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        status
        error
        patient {
          id
          firstName
          lastName
        }
        team {
          id
          name
          createdDate
          provider {
            id
            name
          }
          guidedCareHealthProgram {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePatientEnrollmentRequestsQuery__
 *
 * To run a query within a React component, call `usePatientEnrollmentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientEnrollmentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEnrollmentRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePatientEnrollmentRequestsQuery(baseOptions?: Apollo.QueryHookOptions<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>(PatientEnrollmentRequestsDocument, options);
      }
export function usePatientEnrollmentRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>(PatientEnrollmentRequestsDocument, options);
        }
export function usePatientEnrollmentRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>(PatientEnrollmentRequestsDocument, options);
        }
export type PatientEnrollmentRequestsQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsQuery>;
export type PatientEnrollmentRequestsLazyQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsLazyQuery>;
export type PatientEnrollmentRequestsSuspenseQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsSuspenseQuery>;
export type PatientEnrollmentRequestsQueryResult = Apollo.QueryResult<PatientEnrollmentRequestsQuery, PatientEnrollmentRequestsQueryVariables>;