import { useTranslation } from "@toolkit/i18n";
import { CheckMarkIcon, Typography } from "@toolkit/ui";
import React from "react";
import { useOptimaPassedItemStyles } from "./OptimaPassedItemStyle";

interface OptimaPassedItemProps {}

export const OptimaPassedItem: React.FC<OptimaPassedItemProps> = () => {
  const { t } = useTranslation("domains");
  const { classes } = useOptimaPassedItemStyles();

  return (
    <Typography className={classes.score}>
      {t("Overall Approval Score")}: <CheckMarkIcon fontSize='small' />{" "}
      <Typography component='span' className={classes.passed}>
        {t("Passed")}
      </Typography>
    </Typography>
  );
};
