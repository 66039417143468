import { OptimaPriority } from "@/schema/types";
import { MuiDangerousIcon, MuiErrorIcon, MuiWarningIcon, Theme } from "@toolkit/ui";

export const getOptimaEditPropertiesByPriority = (priority: OptimaPriority, theme: Theme) => {
  if (priority === OptimaPriority.High) {
    return {
      color: theme.palette.error.light,
      icon: <MuiDangerousIcon fontSize={"large"} color={"error"} />,
    };
  } else if (priority === OptimaPriority.Medium) {
    return {
      color: `${theme.palette.warning.light}`,
      icon: <MuiWarningIcon fontSize={"large"} color={"warning"} />,
    };
  }

  return {
    color: theme.palette.common.white,
    icon: <MuiErrorIcon fontSize={"large"} />,
  };
};
