import { CallProvider as BaseCallProvider } from "./types";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { CallContext, CallProviderInfo, UserInfo } from "./CallContext";
import { useCall } from "./types";
import { Meeting } from "@/schema/types";

type ProviderProps = PropsWithChildren<{
  user?: UserInfo;
  accessToken?: string;
}>;

const Provider: FC<ProviderProps> = ({ children, accessToken, user: userInfo }) => {
  const [callInfo, setCallInfo] = useState<Meeting>();
  const user = useMemo(() => ({ userId: userInfo?.user_id, userName: userInfo?.name }), [userInfo?.user_id, userInfo?.name]);
  const call = useCall({ meeting: callInfo, user });
  const select = useCallback((v: Meeting) => setCallInfo(v), []);

  const value = useMemo(
    () => ({ callInfo, userInfo, call, select, accessToken } as CallProviderInfo),
    [call, callInfo, select, accessToken, userInfo]
  );

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
};

export const CallProvider: FC<ProviderProps> = props => {
  return (
    <BaseCallProvider>
      <Provider {...props} />
    </BaseCallProvider>
  );
};
