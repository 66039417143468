import { useState } from "react";
import { IOptimaRequestKpi, optimaRequestKpi, requestsKpiMode } from "../../utils";
import { useGetRcmOptimaValidationRequestsKpiCountsQuery } from "../../gql";
import { OptimaValidationRequestFilterInput } from "@/schema/types";

export const useOptimaValidationRequestsListKpiController = () => {
  const [selectedKpiMode, setSelectedKpiMode] = useState<IOptimaRequestKpi>(optimaRequestKpi.all);
  const [activeFilters, setActiveFilters] = useState<OptimaValidationRequestFilterInput>();
  const { data } = useGetRcmOptimaValidationRequestsKpiCountsQuery({
    variables: {
      filterAll: activeFilters,
      filterRejected: optimaRequestKpi.rejected.filters,
      filterPartially: optimaRequestKpi.partially.filters,
    },
  });

  const hasFilter = selectedKpiMode?.type === "all";
  const onActiveFiltersChange = (filtersValue: OptimaValidationRequestFilterInput | undefined) => {
    setActiveFilters(filtersValue);
  };

  const totalCounts = {
    all: data?.allRequests?.totalCount || 0,
    rejected: data?.rejectedRequests?.totalCount || 0,
    partially: data?.partiallyApprovedRequests?.totalCount || 0,
  };

  const handleKpiModeChange = (mode: requestsKpiMode) => {
    setSelectedKpiMode(optimaRequestKpi[mode]);
  };
  return {
    selectedKpiMode,
    handleKpiModeChange,
    totalCounts,
    hasFilter,
    activeFilters,
    onActiveFiltersChange,
  };
};
