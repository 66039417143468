import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CallInstance, CallPublisherView } from "./types";
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { MeetingSelfViewDraggable } from "./MeetingSelfViewDraggable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { Box } from "@toolkit/ui";

type MeetingSelfViewProps = {
  call: CallInstance;
};

export const MeetingSelfView: FC<MeetingSelfViewProps> = ({ call }) => {
  const selfViewRef = useRef<HTMLElement>(null);
  const parentRef = useRef<HTMLElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [selfViewDimensions, setDelfViewDimensions] = useState({ width: 160, height: 160 });

  const { x, y } = useMemo(() => {
    const { width, height } = dimensions;
    if (!width || !height) {
      return { x: 0, y: 0 };
    }

    return { y: height - (selfViewDimensions.height + 15), x: width - selfViewDimensions.width };
  }, [dimensions, selfViewDimensions]);

  const [coordinates, setCoordinates] = useState({ x, y });
  useEffect(() => {
    setCoordinates({ x, y });
  }, [x, y]);

  useEffect(() => {
    const parentElement = parentRef.current?.parentElement;
    if (!parentElement) {
      return;
    }

    setDimensions({
      width: parentElement.clientWidth ?? 0,
      height: parentElement.clientHeight ?? 0,
    });
    const observer = new ResizeObserver(() => {
      setDimensions({
        width: parentElement.clientWidth ?? 0,
        height: parentElement.clientHeight ?? 0,
      });
    });

    observer.observe(parentElement);

    return () => {
      observer.unobserve(parentElement);
    };
  }, []);

  useEffect(() => {
    const selfViewElement = selfViewRef.current;
    if (!selfViewElement) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setDelfViewDimensions({
        width: selfViewElement.clientWidth ?? 160,
        height: selfViewElement.clientHeight ?? 160,
      });
    });

    observer.observe(selfViewElement);

    return () => {
      observer.unobserve(selfViewElement);
    };
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const onDragStart = useCallback(() => {
    document.body.style.setProperty("cursor", "grabbing");
  }, []);

  const onDragEnd = useCallback(({ delta }: DragEndEvent) => {
    document.body.style.setProperty("cursor", "");
    setCoordinates(c => ({ x: c.x + delta.x, y: c.y + delta.y }));
  }, []);

  return (
    <>
      <Box ref={parentRef} />
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToParentElement]}>
        <MeetingSelfViewDraggable id='draggable-meeting-selfview' top={coordinates.y} left={coordinates.x}>
          <Box ref={selfViewRef} sx={{ minWidth: 160, maxWidth: 200 }}>
            <CallPublisherView call={call} />
          </Box>
        </MeetingSelfViewDraggable>
      </DndContext>
    </>
  );
};
