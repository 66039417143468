import { FlowiseIntegrationChatSortingField, FlowrseIntegrationSortDirection } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps } from "@toolkit/ui";
import moment from "moment";
import { DeleteButtons } from "pages/AIAgents/components/DeleteButons";
import { EditButtons } from "pages/AIAgents/components/EditButtons";
import { PreviewButtons } from "pages/AIAgents/components/PreviewButtons";
import { ShareButtons } from "pages/AIAgents/components/ShareButtons";
import { useMemo } from "react";
import { AgentsQueryNode } from "../../types";

export const useAgentsListTableColumns = ({ isAdmin, handleEditClick }): CustomTableColumnProps<AgentsQueryNode>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "external-Id",
        header: t("External ID"),
        showOnlyForFilterField: true,
        filter: {
          name: "externalId",
          type: "string",
        },
      },
      {
        key: "code",
        header: t("Agent Code"),
        accessor: "code",
        filter: {
          name: "code",
          type: "string",
        },
      },
      {
        key: "name",
        header: t("Agent Name"),
        accessor: "name",
        isSortable: true,
        sortColumnEnum: FlowiseIntegrationChatSortingField.Name,
        sortDirection: FlowrseIntegrationSortDirection.Desc,
        filter: {
          name: "name",
          type: "string",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
        filter: {
          name: "description",
          type: "string",
        },
      },
      {
        key: "last-update",
        header: t("Last Modified Date"),
        accessor: ({ updatedAt }) => moment(updatedAt).format("DD MMM YYYY"),
        isSortable: true,
        sortColumnEnum: FlowiseIntegrationChatSortingField.Updated,
        sortDirection: FlowrseIntegrationSortDirection.Desc,
      },
      {
        key: "KB Documents",
        header: t("KB Documents"),
        accessor: ({ documents }) => (documents?.length ? documents.length : "N/A"),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: row => (
          <Box display='flex' flexDirection='row' gap={0}>
            {isAdmin && <ShareButtons agentId={row.id} users={row.contributors} />}
            <PreviewButtons title={row?.name || t("Preview")} agentId={row.externalId!} />
            <EditButtons agentId={row.id} handleEditClick={handleEditClick} />
            {isAdmin && <DeleteButtons agentId={row.id} agentName={row.code!} />}
          </Box>
        ),
      },
    ];
  }, []);
};
