import { Box } from "@toolkit/ui";
import { FC } from "react";
import { ContributingFactor } from "../../types";
import { OptimaContributingFactorsAiSuggestionsTable } from "./OptimaContributingFactorsAiSuggestionsTable";

export type OptimaContributingFactorsProps = {
  contributingFactors: ContributingFactor[];
};

export const OptimaContributingFactors: FC<OptimaContributingFactorsProps> = ({ contributingFactors }) => {
  return (
    <Box sx={{ maxWidth: 500 }}>
      <OptimaContributingFactorsAiSuggestionsTable dataSets={contributingFactors} />
    </Box>
  );
};
