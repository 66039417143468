import { OptimaEdit } from "@/schema/types";
import { Typography } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaEditDetailsStyle } from "./useOptimaEditDetailsStyle";

type OptimaEditDetailCardProps = {
  edit: OptimaEdit;
};

export const OptimaEditDetails: FC<OptimaEditDetailCardProps> = props => {
  const { edit } = props;

  const { classes } = useOptimaEditDetailsStyle();

  return <Typography className={classes.message}> - {edit?.message || edit?.details} </Typography>;
};
