import { Stack } from "@toolkit/ui";
import { FC } from "react";
import { PatientProfileTabContainer } from "../PatientProfileTabContainer";
import { PatientTimeLine } from "./PatientTimeLine";
import { Activities } from "./UpcomingActivities/Activities";
import { PendingPatientEnrollmentRequests } from "./PendingJourneyView/PendingPatientEnrollmentRequests";

export const PatientJourney: FC = () => {
  const sections = () => [
    {
      id: 1,
      content: (
        <Stack spacing={8}>
          <PatientTimeLine />
          <Activities />
        </Stack>
      ),
    },
  ];

  return (
    <PatientProfileTabContainer sections={sections()} isContainer isRoot>
      <PendingPatientEnrollmentRequests />
    </PatientProfileTabContainer>
  );
};
