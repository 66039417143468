import { useGuidedCareJourneyGapsStyle } from "./useGuidedCareJourneyGapsStyle";
import { FC } from "react";

type GapsCircleProps = {
  value: number;
};

export const GuidedCareJourneyGaps: FC<GapsCircleProps> = props => {
  const { value } = props;

  const { classes } = useGuidedCareJourneyGapsStyle();

  if (!value) {
    return <> - </>;
  }

  return <span className={classes.root}> {value} </span>;
};
