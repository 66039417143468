import React, { FC, useState } from "react";
import { ImageViewerProps } from "./types";
import Viewer from "react-viewer";
import { getMediaLink, useOpenState } from "@toolkit/core";
import { MuiDeleteIcon, MuiZoomInIcon } from "../../base/mui-icons";
import { styles } from "./styles";

export const ImagesViewer: FC<ImageViewerProps> = ({
  images,
  canDeleteImage,
  onDelete,
  icon,
  classes: classesProp = { containerImg: "", img: "", icons: "" },
}) => {
  const { open, handleClose, handleOpen } = useOpenState();
  const [activeIndex, setActiveIndex] = useState(0);
  const { classes, cx } = styles();

  const imagesLinks = images.map(image => {
    return {
      src: getMediaLink(image)!,
      alt: image!,
    };
  });
  const handleClickOpen = (index: number) => () => {
    handleOpen();
    setActiveIndex(index);
  };
  const handleDelete = (index: number) => () => {
    onDelete?.(index);
  };

  return (
    <>
      {(images as string[])?.map((item, index) => (
        <div key={item + index} className={cx(classes.root)}>
          <div className={cx(classes.containerImg, classesProp.containerImg)}>
            <img className={cx(classes.img, classesProp.img)} src={getMediaLink(item)} alt='ImageUpload' />
            <div className={cx(classes.icons, classes.icons)} onClick={handleClickOpen(index)}>
              {canDeleteImage && (
                <MuiDeleteIcon width={icon?.width ?? 30} height={icon?.height ?? 30} color='error' onClick={handleDelete(index)} />
              )}
              <MuiZoomInIcon width={icon?.width ?? 70} height={icon?.height ?? 70} />
            </div>
          </div>
        </div>
      ))}

      {open && <Viewer zIndex={10000000} visible={open} activeIndex={activeIndex} onClose={handleClose} images={imagesLinks} />}
    </>
  );
};
