import { FC } from "react";
import { LinearProgress, Typography } from "@toolkit/ui";
import { getAdherenceColor } from "../../others";
import { useGuidedCareJourneyAdherenceStyle } from "./useGuidedCareJourneyAdherenceStyle";

type AdherenceProgressProps = {
  value: number;
};

export const GuidedCareJourneyAdherence: FC<AdherenceProgressProps> = props => {
  const { value } = props;

  const { classes, theme } = useGuidedCareJourneyAdherenceStyle();

  const { color, themeColor } = getAdherenceColor(value, theme);

  if (!value) {
    return <> - </>;
  }

  return (
    <>
      <Typography color={color} className={classes.value}>
        {value}%
      </Typography>

      <LinearProgress variant={"determinate"} value={value} color={themeColor} className={classes.linearProgress} />
    </>
  );
};
