import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import { DiscountInformationForm } from "../DiscountFromInformation";
import { DiscountUpsertFormSchema, discountUpsertFormSchemaDefaultValues, IDiscountUpsertFormValues } from "./DiscountUpsertFormSchema";
import { convertDiscountToFormValues } from "../../utils";
import { DiscountsQueryNode } from "../../types";
import { ConditionalDiscountPageWrapper } from "../../components/ConditionalPageWrapper";

type DiscountUpsertFormProps = {
  discount?: DiscountsQueryNode;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  onChange: (values: IDiscountUpsertFormValues) => void;
  discountItems?: Pick<IDiscountUpsertFormValues, "product" | "healthPackage">;
};

export type DiscountUpsertFormRef = {
  submit: () => void;
};

const DiscountUpsertFormForwardRef: ForwardRefRenderFunction<DiscountUpsertFormRef, DiscountUpsertFormProps> = (props, ref) => {
  const { discount, isLoading, isSubmitting, submitButtonLabel, onChange, discountItems } = props;
  const isDiscountItemsDisplayed = !discountItems;

  const form = useCustomForm<IDiscountUpsertFormValues>({
    schema: DiscountUpsertFormSchema,
    defaultValues: discountUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    form.setValue("product", discountItems?.product || []);
    form.setValue("healthPackage", discountItems?.healthPackage || []);
  }, [discountItems]);

  const { handleSubmit, setValues } = form;
  const { t } = useTranslation("provider");
  const isButtonDisabled = isLoading || isSubmitting;

  const onSubmit = (values: IDiscountUpsertFormValues) => {
    onChange(values);
  };

  useEffect(() => {
    if (discount) {
      const _discount = convertDiscountToFormValues(discount);
      setValues(_discount);
    }
  }, [discount, setValues]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getForm: () => form,
        submit: () => handleSubmit(onSubmit)(),
      };
    },
    [form, handleSubmit, onSubmit]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConditionalDiscountPageWrapper
          isLoading={!!isLoading}
          isButtonDisabled={!!isButtonDisabled}
          submitButtonLabel={submitButtonLabel!}
          shouldWrapChildren={isDiscountItemsDisplayed}
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Discount Information")} loading={isLoading} doYouHaveData>
                <DiscountInformationForm isDiscountItemsDisplayed={isDiscountItemsDisplayed} discount={discount} />
              </FormCard>
            </Grid>
          </Grid>
        </ConditionalDiscountPageWrapper>
      </form>
    </FormProvider>
  );
};

export const DiscountUpsertForm = forwardRef(DiscountUpsertFormForwardRef);
