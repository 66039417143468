import { FC } from "react";
import { useOptimaTabCurrentVisitStyle } from "./OptimaVisitResultDetailsStyles";
import { MuiDrawer, ShowButton } from "@toolkit/ui";
import { IOptimaValidationRequestsListNode } from "../../types";
import { OptimaVisitValidationResultDetails } from "@health/domains";
import { useOpenState } from "@toolkit/core";

type OptimaVisitResultDetailsModalProps = {
  optimaValidationRequest: IOptimaValidationRequestsListNode;
};

const OptimaVisitResultDetailsModal: FC<OptimaVisitResultDetailsModalProps> = props => {
  const { optimaValidationRequest } = props;
  const { open, handleOpen, handleToggle } = useOpenState();
  const { classes } = useOptimaTabCurrentVisitStyle();
  return (
    <>
      <ShowButton onClick={handleOpen} />
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleToggle}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <OptimaVisitValidationResultDetails optimaValidationRequest={optimaValidationRequest} />
      </MuiDrawer>
    </>
  );
};

export default OptimaVisitResultDetailsModal;
