import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTaskActionsStyle = makeStyles()({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    marginBlock: 2,
  },
});
