import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramTeamEnrollMutationVariables = Types.Exact<{
  input: Types.GuidedCarePatientTeamEnrollmentInput;
}>;


export type GuidedCareProgramTeamEnrollMutation = { __typename?: 'Mutation', enrollPatientToTeam?: { __typename?: 'PatientEnrollmentRequest', providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareProgramTeamEnrollDocument = gql`
    mutation GuidedCareProgramTeamEnroll($input: GuidedCarePatientTeamEnrollmentInput!) {
  enrollPatientToTeam(input: $input) {
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareProgramTeamEnrollMutationFn = Apollo.MutationFunction<GuidedCareProgramTeamEnrollMutation, GuidedCareProgramTeamEnrollMutationVariables>;

/**
 * __useGuidedCareProgramTeamEnrollMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramTeamEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramTeamEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramTeamEnrollMutation, { data, loading, error }] = useGuidedCareProgramTeamEnrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareProgramTeamEnrollMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramTeamEnrollMutation, GuidedCareProgramTeamEnrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramTeamEnrollMutation, GuidedCareProgramTeamEnrollMutationVariables>(GuidedCareProgramTeamEnrollDocument, options);
      }
export type GuidedCareProgramTeamEnrollMutationHookResult = ReturnType<typeof useGuidedCareProgramTeamEnrollMutation>;
export type GuidedCareProgramTeamEnrollMutationResult = Apollo.MutationResult<GuidedCareProgramTeamEnrollMutation>;
export type GuidedCareProgramTeamEnrollMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramTeamEnrollMutation, GuidedCareProgramTeamEnrollMutationVariables>;