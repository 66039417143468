import { makeStyles } from "@toolkit/ui";

export const useGuidedCareProgramEvaluationResultStyle = makeStyles()(theme => ({
  gridItem: {
    paddingLeft: `12px !important`,
  },
  title: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    textDecoration: "underline",
  },
  label: {
    display: "flex",
    gap: 2,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  suggestedProgramWrapper: {
    marginTop: 8,
  },
}));
