import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorsTeamLeadersAutocompleteQuery } from "./gql";
import { IDoctorTeamLeaderAutocomplete } from "./schema";

export const createDoctorTeamLeaderAutocompleteOption = (doctor: IDoctorTeamLeaderAutocomplete) => {
  return createAutocompleteOption({ id: doctor?.id!, fullName: doctor?.fullName! }, "id", "fullName");
};

export const getDoctorsTeamLeadersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "teamLeaderDoctors",
    query: useDoctorsTeamLeadersAutocompleteQuery,
    labelBy: item => item?.user?.fullName!,
    backendAccessor: item => item?.user?.id!,
  });
};
