import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRcmOptimaValidationRequestsKpiCountsQueryVariables = Types.Exact<{
  filterAll?: Types.InputMaybe<Types.OptimaValidationRequestFilterInput>;
  filterRejected?: Types.InputMaybe<Types.OptimaValidationRequestFilterInput>;
  filterPartially?: Types.InputMaybe<Types.OptimaValidationRequestFilterInput>;
}>;


export type GetRcmOptimaValidationRequestsKpiCountsQuery = { __typename?: 'Query', allRequests?: { __typename?: 'OptimaValidationRequestsConnection', totalCount?: number | null } | null, rejectedRequests?: { __typename?: 'OptimaValidationRequestsConnection', totalCount?: number | null } | null, partiallyApprovedRequests?: { __typename?: 'OptimaValidationRequestsConnection', totalCount?: number | null } | null };


export const GetRcmOptimaValidationRequestsKpiCountsDocument = gql`
    query getRcmOptimaValidationRequestsKpiCounts($filterAll: OptimaValidationRequestFilterInput, $filterRejected: OptimaValidationRequestFilterInput, $filterPartially: OptimaValidationRequestFilterInput) {
  allRequests: rcmOptimaValidationRequests(filter: $filterAll) {
    totalCount
  }
  rejectedRequests: rcmOptimaValidationRequests(filter: $filterRejected) {
    totalCount
  }
  partiallyApprovedRequests: rcmOptimaValidationRequests(filter: $filterPartially) {
    totalCount
  }
}
    `;

/**
 * __useGetRcmOptimaValidationRequestsKpiCountsQuery__
 *
 * To run a query within a React component, call `useGetRcmOptimaValidationRequestsKpiCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRcmOptimaValidationRequestsKpiCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRcmOptimaValidationRequestsKpiCountsQuery({
 *   variables: {
 *      filterAll: // value for 'filterAll'
 *      filterRejected: // value for 'filterRejected'
 *      filterPartially: // value for 'filterPartially'
 *   },
 * });
 */
export function useGetRcmOptimaValidationRequestsKpiCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>(GetRcmOptimaValidationRequestsKpiCountsDocument, options);
      }
export function useGetRcmOptimaValidationRequestsKpiCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>(GetRcmOptimaValidationRequestsKpiCountsDocument, options);
        }
export function useGetRcmOptimaValidationRequestsKpiCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>(GetRcmOptimaValidationRequestsKpiCountsDocument, options);
        }
export type GetRcmOptimaValidationRequestsKpiCountsQueryHookResult = ReturnType<typeof useGetRcmOptimaValidationRequestsKpiCountsQuery>;
export type GetRcmOptimaValidationRequestsKpiCountsLazyQueryHookResult = ReturnType<typeof useGetRcmOptimaValidationRequestsKpiCountsLazyQuery>;
export type GetRcmOptimaValidationRequestsKpiCountsSuspenseQueryHookResult = ReturnType<typeof useGetRcmOptimaValidationRequestsKpiCountsSuspenseQuery>;
export type GetRcmOptimaValidationRequestsKpiCountsQueryResult = Apollo.QueryResult<GetRcmOptimaValidationRequestsKpiCountsQuery, GetRcmOptimaValidationRequestsKpiCountsQueryVariables>;