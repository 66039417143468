import { DoctorError, DoctorProfileInput, User } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { removeEmptyRanges, useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDoctorProfileUpdateMutation, useGetMeDoctorUserQuery } from "./gql";
import { profileBreadCrumb, profileEditBreadCrumb } from "./profile.constant";

export const useProfileFormEditHook = () => {
  const { t } = useTranslation("provider");
  const [errors, setErrors] = useState<DoctorError[]>([]);
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const params = useParams();

  const { data, loading } = useGetMeDoctorUserQuery();

  const [doctorProfileUpdateMutation] = useDoctorProfileUpdateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.doctorProfileUpdate?.doctorErrors;
      if (mutationErrors && mutationErrors?.length > 0) {
        setErrors(mutationErrors);
        failed(formatMessageErrors(mutationErrors));
      } else {
        succeeded(t("Profile updated successfully"));
        navigate("/doctor/profile");
      }
    },
    onError: () => {
      failed(t("Failed in updating profile"));
    },
  });

  const onSubmit = (inputData: DoctorProfileInput | any) => {
    const appointmentTypes = inputData?.appointmentTypes?.filter(item => item?.checked).map(item => item?.value);
    const qualifications = inputData?.qualifications?.map(item => ({
      code: item?.code,
      issuer: item?.issuer,
      fromDate: item?.fromDate,
      toDate: item?.toDate,
    }));
    doctorProfileUpdateMutation({
      variables: {
        input: {
          ...inputData,
          qualifications,
          appointmentTypes,
          seniority: inputData?.seniority?.value,
          languages: inputData?.languages?.map(lang => lang?.code),
          yearsOfExperience: Number(inputData?.yearsOfExperience),
          workingHours: removeEmptyRanges(inputData?.workingHours),
          specializations: inputData?.specializations?.map(specialty => specialty?.value?.code ?? specialty),
        },
      },
    });
  };

  const user = data?.me as User;

  useEffect(() => {
    setBreadCrumb({ title: t("My Profile"), values: [profileBreadCrumb(t), profileEditBreadCrumb(params.id, t)] });
  }, []);
  return {
    user,
    loading,
    errors,
    onSubmit,
  };
};
