import { optimaAgentPayerOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormAutocomplete, FormPhoneInput, FormTextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import {
  CreateEligibilityRequestFormSchema,
  ICreateEligibilityRequestFormValues,
  createEligibilityRequestFormDefaultValues,
} from "./createEligibilityRequestFormSchema";
import { useCreateEligibilityRequestFormStyles } from "./useCreateEligibilityRequestFormStyles";

export const OptimaCreateEligibilityRequestFormApi: { submit: () => void; isSubmitting: boolean } = {
  submit: () => {},
  isSubmitting: false,
};
export const CreateEligibilityRequestForm: FC<{
  isSubmitting?: boolean;
  onSubmit: (data: ICreateEligibilityRequestFormValues) => void;
}> = props => {
  const { isSubmitting, onSubmit } = props;
  const { t } = useTranslation("optima");
  const { classes } = useCreateEligibilityRequestFormStyles();
  const formMethods = useCustomForm<ICreateEligibilityRequestFormValues>({
    schema: CreateEligibilityRequestFormSchema,
    defaultValues: createEligibilityRequestFormDefaultValues,
    mode: "onChange",
  });
  useEffect(() => {
    OptimaCreateEligibilityRequestFormApi.submit = formMethods.handleSubmit(onSubmit);
  }, []);
  useEffect(() => {
    OptimaCreateEligibilityRequestFormApi.isSubmitting = !!isSubmitting;
  }, [isSubmitting]);

  return (
    <FormProvider {...formMethods}>
      <Box className={classes.root}>
        <FormTextField name={"firstName"} placeholder={`${t("First Name")}`} label={t("First Name")} />
        <FormTextField name={"lastName"} placeholder={`${t("Last Name")}`} label={t("Last Name")} />
        <FormTextField name={"nationalId"} placeholder={`${t("National ID")}`} label={t("National ID")} />
        <FormPhoneInput name={"mobile"} placeholder={`${t("mobile")}`} label={t("Mobile")} />
        <FormAutocomplete name={"payer"} label={t("Insurance")} options={optimaAgentPayerOptions} />
      </Box>
    </FormProvider>
  );
};
