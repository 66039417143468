import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceHealthPackagesAutocompleteQuery } from "./gql";
import { IMarketplaceHealthPackageAutocomplete } from "./schema";

export const createMarketplaceHealthPackageAutocompleteOption = (healthPackage: IMarketplaceHealthPackageAutocomplete) => {
  return createAutocompleteOption(
    {
      id: healthPackage?.id!,
      name: healthPackage?.name!,
      nameAr: healthPackage?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getMarketplaceHealthPackagesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "marketplaceHealthPackages",
    query: useMarketplaceHealthPackagesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name!, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "search",
  });
};
