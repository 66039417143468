import { MedicalFormAnswer, TaskWorkflowType } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, IconButton, MuiRateReviewIcon, useAddToast } from "@toolkit/ui";
import { useGuidedCareTaskUpdateToDoneMutation } from "pages/GuidedCareTasks/gql";
import { FC } from "react";
import { HealthInsuranceSatisfaction } from "shared/modules/patient/components/Assessments/HealthInsuranceSatisfaction";

type GuidedCareTaskActionsMedicalFormProps = {
  medicalFormAnswers: MedicalFormAnswer;
  guidedCareJourneyItemId: string;
  taskWorkflowType: TaskWorkflowType;
  assigneeUserId: string;
  onChange: () => void;
};

export const GuidedCareTaskActionsMedicalForm: FC<GuidedCareTaskActionsMedicalFormProps> = props => {
  const { medicalFormAnswers, guidedCareJourneyItemId, taskWorkflowType, assigneeUserId, onChange } = props;

  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useOpenState();
  const { succeeded, failed } = useAddToast();

  const [updateGuidedCareTask, { loading }] = useGuidedCareTaskUpdateToDoneMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.updateGuidedCareTaskToDone?.entityErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Task updated successfully"));
        handleClose();
        onChange();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmitClick = () => {
    updateGuidedCareTask({
      variables: {
        actionEntityId: medicalFormAnswers?.medicalForm?.id!,
        guidedCareJourneyItemId,
        taskWorkflowType,
        assigneeUserId,
      },
    });
  };

  return (
    <>
      <IconButton size={"small"} color={"success"} disabled={loading} onClick={handleOpen}>
        <MuiRateReviewIcon />
      </IconButton>

      {open && (
        <HealthInsuranceSatisfaction
          isOpen={open}
          label={medicalFormAnswers?.medicalForm?.name!}
          date={medicalFormAnswers?.medicalForm?.updateDate || medicalFormAnswers?.medicalForm?.createdDate}
          questionAnswers={medicalFormAnswers?.questionAnswers!}
          classificationName={medicalFormAnswers?.classificationName!}
          numberOfQuestions={medicalFormAnswers?.questionAnswers?.length!}
          onToggle={handleClose}
          footerComponent={
            loading ? (
              <CircularProgress />
            ) : (
              <Button disabled={loading} onClick={handleSubmitClick}>
                {t("Submit Review")}
              </Button>
            )
          }
        />
      )}
    </>
  );
};
