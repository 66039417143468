/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { OptimaAction, OptimaEdit } from "@/schema/types";
import { priorityOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Typography } from "@toolkit/ui";
import { FC } from "react";
import { OptimaEditActionsModalApi } from "../../modals/OptimaEditActions/OptimaEditActionsModal";
import { OptimaEditDetailsModalApi } from "../../modals/OptimaEditDetails/OptimaEditDetailsModal";
import { getOptimaEditPropertiesByPriority } from "../../others";
import { useOptimaEditCardStyle } from "./useOptimaEditCardStyle";

type OptimaDiagnosisEditCardProps = {
  display?: string;
  diagnosisCode: string;
  edit?: OptimaEdit | null;
};

export const OptimaDiagnosisEditCard: FC<OptimaDiagnosisEditCardProps> = props => {
  const { display, edit, diagnosisCode = "" } = props;

  const { t } = useTranslation("provider");

  const { classes, theme } = useOptimaEditCardStyle();

  const optimaEditProperties = getOptimaEditPropertiesByPriority(edit?.priority!, theme);

  const onFixClick = () => {
    OptimaEditActionsModalApi.open({ actions: (edit?.actions || []) as OptimaAction[] });
  };

  const onSeeEvidenceClick = () => {
    OptimaEditDetailsModalApi.open({ display: display!, edit: edit as OptimaEdit });
  };

  return (
    <Card className={classes.root}>
      <Box className={classes.wrapper} sx={{ background: optimaEditProperties?.color }}>
        {optimaEditProperties?.icon}

        <Box className={classes.infoWrapper}>
          <Typography className={classes.category}>
            {edit?.category} - {edit?.subCategory} ({priorityOptionsMap[edit?.priority!]?.label})
          </Typography>

          <Typography className={classes.message}>
            <span dangerouslySetInnerHTML={{ __html: edit?.message?.replaceAll("<<code>>", `<b> ${diagnosisCode} </b>`) || "" }}></span>
          </Typography>

          <Box className={classes.buttonsWrapper}>
            <Button className={classes.fixButton} onClick={onFixClick}>
              {t("Actions")}
            </Button>

            <Button variant='outlined' className={classes.seeEvidenceButton} onClick={onSeeEvidenceClick}>
              {t("See Evidence")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
