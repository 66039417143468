import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { PermissionEnum } from "@/schema/types";
import { StaffIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { hasPermission } from "@toolkit/core";
import { GuidedCareJourneyListContainer } from "../containers";
import { guidedCareJourneysPaths, guidedCareJourneysRoute } from "./GuidedCareJourneysPaths";

export const guidedCareJourneysRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "guided-care-journeys-routes",
    text: i18n.t("Guided Care Patients", { ns: "provider" }),
    route: guidedCareJourneysRoute,
    icon: <StaffIcon />,
    hidden: !hasPermission(PermissionEnum.ManageGuidedCarePatients),
    isProhibited: !hasPermission(PermissionEnum.ManageGuidedCarePatients),
    subItems: [
      {
        id: "guided-care-journey-list-route",
        route: guidedCareJourneysPaths.list.route,
        fullPath: guidedCareJourneysPaths.list.fullPath,
        element: <GuidedCareJourneyListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
