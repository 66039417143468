import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { DeleteDocsButtons } from "pages/AIAgents/components/DeleteDocs";
import { AgentDocsQueryNode } from "pages/AIAgents/types";
import { convertBytes } from "pages/AIAgents/utils";
import { useMemo } from "react";

export type CHatFlowDocsColumnTypes = CustomTableColumnProps<AgentDocsQueryNode>;

export const useChatFlowDocsColumns = (): CHatFlowDocsColumnTypes[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "name",
        accessor: "fileName",
        header: t("Name"),
      },
      {
        key: "description",
        accessor: "fileName",
        header: t("Description"),
      },
      {
        key: "size",
        accessor: props => {
          return props?.fileSize ? `(${convertBytes(props?.fileSize)})` : "-";
        },
        header: t("Size"),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: "fileType",
      },

      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ id, fileName }) => <DeleteDocsButtons docId={id} fileName={fileName} />,
      },
    ];
  }, [t]);
};
