/* eslint-disable sonarjs/cognitive-complexity */
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { ExtractNodeType } from "@toolkit/apollo";
import { OptimaPriorAuthorizationActivitiesQuery } from "pages/OptimaPriorRequests/gql";
import { useSiteSettingsGetQuery } from "shared/gql/queries";
import { toTitleCase } from "@toolkit/core";
export const usePriorAuthorizationActivitiesListContainerColumns = (): CustomTableColumnProps<
  ExtractNodeType<OptimaPriorAuthorizationActivitiesQuery>
>[] => {
  const { t } = useTranslation("domains");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";
  const theme = useTheme();
  const getColor = (color: "error" | "warning" | "success") => theme.palette?.[color]?.main;

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code, codeLookup }) => <TruncateTypography text={toTitleCase(codeLookup?.name || code || "-")} />,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type, typeLookup }) => typeLookup?.name || type || "-",
      },
      {
        key: "quantity",
        header: t("Quantity"),
        accessor: "quantity",
      },
      {
        key: "netAmount",
        header: t("Net Amount"),
        accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
      },
      {
        key: "paymentAmount",
        header: t("Payment Amount"),
        accessor: ({ paymentAmount }) => (paymentAmount ? paymentAmount + " " + defaultCurrency : "-"),
      },
      {
        key: "patientShare",
        header: t("Patient share"),
        accessor: ({ patientShare }) => (patientShare ? patientShare + " " + defaultCurrency : "-"),
      },
      {
        key: "activityStatus",
        header: t("status"),
        accessor: ({ denialCode }) =>
          denialCode ? (
            <Typography color={getColor("error")}>{t("Rejected")}</Typography>
          ) : (
            <Typography color={getColor("success")}>{t("Approved")}</Typography>
          ),
      },
      {
        key: "isClaimed",
        header: "Claimed?",
        accessor: ({ claimed, denialCode }) => (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {(denialCode ? "-" : false) ||
              (claimed ? (
                <Typography color={getColor("success")}>{t("Yes")}</Typography>
              ) : (
                <Typography color={getColor("warning")}>{"No"}</Typography>
              ))}
          </Box>
        ),
      },
      {
        key: "denialCode",
        header: t("Denial Code"),
        accessor: ({ denialCode, denialLookup }) => <TruncateTypography text={denialLookup?.name || denialCode || "-"} />,
      },
    ];
  }, [t]);
};
