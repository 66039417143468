import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ProductInformationForm } from "../ProductFromInformation";
import { ProductUpsertFormSchema, productUpsertFormSchemaDefaultValues, IProductUpsertFormValues } from "./ProductUpsertFormSchema";
import { convertProductToFormValues } from "../../utils";
import { ProductData } from "../../types";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { ProductFormReturnInstructions } from "../ProductFormReturnInstructions/ProductFormReturnInstructions";
import { ProductFormDescriptions } from "../ProductFormDescriptions/ProductFormDescriptions";
import { ProductFormHowToUse } from "../ProductFormHowToUse/ProductFormHowToUse";

type ProductUpsertFormProps = {
  product?: ProductData;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IProductUpsertFormValues) => void;
};

export const ProductUpsertForm: FC<ProductUpsertFormProps> = props => {
  const { product, isLoading, isSubmitting, submitButtonLabel, onChange } = props;

  const form = useCustomForm<IProductUpsertFormValues>({
    schema: ProductUpsertFormSchema,
    defaultValues: productUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    setValues,
    formState: { errors },
    watch,
  } = form;

  const { t } = useTranslation("provider");

  const isButtonDisabled = isLoading || isSubmitting;
  const { accessToken } = useOidcUserProfile();
  const isReturnable = watch("returnable");

  const onSubmit = (values: IProductUpsertFormValues) => {
    onChange(values);
  };

  useEffect(() => {
    if (product) {
      const _product = convertProductToFormValues(product!);

      setValues(_product);
    }
  }, [product, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Product Information")} loading={isLoading} doYouHaveData>
                <ProductInformationForm />
              </FormCard>
            </Grid>
            {isReturnable && (
              <Grid item xs={12}>
                <FormCard title={t("Return Instructions")} loading={isLoading} doYouHaveData>
                  <ProductFormReturnInstructions />
                </FormCard>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormCard title={t("Descriptions")} loading={isLoading} doYouHaveData>
                <ProductFormDescriptions product={product} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Guidelines")} loading={isLoading} doYouHaveData>
                <ProductFormHowToUse product={product} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Main Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"mainImageUrl"}
                      validationRation
                      error={!!errors?.mainImageUrl}
                      helperText={errors?.mainImageUrl?.message}
                      token={accessToken!}
                      defaultValue={product?.mainImageUrl}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Images")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      hasMultipleImages={true}
                      canDeleteImage={true}
                      control={form.control}
                      name={"subImages"}
                      token={accessToken!}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
