import { i18n } from "@toolkit/i18n";
import { OptimaAgentPayer } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const optimaAgentPayerOptionsMap: IEnumToOptionsMap<OptimaAgentPayer> = {
  [OptimaAgentPayer.Aafiyatpa]: {
    key: OptimaAgentPayer.Aafiyatpa,
    get label() {
      return i18n.t("Aafiyatpa", { ns: "domains" });
    },
    value: OptimaAgentPayer.Aafiyatpa,
  },
  [OptimaAgentPayer.Adnic]: {
    key: OptimaAgentPayer.Adnic,
    get label() {
      return i18n.t("Adnic", { ns: "domains" });
    },
    value: OptimaAgentPayer.Adnic,
  },
  [OptimaAgentPayer.Almadallah]: {
    key: OptimaAgentPayer.Almadallah,
    get label() {
      return i18n.t("Almadallah", { ns: "domains" });
    },
    value: OptimaAgentPayer.Almadallah,
  },
  [OptimaAgentPayer.Axa]: {
    key: OptimaAgentPayer.Axa,
    get label() {
      return i18n.t("Axa", { ns: "domains" });
    },
    value: OptimaAgentPayer.Axa,
  },
  [OptimaAgentPayer.Daman]: {
    key: OptimaAgentPayer.Daman,
    get label() {
      return i18n.t("Daman", { ns: "domains" });
    },
    value: OptimaAgentPayer.Daman,
  },
  [OptimaAgentPayer.Inaya]: {
    key: OptimaAgentPayer.Inaya,
    get label() {
      return i18n.t("Inaya", { ns: "domains" });
    },
    value: OptimaAgentPayer.Inaya,
  },
  [OptimaAgentPayer.Mednet]: {
    key: OptimaAgentPayer.Mednet,
    get label() {
      return i18n.t("Mednet", { ns: "domains" });
    },
    value: OptimaAgentPayer.Mednet,
  },
  [OptimaAgentPayer.Msh]: {
    key: OptimaAgentPayer.Msh,
    get label() {
      return i18n.t("Msh", { ns: "domains" });
    },
    value: OptimaAgentPayer.Msh,
  },
  [OptimaAgentPayer.Nas]: {
    key: OptimaAgentPayer.Nas,
    get label() {
      return i18n.t("Nas", { ns: "domains" });
    },
    value: OptimaAgentPayer.Nas,
  },
  [OptimaAgentPayer.Nextcare]: {
    key: OptimaAgentPayer.Nextcare,
    get label() {
      return i18n.t("Nextcare", { ns: "domains" });
    },
    value: OptimaAgentPayer.Nextcare,
  },
  [OptimaAgentPayer.Ngi]: {
    key: OptimaAgentPayer.Ngi,
    get label() {
      return i18n.t("Ngi", { ns: "domains" });
    },
    value: OptimaAgentPayer.Ngi,
  },
  [OptimaAgentPayer.Saico]: {
    key: OptimaAgentPayer.Saico,
    get label() {
      return i18n.t("Saico", { ns: "domains" });
    },
    value: OptimaAgentPayer.Saico,
  },
  [OptimaAgentPayer.Sukoon]: {
    key: OptimaAgentPayer.Sukoon,
    get label() {
      return i18n.t("Sukoon", { ns: "domains" });
    },
    value: OptimaAgentPayer.Sukoon,
  },
};

export const optimaAgentPayerOptions = Object.values(optimaAgentPayerOptionsMap);
