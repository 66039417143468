import { Box, CircularProgress, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { usePatientEnrollmentRequestsQuery } from "@/shared/modules/patient/gql";
import { getApolloContextFormToken } from "@/shared/utils";
import { PatientEnrollmentRequestStatus } from "@/schema/types";
import { usePatientEnrollmentRequestChanged } from "@health/sse";

export const PendingPatientEnrollmentRequests = () => {
  const theme = useTheme();
  const { t } = useTranslation("provider");

  const { patientId, patientName, token } = useProfileContext();

  const { data, refetch } = usePatientEnrollmentRequestsQuery({
    context: getApolloContextFormToken(token),
    variables: {
      first: 10,
      filter: {
        patientId: patientId,
        status: PatientEnrollmentRequestStatus.InProgress,
      },
    },
  });

  const hasPendingJourney = data?.patientEnrollmentRequests?.edges?.some(
    item => item?.node?.status === PatientEnrollmentRequestStatus.InProgress
  );

  usePatientEnrollmentRequestChanged(({ data }) => {
    if (data?.patientId === patientId && data?.status === PatientEnrollmentRequestStatus.InProgress) refetch();
  });

  return (
    <>
      {hasPendingJourney && (
        <Box
          sx={{
            zIndex: 4,
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            paddingTop: 40,
            justifyContent: "center",
            backdropFilter: "blur(8px)",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: theme.palette.common.black,
              opacity: 0.4,
            },
          }}
        >
          <Box
            sx={{
              height: "fit-content",
              backgroundColor: "white",
              borderRadius: 3,
              width: "50%",
              boxShadow: theme.mixins.shadows.main,
              zIndex: theme.zIndex.modal,
            }}
          >
            <Box
              p={2}
              sx={{
                height: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={60} />
              <Typography
                margin={1}
                textAlign='center'
                fontSize={theme.mixins.fonts.fontSize.xl}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
              >
                {t("Please wait while we create")} {patientName || t("Patient")} {t("care journey")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
