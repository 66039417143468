import { FC, PropsWithChildren } from "react";
import { FormActions, PageWrapper } from "@toolkit/ui";

type ConditionalDiscountPageWrapper = PropsWithChildren<{
  isLoading: boolean;
  isButtonDisabled: boolean;
  submitButtonLabel: string;
  shouldWrapChildren: boolean;
}>;

export const ConditionalDiscountPageWrapper: FC<ConditionalDiscountPageWrapper> = props => {
  const { isLoading, isButtonDisabled, submitButtonLabel, shouldWrapChildren, children } = props;

  return (
    <>
      {shouldWrapChildren ? (
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          {children}
        </PageWrapper>
      ) : (
        children
      )}
    </>
  );
};
