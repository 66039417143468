import { TransactionPriorRequestFilterInput, TransactionSortDirection, TransactionSortField } from "@/schema/types";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { ActiveFiltersValues } from "components/ActiveFiltersValues/ActiveFiltersValues";
import { RequestListKpis } from "pages/OptimaPriorRequests/components/RequestListKpis/RequestListKpis";
import { useOptimaPriorRequestsWithKpiQuery } from "pages/OptimaPriorRequests/gql";
import { FC, useState } from "react";
import { GridProvider } from "shared/components";
import { useOptimaPriorRequestListContainerColumns } from "./useOptimaPriorRequestListContainerColumns";
import { useOptimaPriorRequestListKpiController } from "./useOptimaPriorRequestListKpiController";

export type IOptimaPriorRequestListContainerEvent = {
  type: "SHOW_AUTHORIZATION_CLAIMS_ACTIVITIES";
  payload: {
    authorizationID: string;
  };
};

type OptimaPriorRequestListContainerProps = {
  isProviderAdmin?: boolean;
};

export const OptimaPriorRequestListContainer: FC<OptimaPriorRequestListContainerProps> = props => {
  const { isProviderAdmin } = props;
  const [activeFilters, setActiveFilters] = useState<TransactionPriorRequestFilterInput | undefined>(undefined);

  const handleOnFiltersChange = (value: TransactionPriorRequestFilterInput | undefined) => {
    setActiveFilters(value);
  };
  const { selectedKpiMode, totalCounts, hasFilter, handleKpiModeChange } = useOptimaPriorRequestListKpiController({ activeFilters });

  const { refetch } = useGridContext();
  useOnOptimaHaveNewNotification(() => {
    refetch({ first: 10 });
  });
  return (
    <GridProvider
      gridName={"optimaPriorRequestList"}
      query={useOptimaPriorRequestsWithKpiQuery}
      columns={useOptimaPriorRequestListContainerColumns({
        isProviderAdmin,
        selectedKpiMode: selectedKpiMode?.type,
      })}
      hasTableSetting
      variables={{
        ...selectedKpiMode.variables,
        filter: activeFilters || {},
        after: null,
        last: null,
        before: null,
        sortBy: {
          field: TransactionSortField.TransactionDate,
          direction: TransactionSortDirection.Desc,
        },
      }}
      dataAccessor={selectedKpiMode.accessor}
      hideFilterInput
      isReadOnlyFilters
    >
      <PageWrapper
        contentContainerSX={{
          pt: 0,
        }}
        filters={hasFilter ? <FilterGrid /> : <Box height={60}></Box>}
        actions={<TableSettingComponent />}
      >
        <ActiveFiltersValues<TransactionPriorRequestFilterInput> onFiltersChange={handleOnFiltersChange} />
        <RequestListKpis selectedKpiMode={selectedKpiMode} totalCounts={totalCounts} handleKpiModeChange={handleKpiModeChange} />
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
