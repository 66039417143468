import { Box, Button, IconButton, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { CallInstance } from "./types";
import { useTranslation } from "@toolkit/i18n";
import { useCallStyles } from "./Call.styles";
import { useEffect, useRef } from "react";
import { useNetwork } from "@toolkit/core";
import { useCallHook } from "./useCallHook";

type TimeoutScreenProps = {
  call: CallInstance;
  onClose?: () => void;
};

export const TimeoutScreen = ({ call, onClose }: TimeoutScreenProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useCallStyles();
  const { handleJoin, isJoining } = useCallHook({ call });
  const isOnline = useNetwork();

  const joinCallRef = useRef(handleJoin);
  joinCallRef.current = handleJoin;

  useEffect(() => {
    // retry after internet is reachable
    const timeoutId = setTimeout(() => {
      if (isOnline) {
        joinCallRef.current();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [isOnline]);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {onClose && (
          <Box className={classes.header}>
            <IconButton color='inherit' onClick={onClose}>
              <MuiArrowBackIcon />
            </IconButton>
          </Box>
        )}

        <Box className={classes.body}>
          <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
            <Typography textAlign='center' fontSize={theme.mixins.fonts.fontSize.md}>
              {t("Internet Connection has been lost")}
            </Typography>

            <Typography textAlign='center' variant='body1' color={theme.palette.gray[400]}>
              {t("Trying to reconnect...")}
            </Typography>

            <Box>
              <Button disabled={!call.callId || isJoining} onClick={handleJoin}>
                {isJoining ? t("Connecting ...") : t("Retry")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
