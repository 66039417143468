import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import { useChatGroupsHooks } from "./ChatGroups.hooks";
import { ChatGroupsEmpty } from "./ChatGroupsEmpty";
import { ChatGroupsItem } from "./ChatGroupsItem";
import { useChatGroupsStyles } from "./ChatGroups.styles";
import { ChatGroupsItemSkeleton } from "./ChatGroupsItemSkeleton";
import { FC } from "react";
import { ChatGroup } from "../../schema/types";

type ChatGroupsProps = {
  selected?: ChatGroup;
  onSelect?: (value: ChatGroup) => void;
};

export const ChatGroups: FC<ChatGroupsProps> = ({ selected, onSelect }) => {
  const { data, loading, hasMore, hasError, isEmpty, keyExtractor, handleFetchMore, handleRetry } = useChatGroupsHooks();
  const { classes } = useChatGroupsStyles();
  if (isEmpty) {
    return <ChatGroupsEmpty isLoading={loading} hasError={hasError} onRetry={handleRetry} />;
  }

  return (
    <Box id='chat-groups-component' className={classes.root}>
      <CustomInfiniteScroll
        dataLength={data.length}
        onFetchMore={handleFetchMore}
        onRefresh={handleRetry}
        hasMore={hasMore}
        scrollableTarget='chat-groups-component'
        loader={<ChatGroupsItemSkeleton />}
      >
        {data.map(item => (
          <ChatGroupsItem key={keyExtractor(item)} item={item} isSelected={selected?.id === item.id} onClick={onSelect} />
        ))}
      </CustomInfiniteScroll>
    </Box>
  );
};
