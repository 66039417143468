import { useState, useCallback, useMemo } from "react";
import { useOptimaClaimActivitiesColumns } from "./useOptimaClaimActivitiesColumns";
import { ExtractNodeType } from "@toolkit/apollo";
import { OptimaClaimActivitiesQuery, useOptimaClaimActivitiesQuery } from "pages/OptimaClaims/gql";

type InputState = {
  first: number | null;
  after: string | null;
  before: string | null;
  last: number | null;
  filter: Record<"claimId", string>;
};

export const useOptimaClaimActivitiesList = (id?: string) => {
  const columns = useOptimaClaimActivitiesColumns();
  const pageSize = 3;

  const defaultInput: InputState = {
    first: pageSize,
    after: null,
    before: null,
    last: null,
    filter: { claimId: id || "" },
  };

  const [input, setInput] = useState<InputState>(defaultInput);

  const { data: activitiesData, loading: isLoading } = useOptimaClaimActivitiesQuery({
    variables: input,
    fetchPolicy: "no-cache",
  });

  const pageInfo = activitiesData?.optimaClaimActivities?.pageInfo;
  const edges = activitiesData?.optimaClaimActivities?.edges || [];
  const totalCount = activitiesData?.optimaClaimActivities?.totalCount || 0;

  const tableData = useMemo(() => edges.map(e => e?.node) as ExtractNodeType<OptimaClaimActivitiesQuery>[], [edges]);

  const handleGotoNext = useCallback(() => {
    setInput(prev => ({
      ...prev,
      first: pageSize,
      after: pageInfo?.endCursor || null,
      last: null,
      before: null,
    }));
  }, [pageInfo?.endCursor, pageSize]);

  const handleGoToPrevious = useCallback(() => {
    setInput(prev => ({
      ...prev,
      last: pageSize,
      before: pageInfo?.startCursor || null,
      first: null,
      after: null,
    }));
  }, [pageInfo?.startCursor, pageSize]);

  return {
    tableData,
    columns,
    pageSize,
    totalCount,
    isLoading,
    hasNextPage: pageInfo?.hasNextPage || false,
    hasPreviousPage: pageInfo?.hasPreviousPage || false,
    handleGotoNext,
    handleGoToPrevious,
  };
};
