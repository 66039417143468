import { OptimaPriorRequestsWithKpiQueryVariables } from "./gql";

export interface IOptimaRequestKpi {
  variables: OptimaPriorRequestsWithKpiQueryVariables;
  accessor?: "optimaPriorRequests" | "optimaPriorRequestsRejectedAuth" | "optimaPriorRequestsWithoutClaim";
  type: requestsKpiMode;
}

export type requestsKpiMode = "all" | "rejected" | "withoutClaim";

export const optimaRequestKpi: Record<requestsKpiMode, IOptimaRequestKpi> = {
  all: {
    type: "all",
    variables: {
      fetchAll: true,
      fetchRejectedAuth: false,
      fetchWithoutClaim: false,
    },
    accessor: "optimaPriorRequests",
  },
  rejected: {
    type: "rejected",
    variables: {
      fetchAll: false,
      fetchRejectedAuth: true,
      fetchWithoutClaim: false,
    },
    accessor: "optimaPriorRequestsRejectedAuth",
  },
  withoutClaim: {
    type: "withoutClaim",
    variables: {
      fetchAll: false,
      fetchRejectedAuth: false,
      fetchWithoutClaim: true,
    },
    accessor: "optimaPriorRequestsWithoutClaim",
  },
} as const;
