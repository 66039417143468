import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useOptimaVisitBadgeGroupStyles } from "./useOptimaVisitBadgeGroupStyles";
import { useTranslation } from "@toolkit/i18n";

interface OptimaVisitBadgeGroupProps {
  passedItemsCount: number;
  itemsNeedReviewCount: number;
}

export const OptimaVisitBadgeGroup: React.FC<OptimaVisitBadgeGroupProps> = ({ passedItemsCount, itemsNeedReviewCount }) => {
  const { classes } = useOptimaVisitBadgeGroupStyles();
  const { t } = useTranslation("domains");

  return (
    <Box className={classes.badgesWrapper}>
      <Box className={classes.badgeWrapper}>
        <Typography className={classes.badge} sx={{ backgroundColor: "success.main" }}>
          {passedItemsCount.toFixed(0)}
        </Typography>
        <Typography className={classes.badgeText}>{t("Passed")}</Typography>
      </Box>
      <Box className={classes.badgeWrapper}>
        <Typography className={classes.badge} sx={{ backgroundColor: "error.main" }}>
          {itemsNeedReviewCount.toFixed(0)}
        </Typography>
        <Typography className={classes.badgeText}>{t("Items Need Review")}</Typography>
      </Box>
    </Box>
  );
};
