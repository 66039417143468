import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramEvaluateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EvaluateProgramRiskFactorTemplateInput>;
}>;


export type GuidedCareProgramEvaluateMutation = { __typename?: 'Mutation', evaluateGuidedCareProgramRiskFactorTemplate?: { __typename?: 'GuidedCareHealthProgramRiskFactorTemplateEvaluation', level?: string | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, allowDoctorDirectEnrollment?: boolean | null } | null> | null } | null };


export const GuidedCareProgramEvaluateDocument = gql`
    mutation GuidedCareProgramEvaluate($input: EvaluateProgramRiskFactorTemplateInput) {
  evaluateGuidedCareProgramRiskFactorTemplate(input: $input) {
    level
    suggestedGuidedPrograms {
      id
      name
      allowDoctorDirectEnrollment
    }
  }
}
    `;
export type GuidedCareProgramEvaluateMutationFn = Apollo.MutationFunction<GuidedCareProgramEvaluateMutation, GuidedCareProgramEvaluateMutationVariables>;

/**
 * __useGuidedCareProgramEvaluateMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramEvaluateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramEvaluateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramEvaluateMutation, { data, loading, error }] = useGuidedCareProgramEvaluateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareProgramEvaluateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramEvaluateMutation, GuidedCareProgramEvaluateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramEvaluateMutation, GuidedCareProgramEvaluateMutationVariables>(GuidedCareProgramEvaluateDocument, options);
      }
export type GuidedCareProgramEvaluateMutationHookResult = ReturnType<typeof useGuidedCareProgramEvaluateMutation>;
export type GuidedCareProgramEvaluateMutationResult = Apollo.MutationResult<GuidedCareProgramEvaluateMutation>;
export type GuidedCareProgramEvaluateMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramEvaluateMutation, GuidedCareProgramEvaluateMutationVariables>;