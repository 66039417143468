import { ApolloError, makeVar } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  useVisitCallEndMutation,
  useVisitCallRejoinMutation,
  useVisitNewCallStartMutation,
  VisitCallRejoinMutation,
  VisitNewCallStartMutation,
} from "../../gql/mutations";
import { Visit, VisitStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { CallInstance } from "../ZoomVideo";

export const isRejoinRequested = makeVar<boolean>(false);

export const useVisitRejoinCall = (call: CallInstance, visit: Visit | undefined | null) => {
  const { id: visitId, status: visitStatus } = visit ?? {};
  const { failed } = useAddToast();
  const { t } = useTranslation();
  const [rejoinCallMutation] = useVisitCallRejoinMutation();
  const [visitNewCallStart] = useVisitNewCallStartMutation();
  const [visitCallEndMutation, { loading: visitCallEndLoading }] = useVisitCallEndMutation();
  const [loading, setLoading] = useState(false);
  const { resetCallState } = call;

  const isCallRejoinable =
    visit?.status && [VisitStatus.CallInProgress, VisitStatus.CallTimedOut, VisitStatus.CallRejoinable].includes(visit.status);

  const rejoinCall = useCallback(async () => {
    if (!visitId || loading) {
      return;
    }

    try {
      setLoading(true);

      const onError = ({ graphQLErrors }: ApolloError) => failed(formatGraphQLError(graphQLErrors));
      const onCompleted = (request: VisitCallRejoinMutation["visitCallRejoin"] | VisitNewCallStartMutation["visitNewCallStart"]) => {
        const visitErrors = request?.visitErrors;
        if (visitErrors?.length) {
          failed(t("Failed to rejoin the call. Please retry."));
        }
      };

      switch (visitStatus) {
        case VisitStatus.CallTimedOut: {
          await rejoinCallMutation({
            variables: { visitId },
            onCompleted: request => onCompleted(request.visitCallRejoin),
            onError,
          });
          break;
        }
        case VisitStatus.CallRejoinable: {
          await visitNewCallStart({
            variables: { visitId },
            onCompleted: request => onCompleted(request.visitNewCallStart),
            onError,
          });
          break;
        }
        case VisitStatus.CallInProgress: {
          resetCallState?.();
        }
      }
    } finally {
      setLoading(false);
    }
  }, [failed, isCallRejoinable, loading, rejoinCallMutation, t, visitId, visitNewCallStart, visitStatus, resetCallState]);

  const handleVisitCallEnd = useCallback(async () => {
    if (visitId && visitStatus === VisitStatus.CallInProgress) {
      try {
        await visitCallEndMutation({ variables: { visitId } });
      } catch {
        // ignore
      }
    }
  }, [visitCallEndMutation, visitId, visitStatus]);

  return {
    handleRejoinCall: rejoinCall,
    handleVisitCallEnd,
    isCallRejoinable,
    visitCallEndLoading,
    loading,
  };
};
