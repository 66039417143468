import { OptimaValidationRequest } from "@/schema/types";
import { Box, Divider } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { useOptimaVisitValidationActions } from "pages/OptimaVisitValidationRequest/hooks/useOptimaVisitValidationResultActions";
import { FC } from "react";
import { OptimaContributingFactorsModal } from "../../modals/OptimaContributingFactors/OptimaContributingFactorsModal";
import { OptimaEditActionsModal } from "../../modals/OptimaEditActions/OptimaEditActionsModal";
import { OptimaEditDetailsModal } from "../../modals/OptimaEditDetails/OptimaEditDetailsModal";
import { getOptimaItemsResult } from "../../utils";
import { OptimaEditsList } from "../OptimaEditsList/OptimaEditsList";
import { OptimaPassedItem } from "../OptimaPassedItem/OptimaPassedItem";
import { OptimaPatientProfileCard } from "../OptimaPatientProfileCard/OptimaPatientProfileCard";
import { OptimaValidationResultItem } from "../OptimaValidationResultItem/OptimaValidationResultItem";
import { OptimaVisitBadgeGroup } from "../OptimaVisitBadgeGroup/OptimaVisitBadgeGroup";
import { PossibleSuggestedFixes } from "../PossibleSuggestedFixes/PossibleSuggestedFixes";
import { useOptimaVisitValidationResultDetailsStyles } from "./useOptimaVisitValidationResultDetails";
import { OptimaDiagnosisEditsList } from "../OptimaDiagnosisEditsList/OptimaDiagnosisEditsList";

type OptimaVisitResultDetailsModalProps = {
  optimaValidationRequest: OptimaValidationRequest;
};

export const OptimaVisitValidationResultDetails: FC<OptimaVisitResultDetailsModalProps> = props => {
  const { optimaValidationRequest } = props;
  const { classes } = useOptimaVisitValidationResultDetailsStyles();
  const { needReviewCount, passedCount } = getOptimaItemsResult(optimaValidationRequest?.items);
  const { onLikeClick, onDisLikeClick, isLikeDislikeEnabled, checkEditBeingUpdated, handleToggleCollapse, collapsedItems } =
    useOptimaVisitValidationActions();

  return (
    <>
      <OptimaEditActionsModal />
      <OptimaEditDetailsModal />
      <OptimaContributingFactorsModal />
      <Box className={classes.wrapper}>
        <OptimaPatientProfileCard
          withPaper
          patientId={optimaValidationRequest?.patientId || ""}
          name={optimaValidationRequest?.patientName || ""}
          dob={optimaValidationRequest?.patientDoB || ""}
          gender={optimaValidationRequest?.patientGender || ""}
        />
        <Box className={classes.editsWrapper}>
          <OptimaVisitBadgeGroup itemsNeedReviewCount={needReviewCount} passedItemsCount={passedCount} />
          <Divider sx={{ marginBlock: 1 }} />
          {optimaValidationRequest?.items?.map(item => {
            const isCollapsed = item?.id && collapsedItems.includes(item?.id);
            const isItemPassed =
              isEmpty(item?.edits) && isEmpty(item?.riskFactors) && isEmpty(item?.linkedDiagnoses?.flatMap(d => d?.edits));
            return (
              <OptimaValidationResultItem
                key={item?.id}
                title={`${item?.code} - ${item?.display?.toLowerCase()}`}
                collapsed={!!isCollapsed}
                onToggle={() => handleToggleCollapse(item?.id || "")}
                hideCollapse={isItemPassed}
                content={
                  <>
                    {isItemPassed && <OptimaPassedItem />}
                    {!isEmpty(item?.riskFactors) && (
                      <PossibleSuggestedFixes
                        possibleFixes={item?.riskFactors?.map(d => d?.display).filter((fix): fix is string => !!fix) || []}
                      />
                    )}

                    <OptimaDiagnosisEditsList diagnosis={item?.linkedDiagnoses} />

                    <OptimaEditsList
                      edits={item?.edits}
                      isLikeDislikeEnabled={isLikeDislikeEnabled}
                      onLikeClick={onLikeClick}
                      onDislikeClick={onDisLikeClick}
                      isIdBeingUpdated={checkEditBeingUpdated}
                    />
                  </>
                }
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};
