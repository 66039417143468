import { FC } from "react";
import { Box } from "@toolkit/ui";
import { useKpiListStyles } from "./useKpiListStyles";
import { KPIItem } from "../KpiItem/KPIItem";

export interface KpiItem {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  type: string;
  color: "success" | "error" | "warning" | "info";
  isSelected: boolean;
}

interface KpiListProps {
  kpis: KpiItem[];
  onKpiClick: (type: string) => void;
}

export const KpiList: FC<KpiListProps> = ({ kpis, onKpiClick }) => {
  const { classes } = useKpiListStyles({});

  const handleCardClick = (type: string) => () => onKpiClick(type);

  return (
    <Box className={classes.container}>
      {kpis.map((kpi, index) => (
        <KPIItem
          key={index}
          title={kpi.title}
          subtitle={kpi.subtitle}
          icon={kpi.icon}
          onClick={handleCardClick(kpi.type)}
          color={kpi.color}
          isSelected={kpi.isSelected}
        />
      ))}
    </Box>
  );
};
