import { useTranslation } from "@toolkit/i18n";
import { CustomTable, useGridContext } from "@toolkit/ui";

export const GuidedCareTaskListCustomTable = () => {
  const { t } = useTranslation("provider");

  const { customTableProps } = useGridContext();

  return (
    <CustomTable
      {...customTableProps!}
      TableContainerProps={{
        sx: {
          height: 300,
        },
      }}
      withoutDataMessage={t("No Pending Items")}
      emptyIconHeight={70}
      iconBoxProps={{
        marginBottom: "15px",
        height: "70px",
      }}
    />
  );
};
