import { useEffect } from "react";
import { useGridContext } from "@toolkit/ui";

export type ActiveFiltersValuesProps<T> = {
  onFiltersChange: (filtersValue: T | undefined) => void;
};

export const ActiveFiltersValues = <T,>({ onFiltersChange }: ActiveFiltersValuesProps<T>): null => {
  const { activeFiltersProps } = useGridContext();

  useEffect(() => {
    const filtersValue = activeFiltersProps?.formattedFiltersValues as T | undefined;
    onFiltersChange?.(filtersValue);
  }, [JSON.stringify(activeFiltersProps?.filters)]);

  return null;
};
