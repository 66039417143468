import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { GridProvider } from "shared/components";
import { useOptimaValidationRequestsListContainerColumns } from "./useOptimaValidationRequestsListContainerColumns";
import { FC, useEffect } from "react";
import { optimaValidationRequestsRoutesBreadcrumb } from "../../constants";
import { useTranslation } from "@toolkit/i18n";
import { useGetRcmOptimaValidationRequestsQuery } from "../../gql";
import { useOptimaValidationRequestsListKpiController } from "./useOptimaValidationRequestsListKpiController";
import { RequestListKpis } from "../../components/RequestListKpis/RequestListKpis";
import { ActiveFiltersValues } from "@health/domains";
import { OptimaValidationRequestFilterInput } from "@/schema/types";

export const OptimaValidationRequestsListContainer: FC = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: optimaValidationRequestsRoutesBreadcrumb(t),
    });
  }, []);

  const { selectedKpiMode, hasFilter, handleKpiModeChange, onActiveFiltersChange, activeFilters, totalCounts } =
    useOptimaValidationRequestsListKpiController();

  return (
    <GridProvider
      gridName={"optimaRcmValidationRequests"}
      query={useGetRcmOptimaValidationRequestsQuery}
      columns={useOptimaValidationRequestsListContainerColumns()}
      variables={{
        filter: hasFilter ? activeFilters : selectedKpiMode.filters,
      }}
      hasTableSetting
      hideFilterInput
      isReadOnlyFilters
    >
      <PageWrapper
        contentContainerSX={{
          pt: 0,
        }}
        filters={hasFilter ? <FilterGrid /> : <Box height={60}></Box>}
        actions={<TableSettingComponent />}
      >
        <ActiveFiltersValues<OptimaValidationRequestFilterInput> onFiltersChange={onActiveFiltersChange} />
        <RequestListKpis totalCounts={totalCounts} selectedKpiMode={selectedKpiMode} handleKpiModeChange={handleKpiModeChange} />
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
