import { AppointmentType, Clinician, Doctor, User, VendorUserTypes } from "@/schema/types";
import {
  createBranchAutocompleteOption,
  createDepartmentAutocompleteOption,
  createLanguageAutocompleteOption,
  createSystemCodeAutocompleteOption,
  createVendorAutocompleteOption,
} from "@health/autocompletes";
import {
  appointmentTypeOptionsMap,
  appRoleTypeOptionsMap,
  dayOfWeekOptionsMap,
  doctorSeniorityOptionsMap,
  userGenderOptionsMap,
  vendorUserTypeOptionsMap,
} from "@health/enum-options";
import { createAutocompleteStringOption } from "@toolkit/ui";
import moment from "moment";
import { IUserProviderUpsertForm } from "pages/UsersProviders/forms/UserProviderUpsert/UserProviderUpsertFormSchema";
import { IUserProviderDoctorDetailsForm } from "pages/UsersProviders/forms/UserProviderDoctorDetails/UserProviderDoctorDetailsFormSchema";
import { AppRoleTypes } from "@health/enum-options/dist/schema/types";

export const convertUserProviderToFormValues = (user: User): IUserProviderUpsertForm => {
  return {
    firstName: user?.firstName!,
    lastName: user?.lastName!,
    nationalId: user?.nationalId!,
    mobile: user?.mobile!,
    email: user?.email!,
    password: "••••••••",
    dateOfBirth: user?.dateOfBirth,
    gender: userGenderOptionsMap[user?.gender!],
    appRole: appRoleTypeOptionsMap[user?.appRole!],
    vendorUserType: vendorUserTypeOptionsMap[user?.vendorUserType!],
    isActive: user?.isActive,
    provider: createVendorAutocompleteOption(user?.vendor!),
    branches: user?.branches?.length ? user?.branches?.map(item => createBranchAutocompleteOption(item!)) : [],
    defaultBranch: createBranchAutocompleteOption(user?.defaultBranch!),
    healthLicenseNumber: user?.healthLicenseNumber,
    healthLicenseStartDate: user?.healthLicenseStartDate,
    healthLicenseEndDate: user?.healthLicenseEndDate,
    departments: user?.departments?.map(item => createDepartmentAutocompleteOption(item!)) || undefined,
    doctorInfo: user?.doctor ? convertUserProviderDoctorInformationToFormValues(user?.doctor) : undefined,
  };
};

export const convertUserProviderDoctorInformationToFormValues = (doctor: Doctor): IUserProviderDoctorDetailsForm => {
  const parsedSocialLinks = doctor?.socialLinks ? JSON.parse(doctor?.socialLinks) : [];

  return {
    specializations: doctor?.specializations?.length
      ? doctor?.specializations?.map(item => createSystemCodeAutocompleteOption(item!, true))
      : [],
    languages: doctor?.languages?.length ? doctor?.languages?.map(item => createLanguageAutocompleteOption(item!)) : [],
    seniority: doctorSeniorityOptionsMap[doctor?.seniority!],
    notificationEmail: doctor?.notificationEmail,
    yearsOfExperience: doctor?.yearsOfExperience,
    secondMobileNumber: doctor?.secondMobileNumber,
    socialLinks: Array.isArray(parsedSocialLinks) ? parsedSocialLinks : [],
    bio: doctor?.bio,
    appointmentSlotTimePeriod: doctor?.appointmentSlotTimePeriod
      ? createAutocompleteStringOption(doctor?.appointmentSlotTimePeriod?.toString())
      : undefined,
    appointmentTypes: doctor?.appointmentTypes?.length ? doctor?.appointmentTypes?.map(item => appointmentTypeOptionsMap[item!]) : [],
    onlineVisitPrice: doctor?.onlineVisitPrice,
    onsiteVisitPrice: doctor?.onsiteVisitPrice,
    atHomeVisitPrice: doctor?.atHomeVisitPrice,
    isMobileNumberPublic: doctor?.isMobileNumberPublic,
    isSecondMobileNumberPublic: doctor?.isSecondMobileNumberPublic,
    isNationalIdPublic: doctor?.isNationalIdPublic,
    isLanguagesPublic: doctor?.isLanguagesPublic,
    isYearsOfExperiencePublic: doctor?.isYearsOfExperiencePublic,
    isDateOfBirthPublic: doctor?.isDateOfBirthPublic,
    isSocialLinksPublic: doctor?.isSocialLinksPublic,
    isHealthLicenseNumberPublic: doctor?.isLicenseNumberPublic,
    qualifications: doctor?.qualifications?.length
      ? {
          code: doctor?.qualifications[0]?.code!,
          issuer: doctor?.qualifications[0]?.issuer,
          fromDate: doctor?.qualifications[0]?.fromDate,
          toDate: doctor?.qualifications[0]?.toDate,
        }
      : undefined,
    workingHours: doctor?.workingHours?.length
      ? doctor?.workingHours?.map(item => ({
          day: dayOfWeekOptionsMap[item?.day!]?.label,
          timeRanges: item?.openTimeRanges?.length
            ? item?.openTimeRanges?.map(timeRange => ({
                startTime: moment(timeRange?.openTime, "HH:mm").format(),
                endTime: moment(timeRange?.closeTime, "HH:mm").format(),
              }))
            : [],
        }))
      : [],
  };
};

export const convertUserProviderNationalIdToFormValues = (user: Clinician): Partial<IUserProviderUpsertForm> => {
  return {
    firstName: user?.firstName!,
    lastName: user?.lastName!,
    nationalId: user?.nationalId!,
    mobile: user?.phone!,
    email: user?.email!,
    password: user?.tempPassword!,
    healthLicenseNumber: user?.license,
    healthLicenseStartDate: user?.licenseStart,
    healthLicenseEndDate: user?.licenseEnd,
    appRole: appRoleTypeOptionsMap[AppRoleTypes.User],
  };
};

export const doesVendorUserTypeRequireHealthLicense = (vendorUserType: VendorUserTypes) => {
  const licensedHealthProfessionals = [
    VendorUserTypes.DentalHygienist,
    VendorUserTypes.DiabetesEducator,
    VendorUserTypes.Doctor,
    VendorUserTypes.FitnessCoach,
    VendorUserTypes.Nurse,
    VendorUserTypes.Nutritionist,
    VendorUserTypes.Optometrist,
    VendorUserTypes.Pharmacist,
    VendorUserTypes.PodiatricMedicalAssistant,
    VendorUserTypes.Psychologist,
    VendorUserTypes.SocialWorker,
  ];

  return licensedHealthProfessionals.includes(vendorUserType);
};

export const isAppointmentTypePresent = (
  appointmentTypes: IUserProviderDoctorDetailsForm["appointmentTypes"] | undefined,
  value: AppointmentType
) => {
  if (!appointmentTypes || !appointmentTypes.length) return false;

  return appointmentTypes.some(item => item?.value === value);
};
