import { FC } from "react";
import OptimaVisitResultDetailsModal from "@/pages/Optima/OptimaRcmValidationRequests/components/OptimaVisitResultDetails/OptimaVisitResultDetails";
import { IOptimaValidatedRequestsListNode } from "../../types";

type OptimaValidationResponseDialogProps = {
  requestResponse: IOptimaValidatedRequestsListNode;
};

export const OptimaValidationResponseDialog: FC<OptimaValidationResponseDialogProps> = ({ requestResponse }) => {
  return <OptimaVisitResultDetailsModal optimaValidationRequest={requestResponse} />;
};
