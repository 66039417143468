import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { useCareJourneyStatisticsQuery } from "../../gql";

type JourneyStatisticsProps = {
  healthProgramId: string;
  activeInterventionsValue?: number;
};

export const JourneyStatistics: FC<JourneyStatisticsProps> = props => {
  const { healthProgramId, activeInterventionsValue } = props;

  const { t } = useTranslation("provider");

  const theme = useTheme();

  const { patientId, loading: loadingPatient, token } = useProfileContext();

  const { data, loading } = useCareJourneyStatisticsQuery({
    context: getApolloContextFormToken(token),
    variables: {
      filter: {
        guidedCareHealthProgramId: healthProgramId,
        patientId: patientId,
      },
    },
  });

  const statistics = data?.careJourneyStatistics;

  const activities = data?.careJourneyStatistics?.allActivities
    ? (data?.careJourneyStatistics?.allCompletedActivities! / data?.careJourneyStatistics?.allActivities!) * 100
    : 0;

  return (
    <>
      {!loading && !loadingPatient && (
        <Grid item sx={{ marginInline: "12px" }}>
          <Box display='flex' alignItems='center' gap={3}>
            <CircularProgressWithValue
              label={t("Interventions")}
              value={100}
              valueLabel={`${activeInterventionsValue || 0}`}
              color={theme.palette.error.main}
            />

            <CircularProgressWithValue label={t("Alerts")} value={statistics?.gaps!} color={theme.palette.error.main} />

            <CircularProgressWithValue
              label={t("Activities")}
              value={activities}
              color='#D46A3D'
              rate={{
                value: data?.careJourneyStatistics?.allCompletedActivities!,
                total: data?.careJourneyStatistics?.allActivities!,
              }}
            />

            <CircularProgressWithValue label={t("Adherence")} value={statistics?.adherence!} color='#4FA043' unit={"%"} />
          </Box>
        </Grid>
      )}
    </>
  );
};

const CircularProgressWithValue: FC<{
  label: string;
  color: string;
  value: number;
  valueLabel?: string;
  unit?: string;
  rate?: { value: number; total: number };
}> = ({ label, value, valueLabel, rate, color, unit }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress variant='determinate' value={100} size={50} thickness={6} sx={{ color: theme.palette.gray.light }} />
      <Box top={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress
          size={50}
          thickness={6}
          variant='determinate'
          value={value}
          sx={{
            color: color,
            strokeLinecap: "round",
          }}
        />
        {rate ? (
          <Typography
            fontSize={theme.mixins.fonts.fontSize.xs}
            sx={{
              color: color,
              position: "absolute",
            }}
          >
            <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
              {rate.value || 0}
            </Typography>
            <sub>/{rate.total}</sub>
          </Typography>
        ) : (
          <Typography
            fontSize={theme.mixins.fonts.fontSize.xs}
            sx={{
              color: color,
              position: "absolute",
            }}
          >
            <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
              {valueLabel || value || 0}
            </Typography>
            <sup>{unit}</sup>
          </Typography>
        )}
      </Box>
      <Typography fontSize={theme.mixins.fonts.fontSize.xs} textAlign='center' color={theme.palette.stale.main}>
        {label}
      </Typography>
    </Box>
  );
};
