import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import { PatientHealthHistoryFragmentFragmentDoc } from '../../fragments/__generated__/patientHealthHistory';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientMedicalProfileQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID']['input'];
}>;


export type PatientMedicalProfileQuery = { __typename?: 'Query', patientMedicalProfile?: { __typename?: 'PatientMedicalProfile', id: string, documentSecretKey?: string | null, patient?: { __typename?: 'Patient', id: string } | null, healthDocuments?: { __typename?: 'PatientHealthDocumentCountableConnection', edges: Array<{ __typename?: 'PatientHealthDocumentCountableEdge', node: { __typename?: 'PatientHealthDocument', createdDate: any, description?: string | null, fileLinks: Array<string>, fileSize?: any | null, id: string, name: string, resultDate: any, type: Types.HealthDocumentType } }> } | null, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null, patientHealthHistory?: Array<{ __typename?: 'PatientHealthHistory', id: string, code: string, display?: string | null, healthHistoryType: Types.HealthHistoryType, lastModifiedDate?: any | null } | null> | null } | null };


export const PatientMedicalProfileDocument = gql`
    query PatientMedicalProfile($patientId: ID!) {
  patientMedicalProfile(patientId: $patientId) {
    id
    patient {
      id
    }
    documentSecretKey
    healthDocuments(first: 10) {
      edges {
        node {
          createdDate
          description
          fileLinks
          fileSize
          id
          name
          resultDate
          type
        }
      }
    }
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
    patientHealthHistory {
      ...patientHealthHistoryFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}
${PatientHealthHistoryFragmentFragmentDoc}`;

/**
 * __usePatientMedicalProfileQuery__
 *
 * To run a query within a React component, call `usePatientMedicalProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicalProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicalProfileQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientMedicalProfileQuery(baseOptions: Apollo.QueryHookOptions<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables> & ({ variables: PatientMedicalProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>(PatientMedicalProfileDocument, options);
      }
export function usePatientMedicalProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>(PatientMedicalProfileDocument, options);
        }
export function usePatientMedicalProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>(PatientMedicalProfileDocument, options);
        }
export type PatientMedicalProfileQueryHookResult = ReturnType<typeof usePatientMedicalProfileQuery>;
export type PatientMedicalProfileLazyQueryHookResult = ReturnType<typeof usePatientMedicalProfileLazyQuery>;
export type PatientMedicalProfileSuspenseQueryHookResult = ReturnType<typeof usePatientMedicalProfileSuspenseQuery>;
export type PatientMedicalProfileQueryResult = Apollo.QueryResult<PatientMedicalProfileQuery, PatientMedicalProfileQueryVariables>;