import { VisitDiagnosis } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useCoPilotContext } from "../CoPilot/CoPilotContext";
import { VisitDiagnosesDocument, useAddDiagnosisToVisitMutation, useDeleteVisitDiagnosisMutation, useVisitDiagnosesQuery } from "../gql";
import { IDiagnosisFormValues } from "@/pages/e-visit/Diagnosis/DiagnosisForm/DiagnosisFormSchema";

export const useVisitDiagnosesHooks = (visitId: string, token: string | undefined) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { handleGetTemplates, handleSelectedVisitDiagnosis } = useCoPilotContext();

  const { data: visit, loading } = useVisitDiagnosesQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });

  const [addDiagnosisToVisit, { data, loading: isSubmitting }] = useAddDiagnosisToVisitMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const visitDiagnosisErrors = data?.addDiagnosisToVisit?.visitDiagnosisErrors;
      if (visitDiagnosisErrors?.length === 0) {
        succeeded(t("Diagnose Added Successfully"));
        handleSelectedVisitDiagnosis?.(data?.addDiagnosisToVisit?.visitDiagnosis!);
      } else {
        failed(t("Added Diagnose Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [VisitDiagnosesDocument],
  });

  const handleCreate = (values: IDiagnosisFormValues) => {
    addDiagnosisToVisit({
      variables: {
        input: {
          visitId: visitId!,
          visitDiagnosisType: values?.visitDiagnosisType?.value,
          code: values?.code?.value?.code!,
        },
      },
    });
  };

  const [deleteVisitDiagnosis] = useDeleteVisitDiagnosisMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const visitDiagnosisErrors = data?.deleteVisitDiagnosis?.visitDiagnosisErrors;
      if (visitDiagnosisErrors?.length === 0) {
        succeeded(t("Diagnose Delete Successfully"));
      } else {
        failed(t("Deleted Diagnose Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [VisitDiagnosesDocument],
  });

  const handleDelete = data => {
    deleteVisitDiagnosis({
      variables: {
        deleteVisitDiagnosisId: data?.id!,
      },
    });
  };

  const visitDiagnoses = visit?.visit?.visitDiagnoses as VisitDiagnosis[];
  const riskTemplate = data?.addDiagnosisToVisit?.visitDiagnosis?.riskTemplate;

  useEffect(() => {
    riskTemplate?.code && handleGetTemplates?.(riskTemplate?.code);
    handleSelectedVisitDiagnosis?.(data?.addDiagnosisToVisit?.visitDiagnosis!);
  }, [JSON.stringify(riskTemplate)]);

  return {
    loading,
    visitDiagnoses,
    riskTemplate,
    handleCreate,
    handleDelete,
    handleSelectedVisitDiagnosis,
    isSubmitting,
  };
};
