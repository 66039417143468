import { useOptimaValidationResultItemStyles } from "./useOptimaValidationResultItemStyle";
import { ArrowDown, ArrowUp, Box, Collapse, Divider, IconButton, Typography } from "@toolkit/ui";

interface OptimaValidationResultItemProps {
  title: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  collapsed: boolean;
  onToggle: (isCollapsed: boolean) => void;
  hideCollapse?: boolean;
}

export const OptimaValidationResultItem: React.FC<OptimaValidationResultItemProps> = ({
  title,
  content,
  icon,
  collapsed,
  onToggle,
  hideCollapse = false,
}) => {
  const { classes } = useOptimaValidationResultItemStyles();

  const handleToggle = () => {
    onToggle(!collapsed);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.titleContainer}>
            {icon && <Box mr={1}>{icon}</Box>}
            <Typography variant='body1' fontWeight='medium'>
              {title}
            </Typography>
          </Box>
          {!hideCollapse && content && (
            <IconButton className={classes.collapseIcon} onClick={handleToggle}>
              {collapsed ? <ArrowDown fontSize='small' /> : <ArrowUp fontSize='small' />}
            </IconButton>
          )}
        </Box>

        <Collapse in={!collapsed || hideCollapse}>
          <Box className={classes.contentContainer}>{content}</Box>
        </Collapse>
      </Box>
      <Divider className={classes.divider} />
    </>
  );
};
