import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import {
  optimaPatientsEligibilityScanHistoryPaths,
  optimaPatientsEligibilityScanHistoryRoute,
} from "./optimaPatientsEligibilityScanHistoryPaths";
import RouteItem from "@/shared/components/Root/routeTypes";
import { OptimaPatientsEligibilityRequestsHistoryListContainer } from "../containers";

export const optimaPatientsEligibilityScanHistoryRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}) => {
  return {
    id: "optima-validation-requests-routes",
    text: t("Patient Eligibility Scans", { ns: "provider" }),
    route: optimaPatientsEligibilityScanHistoryRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageValidationRequests),
    subItems: [
      {
        id: "optima-patients-eligibility-scan-history",
        route: optimaPatientsEligibilityScanHistoryPaths.list.route,
        fullPath: optimaPatientsEligibilityScanHistoryPaths.list.fullPath,
        element: <OptimaPatientsEligibilityRequestsHistoryListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
