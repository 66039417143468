import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { usersPaths } from "../constants";

export const useSetUsersBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Users");

  const userVendorListBreadcrumb = useMemo(() => {
    return {
      name: t("Users"),
      route: usersPaths.list.fullPath,
    };
  }, [t]);

  const setUserVendorListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setUserVendorCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [userVendorListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, userVendorListBreadcrumb, t]);

  const setUserVendorUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [userVendorListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, userVendorListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setUserVendorListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setUserVendorCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setUserVendorUpdateContainerBreadcrumb();
    }
  }, [containerType, setUserVendorListContainerBreadcrumb, setUserVendorCreateContainerBreadcrumb, setUserVendorUpdateContainerBreadcrumb]);
};
