import { MarketplaceOrderSortingField, MarketplaceOrderStatus } from "@/schema/types";
import { getOrderStatusColor } from "@health/domains";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, OrderDirection, OrderStatusTypography, TruncateTypography, Typography } from "@toolkit/ui";
import moment from "moment";
import { ProductsModal } from "pages/GlobalOrders/ProductOrders/Components/ProductModal/ProductModal";
import { useMemo } from "react";
import { OrderEventsDrawer } from "../../components/OrderEvents";
import { GetHealthPackageOrderNode } from "../types";
import { AcceptHealthPackageOrderButton } from "./AcceptHealthPackageOrderButton";
import { RejectHealthPackageOrderButton } from "./RejectHealthPackageOrderButton";
import { SetHealthPackageOrderAsConsumedButton } from "./SetHealthPackageOrderAsConsumedButton";

export type PatientsColumnsType = CustomTableColumnProps<GetHealthPackageOrderNode>[];

export const useGetHealthPackageOrdersListColumns = (): PatientsColumnsType => {
  const { t, i18n } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Number"),
        accessor: ({ id }) => {
          return <Typography> {id}</Typography>;
        },
        isHidden: false,
        filter: {
          type: "string",
          name: "numbers",
        },
      },
      {
        key: "customerName",
        header: t("Customer Name"),
        accessor: ({ user }) => <TruncateTypography text={user?.fullName!} />,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.mobile ?? "")}</span>,
      },
      {
        key: "contactEmail",
        header: t("Customer Email"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.email ?? "")}</span>,
      },
      {
        header: t("Price"),
        key: "totalAmount",
        accessor: "totalNetAmount",
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} />,
        filter: getAutocompleteEnumFilter("OrderStatusActive", "status", { multiple: true }),
      },
      {
        key: "Created",
        header: t("Created"),
        accessor: ({ createdDate }) => {
          moment.localeData("ar");
          const createdTz = moment.tz.guess();
          moment.locale(i18n.language);
          return moment(createdDate).tz(createdTz).format("DD MMM YYYY hh:mm a");
        },
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: MarketplaceOrderSortingField.Created,
      },

      {
        key: "orderItems",
        header: t("Order Items"),
        accessor: ({ id }) => <ProductsModal orderId={id} title={t("Items")} />,
      },
      {
        key: "orderEvents",
        header: t("Order Events"),
        accessor: ({ id, orderEvents }) => (
          <OrderEventsDrawer
            orderId={id}
            events={orderEvents.map(event => ({
              date: event?.date,
              id: event?.id,
              status: event?.status,
              firstName: event?.user?.firstName!,
              lastName: event?.user?.lastName!,
              defaultBranchName: event?.user?.defaultBranch?.name! || "-",
            }))}
          />
        ),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ status, id }) => {
          if (status === MarketplaceOrderStatus.New) {
            return (
              <Box sx={{ width: "200px" }}>
                <AcceptHealthPackageOrderButton orderId={id} />
                <RejectHealthPackageOrderButton orderId={id} />
              </Box>
            );
          }
          if (status === MarketplaceOrderStatus.Accepted) {
            return <SetHealthPackageOrderAsConsumedButton orderId={id} />;
          }
          return <></>;
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        accessor: "toDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "fromDate",
        header: t("From Date"),
        accessor: "fromDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
    ] as PatientsColumnsType;
  }, [i18n.language, t]);
};
