/* eslint-disable max-lines */
import {
  actionFieldTypeOptions,
  appointmentFrequencyTypeOptions,
  appointmentPaymentTypeOptions,
  appointmentStatusOption,
  appointmentTypeOptions,
  appRoleTypeOptions,
  approvalStatusTypeOptions,
  articleCategoryTypeOptions,
  assigneeTypeOptions,
  bulkProcessingStatusOptions,
  bulkTemplateCodeOptions,
  callbackRequestStatusOptions,
  careTypeOptions,
  chartTypeOptions,
  codeSystemCodeOptions,
  conditionOperationOptions,
  coverageByTypeOptions,
  coverageOptions,
  customerSupportOrderStatusOptions,
  destinationTypeOptions,
  deliveryMethosTypeOptions,
  doctorSeniorityOptions,
  fieldStandardOptions,
  guidedCareActivityTypeOptions,
  guidedCareJourneyItemSourceOptions,
  guidedCareTypeOptions,
  healthConditionTypeOptions,
  healthDocumentTypeOptions,
  healthProgramBenefitOptionCodeOptions,
  healthProgramOptionTypeOptions,
  healthProgramSubscriptionPeriodOptions,
  healthProgramTypeOptions,
  marketplaceApprovalStatusNoDraftOptions,
  marketplaceApprovalStatusOptions,
  marketplaceDiscountTypeOptions,
  marketplaceGenderOptions,
  marketplaceOrderStatusOptions,
  marketplaceOrderTypeOptions,
  medicalFormCategoryOptions,
  medicalFormTypeOptions,
  medicalMessageCategoryOptions,
  medicalMessageTypeOptions,
  operatorOptions,
  orderStatusActiveOptions,
  orderStatusOptions,
  optimaEncounterTypeOptions,
  patientEligibilityBenefitsEnumOptions,
  patientEnrollmentRequestStatusOptions,
  patientIdTypeOptions,
  payerTpoOptions,
  payerTypeOptions,
  paymentSourceOptions,
  paymentStatusOptions,
  paymentTypeOptions,
  planPeriodOptions,
  priorityOptions,
  providerGuidedCareTeamStatusOptions,
  questionTypeOptions,
  requestedByOptions,
  requestStatusOptions,
  sourceOptions,
  surveyStatusOptions,
  taskDefinitionStatusOptions,
  taskWorkflowTypeOptions,
  teamMemberPositionOptions,
  templateFieldTypeOptions,
  timeOfDayOptions,
  transactionSourceTypeOptions,
  transactionStatusTypeOptions,
  userGenderOptions,
  userStatusOptions,
  vendorTypeOptions,
  vendorUserTypeOptions,
  visitCareTypeOptions,
  visitDiagnosisTypeOptions,
  visitStatusOptions,
  visitTypeOptions,
  yesNoOptions,
  appTypeOptions,
} from "../enums";

export const enumOptionsMapper = {
  ActionFieldType: actionFieldTypeOptions,
  AppointmentFrequencyType: appointmentFrequencyTypeOptions,
  AppointmentPaymentType: appointmentPaymentTypeOptions,
  AppointmentStatus: appointmentStatusOption,
  AppointmentType: appointmentTypeOptions,
  AppRoleType: appRoleTypeOptions,
  AppType: appTypeOptions,
  ApprovalStatusType: approvalStatusTypeOptions,
  ArticleCategoryType: articleCategoryTypeOptions,
  AssigneeType: assigneeTypeOptions,
  BulkProcessingStatus: bulkProcessingStatusOptions,
  BulkTemplateCode: bulkTemplateCodeOptions,
  CallbackRequestStatus: callbackRequestStatusOptions,
  CareType: careTypeOptions,
  ChartType: chartTypeOptions,
  CodeSystemCode: codeSystemCodeOptions,
  ConditionOperation: conditionOperationOptions,
  Coverage: coverageOptions,
  CoverageByType: coverageByTypeOptions,
  CustomerSupportOrderStatus: customerSupportOrderStatusOptions,
  DestinationType: destinationTypeOptions,
  DoctorSeniority: doctorSeniorityOptions,
  DeliveryMethosType: deliveryMethosTypeOptions,
  FieldStandard: fieldStandardOptions,
  GuidedCareActivityType: guidedCareActivityTypeOptions,
  GuidedCareJourneyItemSource: guidedCareJourneyItemSourceOptions,
  GuidedCareType: guidedCareTypeOptions,
  HealthConditionType: healthConditionTypeOptions,
  HealthDocumentType: healthDocumentTypeOptions,
  HealthProgramBenefitOptionCode: healthProgramBenefitOptionCodeOptions,
  HealthProgramOptionType: healthProgramOptionTypeOptions,
  HealthProgramSubscriptionPeriod: healthProgramSubscriptionPeriodOptions,
  HealthProgramType: healthProgramTypeOptions,
  MarketplaceApprovalStatus: marketplaceApprovalStatusOptions,
  MarketplaceApprovalStatusNoDraft: marketplaceApprovalStatusNoDraftOptions,
  MarketplaceDiscountType: marketplaceDiscountTypeOptions,
  MarketplaceGender: marketplaceGenderOptions,
  MarketplaceOrderStatus: marketplaceOrderStatusOptions,
  MarketplaceOrderType: marketplaceOrderTypeOptions,
  MedicalFormCategory: medicalFormCategoryOptions,
  MedicalFormType: medicalFormTypeOptions,
  MedicalMessageCategory: medicalMessageCategoryOptions,
  MedicalMessageType: medicalMessageTypeOptions,
  Operator: operatorOptions,
  OrderStatus: orderStatusOptions,
  OrderStatusActive: orderStatusActiveOptions,
  OptimaEncounterType: optimaEncounterTypeOptions,
  PatientIdTypeOptions: patientIdTypeOptions,
  PatientEligibilityBenefitsEnumOptions: patientEligibilityBenefitsEnumOptions,
  PayerTpo: payerTpoOptions,
  PayerType: payerTypeOptions,
  PaymentSource: paymentSourceOptions,
  PaymentStatus: paymentStatusOptions,
  PaymentType: paymentTypeOptions,
  PlanPeriod: planPeriodOptions,
  Priority: priorityOptions,
  ProviderGuidedCareTeamStatus: providerGuidedCareTeamStatusOptions,
  PatientEnrollmentRequestStatus: patientEnrollmentRequestStatusOptions,
  QuestionType: questionTypeOptions,
  RequestedBy: requestedByOptions,
  RequestStatus: requestStatusOptions,
  Source: sourceOptions,
  SurveyStatus: surveyStatusOptions,
  TaskDefinitionStatus: taskDefinitionStatusOptions,
  TaskWorkflowType: taskWorkflowTypeOptions,
  TeamMemberPosition: teamMemberPositionOptions,
  TemplateFieldType: templateFieldTypeOptions,
  TimeOfDay: timeOfDayOptions,
  TransactionSourceType: transactionSourceTypeOptions,
  TransactionStatusType: transactionStatusTypeOptions,
  UserGender: userGenderOptions,
  UserStatus: userStatusOptions,
  VendorType: vendorTypeOptions,
  VendorUserType: vendorUserTypeOptions,
  VisitCareType: visitCareTypeOptions,
  VisitDiagnosisType: visitDiagnosisTypeOptions,
  VisitStatus: visitStatusOptions,
  VisitType: visitTypeOptions,
  YesNo: yesNoOptions,
};
