import { Box, FormAutocomplete, Grid, mapToAutocompleteOptions, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { GuidedCareHealthProgram, GuidedCareHealthProgramRiskFactorTemplateEvaluation } from "@/schema/types";
import { useGuidedCareProgramEvaluationResultStyle } from "./useGuidedCareProgramEvaluationResultStyle";

type GuidedCareProgramEvaluationResultProps = {
  evaluationResult: GuidedCareHealthProgramRiskFactorTemplateEvaluation;
};

export const GuidedCareProgramEvaluationResult: FC<GuidedCareProgramEvaluationResultProps> = props => {
  const { evaluationResult } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareProgramEvaluationResultStyle();

  const suggestedPrograms = mapToAutocompleteOptions(evaluationResult?.suggestedGuidedPrograms as GuidedCareHealthProgram[], "id", "name");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.title}> {t("Evaluation Result")} </Typography>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.label}>
          {t("Risk Level")}: <Typography className={classes.value}> {evaluationResult?.level || "-"} </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.label}> {t("Suggested Programs")}: </Typography>

        {evaluationResult?.suggestedGuidedPrograms?.length ? (
          <Box className={classes.suggestedProgramWrapper}>
            <FormAutocomplete name={"suggestedProgram"} label={t("Suggested Program")} options={suggestedPrograms} />
          </Box>
        ) : (
          <Typography className={classes.value}> {t("There are no suggested programs")}. </Typography>
        )}
      </Grid>
    </Grid>
  );
};
