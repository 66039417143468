import { isValidPhoneNumber } from "libphonenumber-js";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";
import { RequestPriority } from "@/schema/types";
export const CreateEligibilityRequestFormSchema = z
  .object({
    firstName: z.string().min(3),
    lastName: z.string().min(3),
    nationalId: z.string().min(3),
    mobile: z.string().min(3).max(15),
    mobileCountryCode: z.string().min(1).max(4),
    payer: zodEnumSchema.optimaAgentPayer,
    priority: z.nativeEnum(RequestPriority).default(RequestPriority.High),
  })
  .superRefine((data, ctx) => {
    (data.mobileCountryCode && !data.mobile) ||
      (isValidPhoneNumber(String(data.mobileCountryCode) + String(data.mobile)) &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid mobile number",
          path: ["mobile", "mobileCountryCode"],
        }));
  });

export type ICreateEligibilityRequestFormValues = z.infer<typeof CreateEligibilityRequestFormSchema>;

export const createEligibilityRequestFormDefaultValues: Partial<ICreateEligibilityRequestFormValues> = {
  firstName: undefined,
  lastName: undefined,
  mobile: undefined,
  mobileCountryCode: "+971",
  payer: undefined,
  priority: RequestPriority.High,
  nationalId: undefined,
};
