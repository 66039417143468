import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GuidedCareProgramGetQuery = { __typename?: 'Query', guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, allowDoctorDirectEnrollment?: boolean | null, guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, riskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, type?: Types.TemplateFieldType | null, isMandatory?: boolean | null, defaultValue?: string | null, allowedValues?: Array<string | null> | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null } | null } | null };


export const GuidedCareProgramGetDocument = gql`
    query GuidedCareProgramGet($id: ID!) {
  guidedCareHealthProgram(id: $id) {
    id
    allowDoctorDirectEnrollment
    guidedCareHealthProgramTemplate {
      id
      riskFactorTemplate {
        id
        fields {
          id
          code
          display
          arabicDisplay
          type
          isMandatory
          defaultValue
          allowedValues
          minNormalRangeValue
          maxNormalRangeValue
          unit {
            display
            arabicDisplay
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareProgramGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareProgramGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables> & ({ variables: GuidedCareProgramGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>(GuidedCareProgramGetDocument, options);
      }
export function useGuidedCareProgramGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>(GuidedCareProgramGetDocument, options);
        }
export function useGuidedCareProgramGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>(GuidedCareProgramGetDocument, options);
        }
export type GuidedCareProgramGetQueryHookResult = ReturnType<typeof useGuidedCareProgramGetQuery>;
export type GuidedCareProgramGetLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramGetLazyQuery>;
export type GuidedCareProgramGetSuspenseQueryHookResult = ReturnType<typeof useGuidedCareProgramGetSuspenseQuery>;
export type GuidedCareProgramGetQueryResult = Apollo.QueryResult<GuidedCareProgramGetQuery, GuidedCareProgramGetQueryVariables>;