import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllVendorUsersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
}>;


export type GetAllVendorUsersQuery = { __typename?: 'Query', users?: { __typename?: 'UserCountableConnection', edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', appRole?: Types.AppRoleTypes | null, appType?: Types.AppTypes | null, email?: string | null, firstName?: string | null, lastName?: string | null, isActive: boolean, id: string, isStaff?: boolean | null, isSuperuser?: boolean | null, mobile?: string | null, nationalId?: string | null, vendorUserType?: Types.UserVendorUserType | null, dateJoined: any } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const GetAllVendorUsersDocument = gql`
    query getAllVendorUsers($first: Int, $last: Int, $before: String, $after: String, $filter: UserFilterInput, $sortBy: UserSortingInput) {
  users(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        appRole
        appType
        email
        firstName
        lastName
        isActive
        id
        isStaff
        isSuperuser
        mobile
        nationalId
        vendorUserType
        dateJoined
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetAllVendorUsersQuery__
 *
 * To run a query within a React component, call `useGetAllVendorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVendorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVendorUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetAllVendorUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>(GetAllVendorUsersDocument, options);
      }
export function useGetAllVendorUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>(GetAllVendorUsersDocument, options);
        }
export function useGetAllVendorUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>(GetAllVendorUsersDocument, options);
        }
export type GetAllVendorUsersQueryHookResult = ReturnType<typeof useGetAllVendorUsersQuery>;
export type GetAllVendorUsersLazyQueryHookResult = ReturnType<typeof useGetAllVendorUsersLazyQuery>;
export type GetAllVendorUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllVendorUsersSuspenseQuery>;
export type GetAllVendorUsersQueryResult = Apollo.QueryResult<GetAllVendorUsersQuery, GetAllVendorUsersQueryVariables>;