import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyAdherenceStyle = makeStyles()(theme => ({
  value: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  linearProgress: {
    height: 10,
    borderRadius: 5,
  },
}));
