import { FC } from "react";
import { CallView } from "./types";
import { CallParticipant } from "./types";
import { Box, MuiMicIcon, MuiMicOffIcon, MuiScreenShareIcon, MuiVideocamIcon, MuiVideocamOffIcon, Typography } from "@toolkit/ui";
import { ChatAvatar } from "@health/chat";
import { useStyles } from "./MeetingParticipantView.styles";
import { useTranslation } from "@toolkit/i18n";

type MeetingParticipantViewProps = {
  participant: CallParticipant;
};

export const MeetingParticipantView: FC<MeetingParticipantViewProps> = ({ participant }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { videoMuted, screenMuted, audioMuted } = participant.state;
  const showVideo = (!videoMuted || !screenMuted) && !participant.isCurrentUser;
  return (
    <Box id={`participant_view_${participant.id}`} className={classes.root}>
      {showVideo && <CallView participant={participant} sharing={!screenMuted} />}
      {!showVideo && <ChatAvatar value={participant.info} size={140} />}

      <Box className={classes.info}>
        {!audioMuted ? <MuiMicIcon fontSize='inherit' /> : <MuiMicOffIcon fontSize='inherit' />}
        {!videoMuted ? <MuiVideocamIcon fontSize='inherit' /> : <MuiVideocamOffIcon fontSize='inherit' />}
        {!screenMuted && <MuiScreenShareIcon fontSize='inherit' />}
        <Typography variant='body1' className={classes.name}>
          {participant.isCurrentUser ? t("You") : participant.displayName}
        </Typography>
      </Box>
    </Box>
  );
};
