import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { IMedicalFormUpsertFormValues } from "pages/MedicalForms/forms/MedicalFormUpsert/MedicalFormUpsertFormSchema";
import { MedicalFormClassificationUpsertModal } from "pages/MedicalForms/modals/MedicalFormClassificationUpsert/MedicalFormClassificationUpsertModal";
import { IMedicalFormClassificationItem, IMedicalFormClassificationUpsertFormEvent } from "pages/MedicalForms/types";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useMedicalFormClassificationsFormStyle } from "./useMedicalFormClassificationsFormStyle";
import { useMedicalFormClassificationsTableColumns } from "./useMedicalFormClassificationsTableColumns";

export const MedicalFormClassificationsForm = () => {
  const [selectedClassification, setSelectedClassification] = useState<IMedicalFormClassificationItem>();

  const { t } = useTranslation("domains");
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes, theme } = useMedicalFormClassificationsFormStyle();

  const form = useCustomFormContext<IMedicalFormUpsertFormValues>();

  const { control } = form;

  const { fields, append, remove, update } = useFieldArray<IMedicalFormUpsertFormValues, "medicalFormClassifications">({
    control,
    name: "medicalFormClassifications",
  });

  const onAddClick = () => {
    setSelectedClassification(undefined);
    handleOpen();
  };

  const onEditRowClick = (item: IMedicalFormClassificationItem) => {
    setSelectedClassification(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IMedicalFormClassificationItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  const onMedicalFormClassificationUpsertModalChange = (event: IMedicalFormClassificationUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedClassification) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedClassification.id);
      if (updatedFieldIndex > -1) {
        const updatedTeaMember = { ...fields[updatedFieldIndex], ...event.payload.values };
        update(updatedFieldIndex, updatedTeaMember);
      }
      handleClose();
    }
  };

  return (
    <Grid container spacing={2}>
      <MedicalFormClassificationUpsertModal
        isOpen={open}
        onClose={handleClose}
        classification={selectedClassification}
        onChange={onMedicalFormClassificationUpsertModalChange}
      />

      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid>
            <Typography className={classes.title}> {t("Classifications")} </Typography>
          </Grid>

          <Grid>
            <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon fill={theme.palette.common.white} />}>
              {t("Add Classification")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useMedicalFormClassificationsTableColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable
          isRowDeletable
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
