/* eslint-disable max-lines */
import { FileType } from "@/schema/types";
import { DocumentStoresAutocomplete } from "@health/autocompletes";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  CircularProgress,
  CustomDialog,
  Divider,
  FileUploader,
  FileUploadProgress,
  LinearProgress,
  PlusIcon,
  TruncateTypography,
  Typography,
  useAddToast,
  useTheme,
} from "@toolkit/ui";
import { IAgentUpdateContainerParams } from "pages/AIAgents/types";
import { convertBytes } from "pages/AIAgents/utils";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useChatFlowDocumentCreateMutation } from "../../gql";

export const UploadModal: FC<{ refetch: () => void }> = ({ refetch }) => {
  const { open, handleToggle } = useOpenState();
  const methods = useForm();
  const { handleSubmit } = methods;
  const [file, setFile] = useState<Record<string, FileUploadProgress | undefined>>();
  const [lastFile, setLastFile] = useState("");
  const { t } = useTranslation("domains");
  const theme = useTheme();
  const { accessToken } = useOidcUserProfile();
  const isSubmitActive = file && lastFile && file[lastFile]?.fileLink;
  const { agentId } = useParams<IAgentUpdateContainerParams>();
  const { succeeded, failed } = useAddToast();

  const [createDocument, { loading }] = useChatFlowDocumentCreateMutation({
    onCompleted: data => {
      data?.chatFlowDocumentCreate?.id && succeeded(t("Document added successfully!"));
      refetch();
      handleToggle();
      setFile({});
      setLastFile("");
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      handleToggle();
    },
  });
  const onDoneClick = () => {
    const type = file?.[lastFile]?.file?.type?.toLowerCase() === "application/pdf" ? FileType.Pdf : FileType.Csv;
    const fileName = file?.[lastFile]?.fileLink?.split("/").pop();
    createDocument({
      variables: {
        chatFlowId: agentId!,
        documentStoreId: methods.getValues("documentStoreId")?.value?.id,
        input: {
          fileName: fileName!,
          fileType: type,
          convertToText: true,
        },
      },
    });
  };
  const onFileSelect = (files: File[]) => {
    setLastFile(files[0].name);
    const newFiles = {};
    if (files.length > 0) {
      files.forEach(file => {
        newFiles[file?.name] = { file, progress: 0, fileLink: "" };
      });
      setFile(file => ({ ...file, ...newFiles }));
    }
  };
  const onProgressChange = (uploadProgress: Record<string, FileUploadProgress>) => {
    setFile(file => ({ ...file, ...uploadProgress }));
  };
  const onEachFileUploadComplete = (filesProgress: FileUploadProgress) => {
    setFile(file => ({ ...file, [filesProgress.file.name]: filesProgress }));
  };
  return (
    <CustomDialog
      type='base'
      sx={{
        "&::before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: theme?.palette?.common?.black,
          opacity: 0.8,
        },
        "& .MuiDialog-paper": {
          borderRadius: 15,
          width: "440px",
        },
      }}
      open={open}
      DialogTitleProps={{
        title: t("Upload File"),
        onClose: handleToggle,
      }}
      button={
        <Button startIcon={<PlusIcon />} onClick={handleToggle}>
          {t("Upload")}
        </Button>
      }
      DialogActionsProps={{
        children: (
          <>
            {loading ? (
              <>
                <CircularProgress
                  sx={{
                    marginInlineEnd: "5px",
                  }}
                  size={theme.mixins.fonts.fontSize.md}
                />
                <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.md}>
                  {t("Processing...")}
                </Typography>
              </>
            ) : (
              <>
                <Button
                  variant='contained'
                  disabled={!isSubmitActive || loading}
                  sx={{
                    paddingX: "30px",
                  }}
                  onClick={onDoneClick}
                >
                  {t("Done")}
                </Button>
                <Button variant='outlined' onClick={handleToggle}>
                  {t("Close")}
                </Button>
              </>
            )}
          </>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onDoneClick)}>
          <DocumentStoresAutocomplete name='documentStoreId' />
          <Divider sx={{ paddingTop: "10px" }} />
          <FileUploader
            inputId='addaads'
            disabled={loading}
            onFileSelect={onFileSelect}
            onProgressChange={onProgressChange}
            token={accessToken}
            maxFileSize={1000000}
            multiple={false}
            accept='.pdf, .csv'
            onEachFileUploadComplete={onEachFileUploadComplete}
            child={
              <>
                {file &&
                  Object.keys(file)
                    .filter(fileName => fileName === file?.[lastFile]?.file?.name)
                    ?.map(fileName => (
                      <Box key={fileName} sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }} marginTop={"8px"}>
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "column" }} marginTop={"8px"}>
                          <Box sx={{ marginRight: 1, width: "100%", height: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box>
                              <TruncateTypography text={t(`Uploading File (${fileName})`)} />
                            </Box>
                            <Box color={"#5B7798"} fontSize='12px'>
                              {convertBytes(((file[fileName]?.file?.size || 1) * (file?.[fileName]?.progress || 100)) / 100)}/
                              {convertBytes(file[fileName]?.file?.size)}
                            </Box>
                          </Box>
                          <Box sx={{ marginRight: 1, width: "100%", height: "100%" }}>
                            <LinearProgress
                              sx={{
                                height: 15,
                                width: "100%",
                                backgroundColor: "#E7EBF0",
                                borderRadius: "8px",
                                "& .MuiLinearProgress-bar": {
                                  borderRadius: 8,
                                  backgroundColor: theme.palette.primary.main,
                                },
                              }}
                              variant='determinate'
                              value={file?.[fileName]?.progress}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
              </>
            }
          />
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
