import { GuidedCareTask, GuidedCareTaskSortingField, H_OrderDirection } from "@/schema/types";
import { getDoctorPatientsAutocompleteFilter, getDoctorsTeamLeadersAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, taskDefinitionStatusOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, useFormatDate } from "@toolkit/ui";
import { GuidedCareTaskActions } from "pages/GuidedCareTasks/components/GuidedCareTaskActions/GuidedCareTaskActions";
import { GuidedCareTaskAssignee } from "pages/GuidedCareTasks/components/GuidedCareTaskAssignee/GuidedCareTaskAssignee";
import { useMemo } from "react";
import { PatientProfile } from "shared/modules/patient";

type GuidedCareTaskListColumnsProps = {
  isComingFromPatientProfile?: boolean;
};

export const useGuidedCareTaskListColumns = (props: GuidedCareTaskListColumnsProps): CustomTableColumnProps<GuidedCareTask>[] => {
  const { isComingFromPatientProfile } = props;

  const { t } = useTranslation("provider");

  const { formatLocalizedDate } = useFormatDate();

  return useMemo(() => {
    return [
      {
        key: "task",
        header: t("Task"),
        accessor: ({ displayEn, displayAr }) => <TruncateTypography text={pickLocalizedValue(displayEn!, displayAr!)} />,
      },
      {
        key: "assignee",
        header: t("Assignees"),
        accessor: ({ assignee, patient }) => <GuidedCareTaskAssignee assignee={assignee!} patient={patient} />,
        isHidden: isComingFromPatientProfile,
      },
      {
        key: "patient",
        header: t("Patient"),
        accessor: ({ patient }) => {
          return patient ? (
            <PatientProfile
              patientId={patient?.id!}
              patientName={{
                firstName: patient?.firstName!,
                lastName: patient?.lastName!,
              }}
            />
          ) : (
            "-"
          );
        },
        isHidden: isComingFromPatientProfile,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => taskDefinitionStatusOptionsMap[status!]?.label,
      },
      {
        key: "dueDate",
        header: t("Due Date"),
        accessor: ({ dueDate }) => formatLocalizedDate(dueDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareTaskSortingField.DueDate,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: data => <GuidedCareTaskActions guidedCareTask={data} />,
      },
      {
        key: "assignees",
        header: t("Assignees"),
        showOnlyForFilterField: true,
        filter: !isComingFromPatientProfile
          ? getDoctorsTeamLeadersAutocompleteFilter({ name: "assigneeUserIds", multiple: true })
          : undefined,
      },
      {
        key: "patients",
        header: t("Patients"),
        showOnlyForFilterField: true,
        filter: !isComingFromPatientProfile ? getDoctorPatientsAutocompleteFilter({ name: "patientUserId", multiple: true }) : undefined,
      },
      {
        key: "statuses",
        header: t("Statuses"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("TaskDefinitionStatus", "statuses", { multiple: true }),
      },
    ];
  }, [formatLocalizedDate, isComingFromPatientProfile, t]);
};
