import { PatientHealthDocument, PatientHealthHistory } from "@/schema/types";
import { useOnPatientProfileAccessResponse, usePatientProfileAccessClose } from "@health/sse";
import { useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientMedicalProfileLazyQuery } from "../gql";

export const useCustomPatientMedicalProfileQuery = ({ token, patientId }) => {
  const [hasAccess, setCheckAccess] = useState<boolean>(false);
  const [consentStatus, setConsentStatus] = useState<string | undefined>(undefined);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

  const [getPatientMedicalProfileQuery, { data: patientMedicalProfileQueryResult, loading: patientMedicalProfileQueryLoading }] =
    usePatientMedicalProfileLazyQuery({
      onCompleted: () => {
        setCheckAccess(true);
      },
      onError: ({ graphQLErrors }) => {
        const error = graphQLErrors?.map(item => item?.extensions?.debugInfo?.["code"]!);
        setErrorCode(error?.[0]);
        setCheckAccess(false);
      },
      context: getApolloContextFormToken(token!),
    });

  useOnPatientProfileAccessResponse(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfileQuery({
        variables: {
          patientId: patientId!,
        },
      });
      setConsentStatus(data?.consentStatus);
    }
  });
  usePatientProfileAccessClose(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfileQuery({
        variables: {
          patientId: patientId!,
        },
        fetchPolicy: "no-cache",
      });
      setConsentStatus(data?.consentStatus);
    }
  });

  const handleResetStatus = () => {
    setConsentStatus(undefined);
    setCheckAccess(true);
  };

  const patientCurrentMedicalStatus = patientMedicalProfileQueryResult?.patientMedicalProfile?.patientCurrentMedicalStatus!;
  const patientHealthHistory = patientMedicalProfileQueryResult?.patientMedicalProfile?.patientHealthHistory as PatientHealthHistory[];
  const patientHealthDocuments = patientMedicalProfileQueryResult?.patientMedicalProfile?.healthDocuments?.edges?.map(
    item => item?.node
  ) as PatientHealthDocument[];
  const documentSecretKey = patientMedicalProfileQueryResult?.patientMedicalProfile?.documentSecretKey;

  return {
    patientCurrentMedicalStatus,
    patientHealthHistory,
    documentSecretKey,
    patientHealthDocuments,
    hasAccess,
    errorCode,
    consentStatus,
    handleResetStatus,
    patientMedicalProfileQueryLoading,
    getPatientMedicalProfileQuery,
  };
};
