import { FC } from "react";
import { IOptimaValidationRequestsListNode } from "../../types";
import { Typography, useTheme } from "@toolkit/ui";
import { transactionStatusTypeOptionsMap } from "@health/enum-options";
import { getOptimaAuthorizationStatusColor } from "@health/domains";
import { ApprovalStatus } from "@/schema/types";

type OptimaValidationStatusProps = {
  request: IOptimaValidationRequestsListNode;
};

export const OptimaValidationStatus: FC<OptimaValidationStatusProps> = props => {
  const { request } = props;
  const theme = useTheme();
  const color = getOptimaAuthorizationStatusColor(request?.approvalStatus || ApprovalStatus.Rejected, theme);

  return (
    <Typography color={color} width='max-width'>
      {request?.approvalStatus ? transactionStatusTypeOptionsMap[request?.approvalStatus || ""].label : "-"}
    </Typography>
  );
};
