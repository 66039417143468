import { useNavigate, useParams } from "react-router-dom";
import { User } from "@/schema/types";
import { UserProviderUpdateContainer } from "@health/domains";
import { useProviderUserGetQuery } from "../../gql";
import { IProviderUserUpdateContainerParams } from "../../types";
import { useSetUsersBreadcrumbs } from "../../hooks";
import { usersPaths } from "../../constants";

export const ProviderUserUpdateContainer = () => {
  const navigate = useNavigate();

  const { userId } = useParams<IProviderUserUpdateContainerParams>();

  const { data, loading } = useProviderUserGetQuery({
    variables: { id: userId! },
    skip: !userId,
    fetchPolicy: "no-cache",
  });

  const user = data?.user as User;

  const onUserVendorUpsertFormChange = () => {
    navigate(usersPaths.list.fullPath);
  };

  useSetUsersBreadcrumbs("UPDATE", `${user?.firstName} ${user?.lastName}`);

  return <UserProviderUpdateContainer user={user} isLoading={loading} onChange={onUserVendorUpsertFormChange} />;
};
