import { useSelectCall } from "./CallContext";
import { useMeetingQuery } from "../../gql/queries";
import { Meeting, VisitStatus } from "@/schema/types";
import { useEffect, useState } from "react";
import { CallState } from "../ZoomVideo";
import { useVisitStatus } from "./useVisitStatus";

export const useMeetingInfo = (visitId: string | undefined) => {
  const visitInfo = useVisitStatus(visitId);
  const callId = visitInfo.visit?.callId;
  const { data, loading, error, refetch } = useMeetingQuery({
    skip: !callId,
    variables: callId ? { meetingId: callId } : undefined,
  });

  const call = useSelectCall(data?.meeting as Meeting | undefined);

  const { dropCall, updateCallStatus } = call || {};
  const callState = call?.state.callState;
  const [joined, setJoined] = useState(callState === CallState.InCall);
  const visitStatus = visitInfo.visit?.status;

  useEffect(() => {
    switch (callState) {
      case CallState.InCall: {
        setJoined(true);
        break;
      }
      case CallState.Started:
      case CallState.NoCall: {
        setJoined(false);
        break;
      }
    }
  }, [callState]);

  useEffect(() => {
    if (callState === CallState.InCall && visitStatus !== VisitStatus.CallInProgress) {
      dropCall?.();
    }
    if (callState === CallState.Dropped && visitStatus !== VisitStatus.CallInProgress) {
      updateCallStatus?.(CallState.Ended);
    }
  }, [callState, visitStatus, dropCall, updateCallStatus]);

  return { visitInfo, call, joined, loading, error, handleRetry: refetch };
};
