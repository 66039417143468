import { createContext, useContext, useEffect } from "react";
import { CallInstance } from "./types";
import { Meeting } from "@/schema/types";

export type CallInfoParams = {
  callId: string;
  userId?: number;
  userName?: string;
};

export type UserInfo = { user_id?: number; name?: string };
export type CallProviderInfo = {
  call?: CallInstance;
  userInfo?: UserInfo;
  callInfo?: Meeting;
  accessToken?: string;
  select: (info: Meeting) => void;
};

export const CallContext = createContext<CallProviderInfo>({
  select: () => Error("Not implemented!"),
});

export function useActiveCall() {
  const { call, callInfo } = useContext(CallContext);
  const isInCall = call?.state.callState === "in_call";
  if (isInCall) {
    return { call, callInfo };
  }

  return null;
}

export function useSelectCall(meeting?: Meeting): CallInstance | undefined {
  const { callInfo, select, call } = useContext(CallContext);
  useEffect(() => {
    if (meeting) {
      select(meeting);
    }
  }, [meeting, select]);

  if (meeting?.id !== callInfo?.id) {
    return undefined;
  }

  return call;
}
