import { FC } from "react";
import { Box, IconButton, Show } from "@toolkit/ui";
import { ChatDialog } from "@/shared/components/Chat/ChatDialog";
import { Patient } from "@/schema/types";
import { PatientProfile } from "@/shared/modules/patient";
import { useGuidedCareJourneyActionsStyle } from "./useGuidedCareJourneyActionsStyle";

type GuidedCareJourneyActionsProps = {
  patient: Patient;
  programId: string;
};

export const GuidedCareJourneyActions: FC<GuidedCareJourneyActionsProps> = props => {
  const { patient, programId } = props;

  const { classes, theme } = useGuidedCareJourneyActionsStyle();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <PatientProfile
          patientId={patient?.id}
          defaultProgramId={programId}
          renderModalButton={modalButtonProps => (
            <IconButton {...modalButtonProps} className={classes.iconButton}>
              <Show fill={theme.palette.primary.main} />
            </IconButton>
          )}
        />
      </Box>

      <Box className={classes.wrapper}>
        <ChatDialog userId={patient?.user?.id!} />
      </Box>
    </Box>
  );
};
