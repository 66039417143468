import { countBy } from "lodash";
import { IOptimaValidationRequestsListNode } from "./types";
import { ApprovalStatus, OptimaValidationRequestFilterInput, TransactionTransactionStatus } from "@/schema/types";
import moment from "moment";
import { BACKEND_DATE_FORMAT } from "@toolkit/core";

export const getOptimaItemsResult = (items?: IOptimaValidationRequestsListNode["items"]) => {
  const counts = countBy(items, item => ((item?.edits?.length || 0) > 0 || (item?.riskFactors?.length || 0) > 0 ? "needReview" : "passed"));

  return {
    passedCount: counts.passed || 0,
    needReviewCount: counts.needReview || 0,
  };
};

export const getOptimaItemsStatus = (items?: IOptimaValidationRequestsListNode["items"]): TransactionTransactionStatus => {
  const { passedCount, needReviewCount } = getOptimaItemsResult(items);
  if (!needReviewCount && !passedCount) {
    return TransactionTransactionStatus.Rejected;
  }
  if (needReviewCount) {
    return TransactionTransactionStatus.PartiallyApproved;
  }
  return TransactionTransactionStatus.FullyApproved;
};

export interface IOptimaRequestKpi {
  type: requestsKpiMode;
  filters: OptimaValidationRequestFilterInput;
}

export type requestsKpiMode = "all" | "rejected" | "partially";

export const optimaRequestKpi: Record<requestsKpiMode, IOptimaRequestKpi> = {
  all: {
    type: "all",
    filters: {},
  },
  rejected: {
    type: "rejected",
    filters: {
      approvalStatuses: [ApprovalStatus.Rejected],
      fromDate: moment().startOf("day").toISOString(),
      toDate: moment().endOf("day"),
    },
  },
  partially: {
    type: "partially",
    filters: {
      approvalStatuses: [ApprovalStatus.PartiallyApproved],
      fromDate: moment().startOf("day").format(BACKEND_DATE_FORMAT),
      toDate: moment().endOf("day").format(BACKEND_DATE_FORMAT),
    },
  },
} as const;
