import { AnonymousChatGroup, ChatGroup } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";

type ChatTitleProps = {
  value: ChatGroup | AnonymousChatGroup | undefined;
};

export const ChatStatus: FC<ChatTitleProps> = ({ value }) => {
  const { t } = useTranslation();
  const participantsCount = value?.participants?.length;

  return <>{t("{{participantsCount}} Participants", { participantsCount })}</>;
};
