import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { downloadDocument, DownloadDocumentOptions } from "@/utils/downloadDocument";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress } from "@toolkit/ui";
import { FC, useState } from "react";
import { useAuth } from "react-oidc-context";

type HealthDocumentDownloadProps = {
  fileLinks: Array<string>;
};

export const HealthDocumentDownload: FC<HealthDocumentDownloadProps> = props => {
  const { fileLinks } = props;

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("provider");

  const { user } = useAuth();
  const { documentSecretKey } = useProfileContext();

  const options: DownloadDocumentOptions = {
    token: user?.access_token!,
    downloadKey: documentSecretKey!,
  };

  const handleDownloadFile = async () => {
    if (!fileLinks?.length) return;
    setLoading(true);

    for (const fileLink of fileLinks) {
      try {
        await downloadDocument(fileLink, options);
      } catch (error) {
        console.error("Failed to download document", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return fileLinks?.length ? (
    <Button
      variant={"outlined"}
      disabled={!fileLinks?.length || loading}
      onClick={handleDownloadFile}
      endIcon={loading ? <CircularProgress size={20} /> : null}
    >
      {t("Download")}
    </Button>
  ) : (
    "-"
  );
};
