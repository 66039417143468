import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Typography } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { QRCode } from "./QRCode";
import { useDispenseModelStyles } from "./QRCodeModal.styles";

interface QRCodeModalProps {
  title: string;
  printTitle?: string;
  qrCode: string | unknown;
  withPrint: boolean;
  open: boolean;
  handleClose: () => void;
}

export const QRCodeModal: FC<QRCodeModalProps> = ({ title, qrCode, withPrint, printTitle, open, handleClose }) => {
  const qrCodeString = JSON.stringify(qrCode);
  const { t, i18n } = useTranslation("provider");
  const [toPrint, setToPrint] = useState<Date | undefined>();
  const [printReady, setPrintReady] = useState<Date | undefined>();
  const { classes } = useDispenseModelStyles();

  useEffect(() => {
    if (printReady) handlePrint();
  }, [printReady]);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });

  const componentRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setToPrint(new Date());
  };

  useEffect(() => {
    if (toPrint) {
      setPrintReady(new Date());
    }
  }, [toPrint]);

  return open ? (
    <CustomDialog
      type='base'
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
      DialogTitleProps={{
        onClose: handleClose,
        title: title,
      }}
      DialogActionsProps={{
        children: (
          <>
            {withPrint && (
              <ReactToPrint
                trigger={() => (
                  <Button className={classes.print} onClick={handleClick}>
                    {t("Print")}
                  </Button>
                )}
                content={() => componentRef?.current}
              />
            )}
            <Box display={window.matchMedia("print") ? "none" : "flex"}>
              <div dir={i18n.dir(i18n.language)} ref={componentRef}>
                <Box height='100vh' bgcolor='transparent' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                  <Typography className={classes.id}>{printTitle || title}</Typography>
                  <Box sx={{ position: "relative", margin: "0px" }}>
                    <Box
                      sx={{
                        margin: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <QRCode qrCodeText={qrCodeString} />
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
            <Button onClick={handleClose} className={classes.ok}>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <Box className={classes.box}>
        <div className={classes.container}>
          <QRCode qrCodeText={qrCodeString} />
        </div>
      </Box>
    </CustomDialog>
  ) : null;
};

QRCodeModal.defaultProps = {
  printTitle: "",
};
