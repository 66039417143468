import { Maybe, OptimaDiagnosis } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { FC } from "react";
import { OptimaDiagnosisEditCard } from "../OptimaEditCard/OptimaDiagnosisEditCard";

interface OptimaDiagnosisEditsListProps {
  display?: string;
  diagnosis?: Maybe<OptimaDiagnosis>[] | null;
}

export const OptimaDiagnosisEditsList: FC<OptimaDiagnosisEditsListProps> = ({ display = "", diagnosis: diagnosis }) => {
  const { t } = useTranslation("provider");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, marginTop: 1.5 }}>
      {!isEmpty(diagnosis) && (
        <Typography textAlign='start'>{t("As per medical coding and insurance guidelines, the following has been flagged")}:</Typography>
      )}
      {diagnosis?.map(diagnosis => {
        return diagnosis?.edits?.map(editItem => (
          <OptimaDiagnosisEditCard key={editItem?.edit?.id} display={display} edit={editItem?.edit} diagnosisCode={diagnosis.code!} />
        ));
      })}
    </Box>
  );
};
