import { FC } from "react";
import { IOptimaValidationRequestsListNode } from "../../types";
import { Box, makeStyles, Typography } from "@toolkit/ui";
import { getOptimaItemsResult } from "../../utils";
import { useTranslation } from "@toolkit/i18n";

type OptimaValidationResultProps = {
  request: IOptimaValidationRequestsListNode;
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: "max-content",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
  },
  passedText: {
    color: theme.palette.success.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  needReviewText: {
    color: theme.palette.warning.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  noItemsText: {
    color: theme.palette.error.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));

export const OptimaValidationResult: FC<OptimaValidationResultProps> = props => {
  const { request } = props;
  const { classes } = useStyles();
  const { needReviewCount, passedCount } = getOptimaItemsResult(request?.items);
  const total = needReviewCount + passedCount;
  const { t } = useTranslation("provider");

  let text = "";
  let textClass = "";

  if (total === 0) {
    text = t("No items");
    textClass = classes.noItemsText;
  } else if (total === passedCount) {
    text = t("{{items}} passed", { items: passedCount });
    textClass = classes.passedText;
  } else {
    text = t("{{items}} need review of {{total}}", {
      items: needReviewCount,
      total,
    });
    textClass = classes.needReviewText;
  }

  return (
    <Box className={classes.root}>
      <Typography className={textClass}>{text}</Typography>
    </Box>
  );
};
