import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramInviteMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AddMemberToProgramInput>;
}>;


export type GuidedCareProgramInviteMutation = { __typename?: 'Mutation', addMemberToProgram?: { __typename?: 'GuidedCareHealthProgram', id: string } | null };


export const GuidedCareProgramInviteDocument = gql`
    mutation GuidedCareProgramInvite($input: AddMemberToProgramInput) {
  addMemberToProgram(input: $input) {
    id
  }
}
    `;
export type GuidedCareProgramInviteMutationFn = Apollo.MutationFunction<GuidedCareProgramInviteMutation, GuidedCareProgramInviteMutationVariables>;

/**
 * __useGuidedCareProgramInviteMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramInviteMutation, { data, loading, error }] = useGuidedCareProgramInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareProgramInviteMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramInviteMutation, GuidedCareProgramInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramInviteMutation, GuidedCareProgramInviteMutationVariables>(GuidedCareProgramInviteDocument, options);
      }
export type GuidedCareProgramInviteMutationHookResult = ReturnType<typeof useGuidedCareProgramInviteMutation>;
export type GuidedCareProgramInviteMutationResult = Apollo.MutationResult<GuidedCareProgramInviteMutation>;
export type GuidedCareProgramInviteMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramInviteMutation, GuidedCareProgramInviteMutationVariables>;