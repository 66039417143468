import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPrescriptionsOrdersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.OrderSortingInput>;
  isAdmin: Types.Scalars['Boolean']['input'];
}>;


export type GetPrescriptionsOrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, created: any, number: string, status: Types.OrderStatus, type: Types.OrderType, totalPatientShareAmount?: number | null, totalOrderDistance?: number | null, totalNetAmount?: number | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } | null, children?: Array<{ __typename?: 'Order', number: string, status: Types.OrderStatus, type: Types.OrderType, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null, contactNumber?: string | null } | null } | null> | null, prescription: { __typename?: 'Prescription', id: string, patientIdentifier: string, referenceNumber: string, dispenseStatus: Types.PrescriptionDispenseStatus, patient: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null, contactNumber?: string | null, email?: string | null }, medications: Array<{ __typename?: 'Medication', id: number, code: string, refills?: number | null, display?: string | null, quantity?: number | null, instructions?: string | null, arabicInstructions?: string | null }>, orders: { __typename?: 'OrderCountableConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename: 'Order', id: string, number: string } }> } } } }> } | null };


export const GetPrescriptionsOrdersDocument = gql`
    query getPrescriptionsOrders($filter: OrderFilterInput, $first: Int, $after: String, $before: String, $last: Int, $sortBy: OrderSortingInput, $isAdmin: Boolean!) {
  orders(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        created
        number
        status
        type
        branch {
          id
          name
          nameAr
          vendor @include(if: $isAdmin) {
            id
            name
            nameAr
          }
        }
        totalPatientShareAmount
        totalOrderDistance
        totalNetAmount
        children {
          number
          status
          type
          branch {
            name
            nameAr
            contactNumber
          }
        }
        prescription {
          id
          patientIdentifier
          referenceNumber
          patient {
            firstName
            lastName
            contactNumber
            email
          }
          medications {
            id
            code
            refills
            display
            quantity
            instructions
            arabicInstructions
          }
          dispenseStatus
          orders(first: 20) {
            pageInfo {
              ...PageInfoFragment
            }
            edges {
              node {
                id
                number
                __typename
              }
            }
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetPrescriptionsOrdersQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionsOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetPrescriptionsOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables> & ({ variables: GetPrescriptionsOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>(GetPrescriptionsOrdersDocument, options);
      }
export function useGetPrescriptionsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>(GetPrescriptionsOrdersDocument, options);
        }
export function useGetPrescriptionsOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>(GetPrescriptionsOrdersDocument, options);
        }
export type GetPrescriptionsOrdersQueryHookResult = ReturnType<typeof useGetPrescriptionsOrdersQuery>;
export type GetPrescriptionsOrdersLazyQueryHookResult = ReturnType<typeof useGetPrescriptionsOrdersLazyQuery>;
export type GetPrescriptionsOrdersSuspenseQueryHookResult = ReturnType<typeof useGetPrescriptionsOrdersSuspenseQuery>;
export type GetPrescriptionsOrdersQueryResult = Apollo.QueryResult<GetPrescriptionsOrdersQuery, GetPrescriptionsOrdersQueryVariables>;