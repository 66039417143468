import { PatientEligibilityRequestHistoryCountableConnection } from "@/schema/types";
import { ExtractNodeType } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { BenefitCoverageModal } from "../BenefitCoverageModal/BenefitCoverageModal";
import { CoverageItem } from "../BenefitCoverageModal/useCoverageListColumns";

export type BenefitItem = NonNullable<
  NonNullable<ExtractNodeType<PatientEligibilityRequestHistoryCountableConnection>["patientEligibility"]["benefits"]>[number]
>;
export const useBenefitsListColumns = (): CustomTableColumnProps<BenefitItem>[] => {
  const { t } = useTranslation("optima");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: "benefitType",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => <Typography>{code || "-"}</Typography>,
      },
      {
        key: "authorizationRequired",
        header: t("Authorization Required"),
        accessor: ({ authorizationRequired }) => {
          return <Typography>{authorizationRequired ? t("Yes") : t("No") || "-"} </Typography>;
        },
      },
      {
        key: "coverage",
        header: t("Coverage"),
        accessor: ({ coverage }) => {
          return <BenefitCoverageModal data={(coverage as CoverageItem[]) || []} />;
        },
      },
    ];
  }, [t]);
};
