import { FC } from "react";
import { CallInstance } from "./types";
import { MeetingSelfView } from "./MeetingSelfView";
import { MeetingParticipantView } from "./MeetingParticipantView";
import { Grid } from "@toolkit/ui";

type MeetingViewProps = {
  call: CallInstance;
};

export const MeetingView: FC<MeetingViewProps> = ({ call }) => {
  const { participants } = call;
  return (
    <>
      <MeetingSelfView call={call} />
      <Grid container spacing={0} flex={1} overflow='auto' xs={12}>
        {participants?.map(
          p =>
            (!p.isCurrentUser || participants.length === 1) && (
              <Grid key={p.id} item xs display='flex' alignItems='center' justifyContent='center' maxHeight='100%'>
                <MeetingParticipantView participant={p} />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};
