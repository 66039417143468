import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorPatientsAutocompleteQuery } from "./gql";
import { IDoctorPatientAutocomplete } from "./schema";

export const createDoctorPatientAutocompleteOption = (patient: IDoctorPatientAutocomplete) => {
  return createAutocompleteOption({ id: patient?.id!, fullName: patient?.fullName! }, "id", "fullName");
};

export const getDoctorPatientsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "doctorTeamEnrolledMembers",
    query: useDoctorPatientsAutocompleteQuery,
    labelBy: item => item?.user?.fullName!,
    backendAccessor: item => item?.user?.id!,
  });
};
