import { OptimaValidationRequestsSortingField, SortDirection } from "@/schema/types";
import { GridCellText } from "@health/domains";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { DATE_FORMAT_FOR_TABLE_LONG, toTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import OptimaVisitResultDetailsModal from "../../components/OptimaVisitResultDetails/OptimaVisitResultDetails";
import { OptimaValidationResult } from "../../components/requestResult/requestResult";
import { OptimaValidationStatus } from "../../components/requestStatus/requestStatus";
import { IOptimaValidationRequestsListNode } from "../../types";

export const useOptimaValidationRequestsListContainerColumns = (): CustomTableColumnProps<IOptimaValidationRequestsListNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Request Id"),
        accessor: ({ requestId }) => <TruncateTypography text={requestId || "-"} />,
        filter: {
          type: "string",
          name: "requestId",
        },
      },
      {
        key: "visitId",
        header: t("Visit Id"),
        accessor: ({ visitId }) => <GridCellText text={visitId} />,
        filter: {
          type: "string",
          name: "visitId",
        },
      },
      {
        key: "patientId",
        header: t("Patient Id"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "patientId",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ patientName }) => <GridCellText text={toTitleCase(patientName || "")} />,
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "insurancePayer",
        header: t("Payer"),
        accessor: ({ insurancePayer }) => <GridCellText text={insurancePayer} />,
      },
      {
        key: "insuranceReceiver",
        header: t("Receiver"),
        accessor: ({ insuranceReceiver }) => <GridCellText text={insuranceReceiver} />,
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: OptimaValidationRequestsSortingField.Created,
        accessor: ({ createdDate }) => <GridCellText text={format(createdDate, DATE_FORMAT_FOR_TABLE_LONG)} />,
      },
      {
        key: "status",
        header: t("Potential Status"),
        accessor: row => <OptimaValidationStatus request={row} />,
      },
      {
        key: "createdDateFrom",
        header: t("Created Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
      {
        key: "createdDateTo",
        header: t("Created Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "hasDislikedEdits",
        header: t("Has Disliked Edits"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "hasDislikedEdits"),
      },
      {
        key: "potentialStatus",
        header: t("Potential Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("ApprovalStatusType", "approvalStatuses", { multiple: true }),
      },
      {
        key: "result",
        header: t("Result"),
        accessor: row => <OptimaValidationResult request={row} />,
      },
      {
        key: "showResultDetails",
        header: t("Result Details"),
        accessor: row => <OptimaVisitResultDetailsModal optimaValidationRequest={row} />,
      },
    ];
  }, [t]);
};
