import { useTranslation } from "@toolkit/i18n";
import { format, ValueWithLabel } from "@toolkit/ui";
import { usePriorAuthorizationDrawerStyles } from "../PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { FC } from "react";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { useSiteSettingsGetQuery } from "shared/gql/queries";
import { OptimaPriorRequestNodeFragmentFragment } from "shared/gql/fragments";

type PriorAuthorizationDrawerDetailsProps = {
  request: OptimaPriorRequestNodeFragmentFragment;
};

export const PriorAuthorizationDrawerDetails: FC<PriorAuthorizationDrawerDetailsProps> = props => {
  const { request } = props;
  const { priorAuthorization } = request;
  const { classes } = usePriorAuthorizationDrawerStyles();
  const { t } = useTranslation("domains");

  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";

  return (
    <>
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Start Date")}
        value={priorAuthorization?.start ? format(priorAuthorization?.start, DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("End Date")}
        value={priorAuthorization?.end ? format(priorAuthorization?.end, DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Limit")}
        value={priorAuthorization?.limit ? priorAuthorization?.limit + " " + defaultCurrency : "-"}
      />
      <ValueWithLabel
        labelProps={{
          className: classes.label,
        }}
        valueProps={{
          className: classes.value,
        }}
        label={t("Result")}
        value={priorAuthorization?.result || "-"}
      />
    </>
  );
};
