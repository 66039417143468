import { i18n } from "@toolkit/i18n";
import { Source } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const sourceOptionsMap: IEnumToOptionsMap<Source> = {
  [Source.Iot]: {
    key: Source.Iot,
    get label() {
      return i18n.t("IoT", { ns: "domains" });
    },
    value: Source.Iot,
  },
  [Source.Doctor]: {
    key: Source.Doctor,
    get label() {
      return i18n.t("Doctor", { ns: "domains" });
    },
    value: Source.Doctor,
  },
  [Source.Nurse]: {
    key: Source.Nurse,
    get label() {
      return i18n.t("Nurse", { ns: "domains" });
    },
    value: Source.Nurse,
  },
  [Source.Patient]: {
    key: Source.Patient,
    get label() {
      return i18n.t("Patient", { ns: "domains" });
    },
    value: Source.Patient,
  },
};

export const sourceOptions = Object.values(sourceOptionsMap);
