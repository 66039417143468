import { doctorsRoutes } from "@/pages/Doctors/constants";
import { optimaRoutes } from "@/pages/Optima/constants";
import { PlaygroundsRoutes } from "@/pages/Playgrounds/route";
import { doLogout } from "@/shared/configs/apollo";
import { useFeatureFlag } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { agentsRoutes } from "pages/AIAgnets/constants";
import { chatRoutes } from "pages/Chat/route";
import { globalOrdersRoutes } from "pages/GlobalOrders/constants/global-orders-routes.constants";
import { guidedCareJourneysRoutes } from "@/pages/GuidedCareJourneys/constants";
import { healthProvidersManagementRoutes } from "pages/HealthProviderManagement/constants/HealthProvidersManagementRoutes";
import { marketplaceRoutes } from "pages/Marketplace/constants/marketplace-routes.constants";
import { medicalFormsRoutes } from "pages/MedicalForms";
import { notificationsRoutes } from "pages/Notifications/route";
import { prescriptionsOrdersTrackingRoutes } from "pages/PrescriptionsOrdersTracking";
import { settingsRoutes } from "pages/Settings/route";
import { streamingRoutes } from "pages/Streaming/route";
import { visitHistoryRoutes } from "pages/VisitHistory/route";
import { appointmentsRoutes } from "pages/appointments/constants";
import { dashboardRoutes } from "pages/dashboard";
import { profileRoutes } from "pages/profile/route";
import { programsCallsRoutes } from "pages/programs-calls/route";
import { useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { patientProfileRoutes } from "shared/modules/patient";
import RouteItem from "./routeTypes";

type IUseAppRoutes = {
  routes: RouteItem[];
  routesInitialized: boolean;
};

export const useAppRoutes = (): IUseAppRoutes => {
  const navigate = useNavigate();
  const { t } = useTranslation("provider");
  const auth = useAuth();
  const { userInfo } = useOidcUserProfile();
  const { isFeatureEnabled } = useFeatureFlag();

  useEffect(() => {
    if (auth && auth.isLoading) return;
    if (auth && auth.isAuthenticated && !auth.user?.expired) {
      const intervalId = setInterval(() => {
        const expiresIn = auth?.user?.expires_in!;
        if (expiresIn == 0 || !!auth?.user?.expired) {
          doLogout();
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }

    auth.signinRedirect();
  }, [auth]);

  return useMemo(() => {
    const routes = [
      PlaygroundsRoutes({ navigate, t }),
      dashboardRoutes({ navigate, t, userInfo }),
      appointmentsRoutes({ navigate, t, userInfo }),
      programsCallsRoutes({ navigate, t, userInfo }),
      visitHistoryRoutes({ navigate, t, userInfo }),
      guidedCareJourneysRoutes(navigate),
      doctorsRoutes({ navigate, userInfo }),
      patientProfileRoutes({ navigate, t }),
      chatRoutes({ navigate, t }),
      medicalFormsRoutes({ navigate, t }),
      healthProvidersManagementRoutes({ navigate, t }),
      globalOrdersRoutes({ navigate, t, isFeatureEnabled }),
      marketplaceRoutes({ navigate, t, isFeatureEnabled }),
      optimaRoutes({ navigate, t }),
      prescriptionsOrdersTrackingRoutes({ navigate, t }),
      streamingRoutes({ navigate, t, userInfo }),
      agentsRoutes({ navigate }),
      settingsRoutes({ navigate, t }),
      notificationsRoutes({ navigate, t }),
      profileRoutes({ navigate, t }),
    ];

    return {
      routes,
      routesInitialized: !auth.isLoading,
    };
  }, [navigate, t, userInfo, isFeatureEnabled, auth.isLoading]);
};
