import { FC } from "react";
import { Box, ValueWithLabel } from "@toolkit/ui";
import { ExtractNodeType } from "@toolkit/apollo";
import { OptimaClaimsListQuery } from "pages/OptimaClaims/gql";
import { useTranslation } from "@toolkit/i18n";
import { useRemittanceDrawerStyles } from "../RemittanceDrawer/RemittanceDrawerStyles";
import moment from "moment";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";

type EncounterDetailsProps = {
  claim: ExtractNodeType<OptimaClaimsListQuery>;
};

export const ClaimEncounterDetails: FC<EncounterDetailsProps> = ({ claim }) => {
  const { classes } = useRemittanceDrawerStyles();
  const { t } = useTranslation("domains");

  return (
    <Box className={classes.sectionContainer}>
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Start Type")}
        value={claim.encounterStartTypeLookup?.name || claim.encounterStartType?.toString() || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("Start Date")}
        value={claim.encounterStart ? moment(claim.encounterStart).format(DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("End Type")}
        value={claim.encounterEndTypeLookup?.name || claim.encounterEndType?.toString() || "-"}
      />
      <ValueWithLabel
        labelProps={{ className: classes.label }}
        valueProps={{ className: classes.value }}
        label={t("End Date")}
        value={claim.encounterEnd ? moment(claim.encounterEnd).format(DATE_FORMAT_FOR_TABLE_LONG) : "-"}
      />
    </Box>
  );
};
