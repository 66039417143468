export type DownloadDocumentOptions = {
  token: string;
  headers?: Record<string, string>;
  filename?: string;
  downloadKey?: string;
};

export const downloadDocument = async (url, options: DownloadDocumentOptions) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${options.token || ""}`,
        "document-secret-key": options?.downloadKey || "",
        ...options.headers,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to download document: ${response.statusText}`);
    }

    const blob = await response.blob();

    const fileName = response.url.split("/").pop();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName || "document";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading document:", error);
  }
};
