import { Typography } from "@toolkit/ui";
import { FC } from "react";

type AddressSectionProps = {
  address: string;
};

export const AddressSection: FC<AddressSectionProps> = ({ address }) => {
  return (
    <Typography fontSize={12} gutterBottom>
      {address}
    </Typography>
  );
};
