import React, { FC, useEffect } from "react";
import { useCustomForm } from "@toolkit/core";
import { User, VendorUserTypes } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { convertUserProviderToFormValues, doesVendorUserTypeRequireHealthLicense } from "../../others";
import { UserProviderInformationForm } from "../UserProviderInformation/UserProviderInformationForm";
import { UserProviderVendorInformationForm } from "../UserProviderVendorInformation/UserProviderVendorInformationForm";
import { UserProviderDoctorDetailsForm } from "../UserProviderDoctorDetails/UserProviderDoctorDetailsForm";
import { UserProviderNationalIdForm } from "../UserProviderNationalId/UserProviderNationalIdForm";
import { UserProviderHealthLicenseInformationForm } from "../UserProviderHealthLicenseInformation/UserProviderHealthLicenseInformationForm";
import { DepartmentsAutocomplete } from "@health/autocompletes";
import { userProviderUpsertFormSchema, userProviderUpsertFormDefaultValues, IUserProviderUpsertForm } from "./UserProviderUpsertFormSchema";

export type IUserProviderUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IUserProviderUpsertForm;
  };
};

type UserProviderUpsertFormProps = {
  user?: User;
  vendorId?: string;
  buttonLabel: string;
  isAdmin?: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IUserProviderUpsertFormEvent) => void;
};

export const UserProviderUpsertForm: FC<UserProviderUpsertFormProps> = props => {
  const { user, vendorId, buttonLabel, isAdmin, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IUserProviderUpsertForm>({
    defaultValues: userProviderUpsertFormDefaultValues,
    schema: userProviderUpsertFormSchema(isAdmin),
  });

  const { watch, handleSubmit, setValues } = form;

  const vendorUserType = watch("vendorUserType")?.value;
  const isVendorUserTypeDoctor = vendorUserType && vendorUserType === VendorUserTypes.Doctor;
  const vendorUserTypeRequireHealthLicense = vendorUserType && doesVendorUserTypeRequireHealthLicense(vendorUserType);

  const onSubmit = (values: IUserProviderUpsertForm) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (user) {
      const _user = convertUserProviderToFormValues(user);
      setValues(_user);
    }
  }, [user, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            {!isAdmin && !user && (
              <Grid item xs={12}>
                <FormCard title={t("Search By National ID")} loading={isLoading} doYouHaveData>
                  <UserProviderNationalIdForm />
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("User Information")} loading={isLoading} doYouHaveData>
                <UserProviderInformationForm isUpdateMode={!!user} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={isAdmin ? t("Provider Information") : t("Branch Information")} loading={isLoading} doYouHaveData>
                <UserProviderVendorInformationForm isAdmin={isAdmin} vendorId={vendorId} isUpdateMode={!!user} />
              </FormCard>
            </Grid>

            {!isAdmin && isVendorUserTypeDoctor && (
              <Grid item xs={12}>
                <FormCard title={t("Department Information")} loading={isLoading} doYouHaveData>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <DepartmentsAutocomplete name={"departments"} multiple />
                    </Grid>
                  </Grid>
                </FormCard>
              </Grid>
            )}

            {isVendorUserTypeDoctor && (
              <Grid item xs={12}>
                <UserProviderDoctorDetailsForm isLoading={isLoading} />
              </Grid>
            )}

            {vendorUserTypeRequireHealthLicense && (
              <Grid item xs={12}>
                <FormCard title={t("Health License Information")} loading={isLoading} doYouHaveData>
                  <UserProviderHealthLicenseInformationForm isVendorUserTypeDoctor={isVendorUserTypeDoctor} />
                </FormCard>
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
