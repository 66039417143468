/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { gql, useQuery } from "@apollo/client";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, useAddToast } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { usePatientEligibilityRequestCreateMutation } from "../gql/mutations";
import {
  CreateEligibilityRequestForm,
  OptimaCreateEligibilityRequestFormApi,
} from "./CreateEligibilityRequestForm/CreateEligibilityRequestForm";
import {
  createEligibilityRequestFormDefaultValues,
  ICreateEligibilityRequestFormValues,
} from "./CreateEligibilityRequestForm/createEligibilityRequestFormSchema";

type IOpen = (data?: { nationalId?: string }) => void;
type IClose = () => void;

export const OptimaCreateEligibilityRequestModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const OptimaCreateEligibilityRequestModal: FC<{
  onSubmitSuccess?: () => void;
}> = ({ onSubmitSuccess }) => {
  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const [fetchCreatePatientEligibilityRequest, { loading: isSubmitting }] = usePatientEligibilityRequestCreateMutation();
  const { data: defaultBranchData } = useQuery(
    gql`
      query {
        me {
          defaultBranch {
            id
            name
            nameAr
            healthLicense
          }
        }
      }
    `
  );

  useEffect(() => {
    const open: IOpen = data => {
      handleOpen();
      createEligibilityRequestFormDefaultValues.nationalId = data?.nationalId;
    };

    const close: IClose = () => {
      handleClose();
    };

    OptimaCreateEligibilityRequestModalApi.open = open;
    OptimaCreateEligibilityRequestModalApi.close = close;
  }, []);

  const handleSubmit = (data: ICreateEligibilityRequestFormValues) => {
    fetchCreatePatientEligibilityRequest({
      variables: {
        input: {
          nationalId: data.nationalId,
          mobileCountryCode: data.mobileCountryCode,
          mobile: data.mobile.replace(data.mobileCountryCode, ""),
          firstName: data.firstName,
          lastName: data.lastName,
          branchLicense: defaultBranchData?.me?.defaultBranch?.healthLicense,
          payer: data.payer.value,
          priority: data.priority,
        },
      },
    })
      .then(result => {
        if (!result.errors || result.errors.length === 0) {
          succeeded(t("Eligibility request created successfully"));
          handleClose();
          onSubmitSuccess?.();
        } else {
          failed(t("Failed to create eligibility request \n " + result?.errors?.[0]?.message));
        }
      })
      .catch(error => {
        failed(t("Failed to create eligibility request \n " + error?.errors?.[0]?.message));
        console.error(error);
      });
  };

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      keepMounted={false}
      DialogTitleProps={{
        title: t("Create Eligibility Request"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        disableSubmit: OptimaCreateEligibilityRequestFormApi.isSubmitting,
        onSubmit() {
          OptimaCreateEligibilityRequestFormApi.submit();
        },
        submitTitle: t("Scan Now"),
      }}
    >
      <CreateEligibilityRequestForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
    </CustomDialog>
  );
};
