import { useChatsQuery } from "../../gql/queries";
import { Chat, ChatSortingField, CommunicationSortDirection } from "../../schema/types";
import { useCallback, useEffect, useMemo } from "react";
import { useChats } from "../ChatProvider";
import { useOnChatHaveNewMessage } from "@health/sse";

export const useChatListHooks = (chatGroupId?: string | null) => {
  const { data, fetchMore, loading, refetch, error } = useChatsQuery({
    skip: !chatGroupId,
    fetchPolicy: "network-only",
    variables: chatGroupId
      ? {
          first: 10,
          filter: { chatGroupId },
          sortBy: {
            field: ChatSortingField.Created,
            direction: CommunicationSortDirection.Desc,
          },
        }
      : undefined,
  });
  const { chatUpdates, clearChatUpdates } = useChats();
  useEffect(() => {
    if (chatUpdates.length) {
      clearChatUpdates();
      refetch();
    }
  }, [chatUpdates, clearChatUpdates, refetch]);

  useOnChatHaveNewMessage(({ data: chat }) => {
    if (chat.chatGroupId === chatGroupId) {
      refetch();
    }
  });

  const chats = useMemo(() => data?.chats?.edges.map(e => e.node) as Chat[], [data]);
  const keyExtractor = useCallback((item: Chat) => item.id, []);
  const pageInfo = data?.chats?.pageInfo;
  const hasMore = pageInfo?.hasNextPage;
  const hasError = !!error;
  const isEmpty = !chats || chats.length === 0;

  const handleFetchMore = useCallback(() => {
    if (pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            chats: {
              ...fetchMoreResult.chats,
              edges: [...(prev?.chats?.edges || []), ...(fetchMoreResult?.chats?.edges || [])],
            },
          });
        },
      });
    }
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage, loading]);

  const handleRetry = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return {
    data: chats,
    hasMore,
    hasError,
    isEmpty,
    loading,
    keyExtractor,
    handleFetchMore,
    handleRetry,
  };
};
