import { useForm } from "react-hook-form";
import { formData } from "./type";

export const usePackageFormHook = ({ handleOnSubmit }) => {
  const methods = useForm<formData>({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors: errorsForm },
  } = methods;

  const onSubmit = (data: formData) => {
    handleOnSubmit({ ...data });
  };

  return {
    methods,
    location,
    errorsForm,
    handleSubmit,
    onSubmit,
  };
};
