import { removeEmptyRanges, WorkingHoursType } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BranchFormInputData } from "./BranchesForm.types";

export const useBranchFormHook = ({ branch, handleOnSubmit }) => {
  const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>({ lat: 24.750643, lng: 46.707766 });

  useEffect(() => {
    branch?.address?.coordinates && setLocation(branch?.address?.coordinates);
  }, [branch]);

  const handleLocation = (location: { lat: number; lng: number }) => {
    setLocation(location);
  };

  const methods = useForm<BranchFormInputData>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors: errorsForm },
    watch,
  } = methods;

  const onSubmit = (data: BranchFormInputData) => {
    const { healthLicenseStartDate, healthLicenseEndDate, addressInput, arabicStreetAddress2, arabicStreetAddress, workingHours, ...rest } =
      data;
    const working = workingHours as WorkingHoursType[];
    const request = {
      ...rest,
      healthLicenseStartDate: typeof healthLicenseStartDate == "string" ? healthLicenseStartDate : healthLicenseStartDate?.toISOString(),
      healthLicenseEndDate: typeof healthLicenseEndDate == "string" ? healthLicenseEndDate : healthLicenseEndDate?.toISOString(),
      type: data?.type?.["value"],
      addressInput: {
        ...addressInput,
        city: addressInput?.city,
        coordinates: {
          lat: location?.lat as number,
          lng: location?.lng as number,
        },
        streetAddress2Ar: arabicStreetAddress2,
        areaAr: addressInput?.areaAr,
        streetAddress1Ar: arabicStreetAddress,
        streetAddress1: addressInput?.streetAddress1,
        area: addressInput?.area,
        streetAddress2: addressInput?.streetAddress2,
      },
      workingHours: removeEmptyRanges(working),
    };

    handleOnSubmit(request);
  };

  return {
    methods,
    location,
    errorsForm,
    handleSubmit,
    onSubmit,
    handleLocation,
    watch,
  };
};
