import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { guidedCareProgramEvaluationFieldsSchema } from "../GuidedCareProgramEvaluationFields/GuidedCareProgramEvaluationFieldsSchema";
import { createZodAutocomplete } from "@toolkit/core";

export const guidedCareProgramMemberAddFormSchema = z.object({
  program: zodSchema.guidedCareProgram,
  riskFactorTemplateFields: z.array(guidedCareProgramEvaluationFieldsSchema),
  suggestedProgram: createZodAutocomplete(
    z.object({
      id: z.string(),
      name: z.string().nullish(),
      allowDoctorDirectEnrollment: z.boolean(),
    })
  ).nullish(),
  team: zodSchema.guidedCareTeam.nullish(),
});

export type IGuidedCareProgramMemberAddFormValues = z.infer<typeof guidedCareProgramMemberAddFormSchema>;

export const guidedCareProgramMemberAddFormDefaultValues: Partial<IGuidedCareProgramMemberAddFormValues> = {
  program: undefined,
  riskFactorTemplateFields: [],
  suggestedProgram: undefined,
  team: undefined,
};
