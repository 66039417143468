import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const diagnosisFormSchema = z.object({
  code: zodSchema.systemCode,
  visitDiagnosisType: zodEnumSchema.visitDiagnosisType,
});

export type IDiagnosisFormValues = z.infer<typeof diagnosisFormSchema>;

export const diagnosisFormDefaultValues: Partial<IDiagnosisFormValues> = {
  code: undefined,
  visitDiagnosisType: undefined,
};
