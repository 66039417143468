import { OptimaValidationRequestsSortingField, SortDirection } from "@/schema/types";
import { GridCellText } from "@health/domains";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
// import OptimaVisitResultDetailsModal from "../../components/OptimaVisitResultDetails/OptimaVisitResultDetails";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { EligibilityScanResultModal } from "../../components/EligibilityScanResultModal/EligibilityScanResultModal";
import { IOptimaValidationRequestsListNode } from "../../types";

export const useOptimaPatientsEligibilityRequestsHistoryListContainerColumns =
  (): CustomTableColumnProps<IOptimaValidationRequestsListNode>[] => {
    const { t } = useTranslation("provider");

    return useMemo(() => {
      return [
        {
          key: "id",
          header: t("Request Id"),
          accessor: ({ id }) => <TruncateTypography text={id || "-"} />,
          // filter: {
          //   type: "string",
          //   name: "id",
          // },
        },
        {
          key: "patientName",
          header: t("Patient Name"),
          accessor: ({ patientEligibility }) => <GridCellText text={patientEligibility?.memberName} />,
          filter: {
            type: "string",
            name: "visitId",
          },
        },
        {
          key: "patientId",
          header: t("Patient Id"),
          accessor: ({ patientEligibility }) => <GridCellText text={patientEligibility?.emiratesId} />,
          filter: {
            type: "string",
            name: "patientIdentifier",
          },
        },
        {
          key: "Status",
          header: t("Status"),
          accessor: ({ isFounded, isCompleted }) => (
            <GridCellText text={!isCompleted ? t("Scanning") : `${isFounded ? t("Found") : t("Not Found")}`} />
          ),
        },
        {
          key: "createdDate",
          header: t("Created Date"),
          isSortable: true,
          sortDirection: SortDirection.Desc,
          sortColumnEnum: OptimaValidationRequestsSortingField.Created,
          accessor: ({ createdDate }) => <GridCellText text={format(createdDate, DATE_FORMAT_FOR_TABLE_LONG)} />,
        },
        {
          key: "createdDateFrom",
          header: t("Created Date From"),
          showOnlyForFilterField: true,
          filter: {
            type: "date",
            name: "fromDate",
          },
        },
        {
          key: "isFounded",
          header: t("isFounded"),
          showOnlyForFilterField: true,
          filter: getAutocompleteEnumFilter("YesNo", "isFounded"),
        },
        {
          key: "insuranceName",
          header: t("insuranceName"),
          showOnlyForFilterField: true,
          // filter: getAutocompleteEnumFilter("OptimaAgentPayer", "insuranceName"),
        },
        {
          key: "isComplete",
          header: t("isComplete"),
          showOnlyForFilterField: true,
          filter: getAutocompleteEnumFilter("YesNo", "isComplete"),
        },
        {
          key: "showResultDetails",
          header: t("Details"),
          accessor: ({ id, patientEligibility }) => (
            <EligibilityScanResultModal id={id} patientNationalId={patientEligibility?.emiratesId} />
          ),
        },
      ];
    }, [t]);
  };
