import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { usersPaths, usersRoute } from "./UsersPaths";
import { ProviderUserListContainer, ProviderUserCreateContainer, ProviderUserUpdateContainer } from "../containers";

export const usersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  const hasViewUsersPermission = hasPermission(PermissionEnum.ViewUsers);
  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);

  return {
    id: "users-routes",
    text: i18n.t("Users Management", { ns: "provider" }),
    route: usersRoute,
    isProhibited: !hasViewUsersPermission && !hasManageUsersPermission,
    subItems: [
      {
        id: "user-list-route",
        route: usersPaths.list.route,
        fullPath: usersPaths.list.fullPath,
        element: <ProviderUserListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-create-route",
        route: usersPaths.create.route,
        fullPath: usersPaths.create.fullPath,
        hidden: true,
        element: <ProviderUserCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-update-route",
        route: usersPaths.update.route,
        fullPath: usersPaths.update.fullPath,
        hidden: true,
        element: <ProviderUserUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
