import { useMemo } from "react";
import { useMuteAudio } from "./useMuteAudio";
import { useMuteScreen } from "./useMuteScreen";
import { useMuteVideo } from "./useMuteVideo";
import { useZoom } from "./useZoom";
import { CallParticipant, ZoomParticipant } from "./types";
import { Meeting, User } from "@/schema/types";
import { toBase64 } from "@toolkit/core";

const getCallParticipant = (u: Partial<ZoomParticipant>, participants: Record<string, User>): CallParticipant => {
  const userInfo = u.userIdentity ? participants[u.userIdentity] : undefined;

  return {
    id: u.userId,
    displayName: userInfo?.fullName || u.displayName,
    info: userInfo ?? {
      fullName: u.displayName,
    },
    userIdentity: u.userIdentity,
    isCurrentUser: !!u.isCurrentUser,
    state: { audioMuted: u.muted !== false, videoMuted: u.bVideoOn !== true, screenMuted: u.sharerOn !== true },
  };
};

export function useCallParticipants({
  meeting,
  userId,
  displayName,
}: {
  meeting?: Meeting | undefined;
  userId?: number;
  displayName?: string;
}) {
  const userIdentity = useMemo(() => (userId ? toBase64(`User:${userId}`) : undefined), [userId]);
  const { muteAudio, audioMuted } = useMuteAudio();
  const { muteVideo, videoMuted } = useMuteVideo();
  const { muteScreen, screenMuted } = useMuteScreen();
  const { currentUser, users } = useZoom();

  const meetingParticipants: Record<string, User> = useMemo(
    () => (meeting?.participants || []).reduce((list, u) => ({ ...list, [u.id]: u }), {}),
    [meeting?.participants]
  );

  const currentParticipant = useMemo<CallParticipant>(() => {
    if (currentUser) {
      return getCallParticipant(currentUser, meetingParticipants);
    }

    return getCallParticipant(
      {
        userId: userId ?? 0,
        userIdentity,
        displayName: displayName,
        isCurrentUser: true,
        muted: audioMuted,
        bVideoOn: !videoMuted,
        sharerOn: !screenMuted,
      },
      meetingParticipants
    );
  }, [currentUser, userId, userIdentity, displayName, audioMuted, videoMuted, screenMuted, meetingParticipants]);

  const participants = useMemo<CallParticipant[]>(() => {
    const value = users ? Object.values(users) : [];
    if (value.length === 0 && currentParticipant) {
      return [currentParticipant];
    }

    return value.map(p => getCallParticipant(p, meetingParticipants));
  }, [users, currentParticipant, meetingParticipants]);
  return {
    participants,
    currentParticipant,
    muteAudio,
    muteVideo,
    muteScreen,
  };
}
