import { FC, PropsWithChildren } from "react";
import { GuestCallView } from "./GuestCallView";
import { CallProvider } from "../Call/types";

type GuestCallProps = PropsWithChildren<{
  token: string;
}>;

export const GuestCall: FC<GuestCallProps> = ({ token }) => {
  return (
    <CallProvider>
      <GuestCallView token={token} />
    </CallProvider>
  );
};
