import { Visit } from "@/schema/types";
import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { useVisitQuery } from "../../gql/queries";

export function useVisitStatus(visitId?: string | null) {
  const { data: visitData, refetch, loading, error } = useVisitQuery({ skip: !visitId, variables: { visitId } });
  useOnHealthProgramStatusUpdate(({ data }) => {
    data.graphqlType === "Visit" && data?.id && refetch();
  });

  return { loading, error, handleRetry: refetch, visit: visitData?.visit as Visit | undefined };
}
