/* eslint-disable max-lines */
import { userWorkspaceDoctorVar } from "@/pages/UserWorkspace/vars";
import { GuidedCareHealthProgram, ProviderGuidedCareHealthProgramTeam } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { BranchesAutocomplete, GuidedCareProgramsAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  CustomAlert,
  CustomTable,
  FormActions,
  FormCard,
  FormNumberField,
  FormSwitch,
  FormTextField,
  Grid,
  Link,
  PageWrapper,
  Typography,
  createAutocompleteOption,
} from "@toolkit/ui";
import {
  GuidedCareTeamUpsertFormSchema,
  IGuidedCareTeamUpsertFormValues,
  guidedCareTeamUpsertFormDefaultValues,
} from "pages/HealthProviderManagement/GuidedCare/forms/TeamUpsert/GuidedCareTeamUpsertFormSchema";
import { GuidedCareProgramListQuery, useGuidedCareProgramListLazyQuery } from "pages/HealthProviderManagement/GuidedCare/gql";
import { convertProgramTeamMembersToFormValues, convertTeamToFormValues } from "pages/HealthProviderManagement/GuidedCare/others";
import { IGuidedCareTeamUpsertFormEvent } from "pages/HealthProviderManagement/GuidedCare/types";
import { healthProviderGuidedCareTeamsPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGuidedCareTeamUpsertFormStyle } from "./useGuidedCareTeamUpsertFormStyle";
import { useGuidedCareTeamUpsertTableColumns } from "./useGuidedCareTeamUpsertTableColumns";

type GuidedCareTeamUpsertFormProps = {
  submitButtonLabel: string;
  team?: ProviderGuidedCareHealthProgramTeam;
  program?: GuidedCareHealthProgram;
  isTeamOutdated?: boolean;
  isLoading?: boolean;
  onChange: (event: IGuidedCareTeamUpsertFormEvent) => void;
};

export const GuidedCareTeamUpsertForm: FC<GuidedCareTeamUpsertFormProps> = props => {
  const { submitButtonLabel, team, program, isTeamOutdated, isLoading, onChange } = props;

  const { t } = useTranslation("provider");
  const navigate = useNavigate();

  const { classes } = useGuidedCareTeamUpsertFormStyle();

  const branchUserPreference = useReactiveVar(userWorkspaceDoctorVar);

  const form = useCustomForm<IGuidedCareTeamUpsertFormValues>({
    defaultValues: guidedCareTeamUpsertFormDefaultValues,
    schema: GuidedCareTeamUpsertFormSchema,
  });

  const { setValue, setValues, watch, handleSubmit, setFormDisabled } = form;

  const selectedProgram = watch("program")?.value?.name;
  const selectedBranchId = watch("branch")?.value?.id;
  const teamMembers = watch("teamMembers");

  const columns = useGuidedCareTeamUpsertTableColumns({ selectedBranchId });

  const [fetchProgramListQuery, { loading }] = useGuidedCareProgramListLazyQuery({
    onCompleted: (data: GuidedCareProgramListQuery) => {
      const _teamMembers = data?.guidedCareHealthPrograms?.edges?.[0]?.node?.teamMembers!;

      if (_teamMembers) {
        const _programTeamMembers = convertProgramTeamMembersToFormValues(_teamMembers);
        setValue("teamMembers", _programTeamMembers);
      }
    },
  });

  const onSubmit = (values: IGuidedCareTeamUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleSyncClick = () => {
    if (!team) return;

    navigate(`${healthProviderGuidedCareTeamsPaths.new.fullPath}?teamId=${team?.id}`);
  };

  useEffect(() => {
    setFormDisabled(!!isTeamOutdated);
  }, [isTeamOutdated, setFormDisabled]);

  useEffect(() => {
    if (!team && branchUserPreference?.branch) {
      setValue("branch", createAutocompleteOption(branchUserPreference?.branch!, "id", "name"));
    }
  }, [team, branchUserPreference?.branch, setValue]);

  useEffect(() => {
    if (!isLoading) {
      if (team) {
        const _team = convertTeamToFormValues(team, program);
        setValues(_team);
      } else if (selectedProgram) {
        fetchProgramListQuery({
          variables: {
            filter: {
              name: selectedProgram,
            },
          },
        });
      }
    }
  }, [team, selectedProgram, program, isLoading, setValue, fetchProgramListQuery, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton={!isTeamOutdated}
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isLoading}
            />
          }
        >
          <Grid container spacing={3}>
            {!isLoading && isTeamOutdated && (
              <Grid item xs={12}>
                <CustomAlert level={"module"} appearance='error' title={t("Be Aware!")} disableClose disableMarginBottom>
                  <Typography>
                    {t("The care team is outdated and cannot be updated. Please click")}{" "}
                    <Link onClick={handleSyncClick} className={classes.syncLinkText}>
                      {t("here")}
                    </Link>{" "}
                    {t("to create a new team.")}
                  </Typography>
                </CustomAlert>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Team Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormTextField name={"name"} label={t("Name")} />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormNumberField name={"maxNumberOfPatients"} label={t("Max No of Patients")} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <GuidedCareProgramsAutocomplete name={"program"} disabled={!!team} filter={{ isActive: true }} />
                      </Grid>

                      <Grid item xs={4}>
                        <BranchesAutocomplete name={"branch"} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <FormTextField name={"description"} multiline rows={5} label={t("Description")} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <FormSwitch name={"isActive"} label={t("Is Active")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Assign Team Members")} loading={loading} doYouHaveData>
                {selectedProgram && teamMembers?.length ? (
                  <CustomTable data={teamMembers} columns={columns} hasFooter={false} scrollable />
                ) : (
                  <Typography> {t("Select a program to create a team")} </Typography>
                )}
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
