import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  CircularProgress,
  FormCard,
  FormNumberField,
  FormTextField,
  Grid,
  InputAdornment,
  Typography,
  useTheme,
} from "@toolkit/ui";
import { IVitalSignUpdateFormEvent } from "pages/e-visit/VitalSigns/types";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { IVitalSignsUpdateFormValues, vitalSignUpdateFormSchema, vitalSignsUpdateFormDefaultValues } from "./VitalSignUpdateFormSchema";
import { useHealthParameterVitalSigns } from "../../../../PatientHealthParameters/hooks/useHealthParameterVitalSigns";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";

type VitalSignsUpdateFormProps = {
  visitId: string;
  token?: string;
  isSubmitting?: boolean;
  onChange: (event: IVitalSignUpdateFormEvent) => void;
};

export const VitalSignUpdateForm: FC<VitalSignsUpdateFormProps> = props => {
  const { visitId, token, isSubmitting, onChange } = props;

  const { t } = useTranslation("provider");

  const theme = useTheme();

  const { data, isLoading } = useHealthParameterVitalSigns({
    visitId,
    token,
  });

  const form = useCustomForm<IVitalSignsUpdateFormValues>({
    defaultValues: vitalSignsUpdateFormDefaultValues,
    schema: vitalSignUpdateFormSchema,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = form;

  const items = watch("items");
  const hasValues = getValues()?.items?.some(item => item?.value);
  const hasError = Object.keys(errors).length > 0;

  const onSubmit = (values: IVitalSignsUpdateFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (data?.length) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _data = data?.map(({ source, ...rest }) => rest);
      setValue("items", _data);
    }
  }, [data, setValue]);

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <Grid item xs={12}>
          <Box textAlign={"center"}>
            <CircularProgress size={30} />
          </Box>
        </Grid>
      ) : (
        <>
          {items?.length ? (
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                  <FormCard loading={false} doYouHaveData>
                    <Grid container spacing={1}>
                      {items?.map((item, index) => {
                        if (item?.code === `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`) {
                          return (
                            <Grid key={item?.code} item xs={6}>
                              <FormTextField
                                name={`items.${index}.value`}
                                label={item?.display}
                                placeholder={item?.display}
                                InputProps={{ endAdornment: item?.unit }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          );
                        }

                        return (
                          <Grid key={item?.code} item xs={6}>
                            <FormNumberField
                              name={`items.${index}.value`}
                              label={item?.display}
                              placeholder={item?.display}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end' sx={{ fontSize: 14 }}>
                                    <Typography>{item?.unit}</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{ shrink: true }}
                              isFloatAllowed={
                                item?.code === healthParameterCodes?.oxygenSaturation || item?.code === healthParameterCodes?.temperature
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </FormCard>
                </Grid>

                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                  <Box mx={2}>
                    <Button
                      type={"submit"}
                      disabled={isSubmitting || !hasValues || hasError}
                      endIcon={isSubmitting ? <CircularProgress color={"inherit"} size={20} /> : null}
                    >
                      {t("Update")}
                    </Button>
                  </Box>
                </Grid>
              </form>
            </FormProvider>
          ) : (
            <Grid item xs={12} textAlign={"center"}>
              <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {t("There are no available Vital Signs")}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
