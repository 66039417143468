import { Box, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { useRequestListKpisStyles } from "../../pages/OptimaPriorRequests/components/RequestListKpis/RequestListKpisStyles";

export interface KPIItemProps {
  title: string;
  subtitle: string | number;
  icon: React.ReactNode;
  onClick: () => void;
  color: "success" | "error" | "warning" | "info";
  isSelected?: boolean;
}

export const KPIItem: FC<KPIItemProps> = props => {
  const { title, subtitle, icon, onClick, color, isSelected } = props;
  const { classes, cx } = useRequestListKpisStyles({ color });

  return (
    <Box onClick={onClick} className={cx(classes.kpiCard, isSelected && classes.kpiCard__selected)}>
      <Box className={classes.kpiStartSquare}>{icon}</Box>
      <Box className={classes.kpiTextContainer}>
        <Typography className={classes.kpiTitle}>{title}</Typography>
        <Typography className={classes.kpiSubtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  );
};
