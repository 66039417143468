import { CustomDialog, IconButton, IncrementIcon } from "@toolkit/ui";
import React, { FC } from "react";
import { useOpenState } from "@toolkit/core";
import { GuidedCareProgramMemberAddForm } from "@/shared/modules/patient";
import { useTranslation } from "@toolkit/i18n";
import { Patient } from "@/schema/types";

type GuidedCareTaskActionsEnrollmentJourneyProps = {
  patient: Patient;
  onChange: () => void;
};

export const GuidedCareTaskActionsEnrollmentJourney: FC<GuidedCareTaskActionsEnrollmentJourneyProps> = props => {
  const { patient, onChange } = props;

  const { t } = useTranslation("provider");

  const { open, handleOpen, handleClose } = useOpenState();

  const patientDefaultMember = patient?.activeHealthProgramMembers?.find(item => item?.isDefault);

  const onGuidedCareProgramMemberAddFormChange = () => {
    handleClose();
    onChange();
  };

  return (
    <CustomDialog
      type={"base"}
      open={open}
      maxWidth={"sm"}
      button={
        <IconButton size={"small"} color={"success"} onClick={handleOpen}>
          <IncrementIcon />
        </IconButton>
      }
      DialogTitleProps={{
        title: t("Add Patient to Program"),
        onClose: handleClose,
      }}
    >
      <GuidedCareProgramMemberAddForm
        patientId={patient?.id!}
        patientUserId={patient?.user?.id!}
        payerId={patientDefaultMember?.payer?.id!}
        patientDefaultMemberId={patientDefaultMember?.id!}
        onChange={onGuidedCareProgramMemberAddFormChange}
      />
    </CustomDialog>
  );
};
