import { makeStyles } from "@toolkit/ui";

export const useGuidedCareProgramEvaluationFieldsStyle = makeStyles()(theme => ({
  accordion: {
    borderRadius: `10px !important`,
    border: `1px solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    paddingLeft: 10,
  },
}));
