import { FC, useCallback, useMemo } from "react";
import { ChatGroup } from "../../schema/types";
import moment from "moment";
import { Badge, Typography, ListItem, ListItemAvatar, ListItemText, useTheme, ListItemSecondaryAction, format } from "@toolkit/ui";
import { i18n } from "@toolkit/i18n";
import { ChatAvatar } from "../ChatAvatar";
import { ChatStatus } from "../ChatStatus";
import { ChatTitle } from "../ChatTitle";

export type ChatGroupsItemProps = {
  item: ChatGroup;
  isSelected?: boolean;
  unreadCount?: number;

  onClick?: (value: ChatGroup) => void;
};

export const ChatGroupsItem: FC<ChatGroupsItemProps> = ({ item, isSelected, unreadCount, onClick }) => {
  const theme = useTheme();
  const { createdAt } = item;

  const time = useMemo(() => {
    if (!createdAt) {
      return null;
    }

    const value = moment(createdAt);
    return format(value.toISOString(), value.isSame(moment(), "day") ? "hh:mm A" : "DD/MM/YYYY", i18n.language);
  }, [createdAt]);

  const handleOnClick = useCallback(() => onClick?.(item), [item, onClick]);

  return (
    <ListItem sx={{ cursor: "pointer" }} onClick={handleOnClick} selected={isSelected}>
      <ListItemAvatar>
        <ChatAvatar value={item} size={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<ChatTitle value={item} />}
        primaryTypographyProps={{
          sx: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontWeight: theme.mixins.fonts.fontWeight.medium },
        }}
        secondary={
          <>
            <ChatStatus value={item} />
            <Typography sx={{ position: "absolute", right: 10, bottom: 2 }} color='primary' fontSize={theme.mixins.fonts.fontSize.xxs}>
              {time}
            </Typography>
          </>
        }
      ></ListItemText>
      {unreadCount && unreadCount > 0 && (
        <ListItemSecondaryAction>
          <Badge badgeContent={unreadCount} color='primary' />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
