import { FC } from "react";
import { Box, Button, CallIcon, CircularProgress, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useCallErrorStyles } from "./CallError.styles";

type CallErrorProps = {
  onClick?: () => void;
  isLoading?: boolean;
  hasError?: boolean;
  errorMessage?: string;
};

export const CallError: FC<CallErrorProps> = ({ isLoading, hasError, errorMessage, onClick }) => {
  const { t } = useTranslation("provider");
  const { classes } = useCallErrorStyles();

  return (
    <Box className={classes.root}>
      {isLoading || !hasError ? (
        <CircularProgress color='primary' size={50} />
      ) : (
        <>
          <Box className={classes.icon}>
            <CallIcon color='inherit' sx={{ width: 40, height: 40 }} />
          </Box>
          <h2>{t("Unable to join meeting")}</h2>
          <Typography>{errorMessage}</Typography>
          {onClick && <Button onClick={onClick}>{t("Retry")}</Button>}
        </>
      )}
    </Box>
  );
};
