import { useCallback } from "react";
import { EVENT_TOPIC, TOPIC_LISTENERS, useSubscribeToNotificationTopic } from "../NotificationsServiceProvider";

export const useOnPatientProfileAccessResponse = (handler: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_RESPONSE][number]) => {
  const onNotificationChange: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_RESPONSE][number] = useCallback(data => {
    handler(data);
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_RESPONSE, onNotificationChange);
};

export const useOnPatientProfileAccessRequest = (handler: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST][number]) => {
  const onNotificationChange: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST][number] = useCallback(data => {
    handler(data);
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST, onNotificationChange);
};
export const usePatientProfileAccessClose = (handler: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE][number]) => {
  const onNotificationChange: TOPIC_LISTENERS[EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE][number] = useCallback(data => {
    handler(data);
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE, onNotificationChange);
};
