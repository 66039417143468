import { createPathDefinition } from "@toolkit/core";

export const healthProviderManagementRoute = "/health-provider-management";

export const healthProviderBranchesRoute = `${healthProviderManagementRoute}/branches`;

export const healthProviderBranchesPaths = createPathDefinition(healthProviderBranchesRoute, {
  indexPage: "",
  addNew: "new",
  edit: "edit/:branchId",
  branchUsers: ":branchId/users",
});

export const healthProviderGuidedCareTeamsRoute = `${healthProviderManagementRoute}/guided-care/teams`;

export const healthProviderGuidedCareTeamsPaths = createPathDefinition(healthProviderGuidedCareTeamsRoute, {
  main: "",
  new: "new",
  edit: "edit/:teamId",
  patients: "patients/:teamId",
});
