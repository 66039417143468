import { hasPermission } from "@toolkit/core";
import { dashboardPaths, dashboardRoute } from "./constants/dashboardPaths.constants";
import { TFunction } from "@toolkit/i18n";
import { BarChartIcon } from "@toolkit/ui";
import { allDoctorsRoutes } from "pages/allDoctorsList/route";
import { DashboardContainer } from "pages/dashboard/containers";
import { doctorCallsRatingsRoutes } from "pages/doctorsCallsRatings";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";
import { isVendorAdmin } from "utils";
import { PermissionEnum } from "@/schema/types";

export const dashboardRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  const isUserAVendorAdmin = isVendorAdmin(userInfo);
  const hasDashboardAccess = hasPermission(PermissionEnum.ManageDashboard) || isUserAVendorAdmin;
  return {
    id: "dashboard",
    text: t("Dashboard"),
    icon: <BarChartIcon />,
    route: dashboardRoute,
    hidden: !hasDashboardAccess,
    isProhibited: !hasDashboardAccess,
    subItems: [
      {
        id: dashboardPaths.indexPage.fullPath,
        route: dashboardPaths.indexPage.route,
        fullPath: dashboardPaths.indexPage.fullPath,
        text: t("Dashboard"),
        element: <DashboardContainer userInfo={userInfo} />,
        onClick: route => navigate(route),
        hidden: !hasDashboardAccess,
        isProhibited: !hasDashboardAccess,
      },
      doctorCallsRatingsRoutes({ navigate, t, userInfo }),
      allDoctorsRoutes({ navigate, t, userInfo }),
    ],
  };
};
