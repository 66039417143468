import { CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { GuidedCareProgramMemberAddForm } from "@/shared/modules/patient";
import { Patient } from "@/schema/types";

type GuidedCareProgramMemberAddModalProps = {
  isOpen: boolean;
  patient: Patient;
  onClose: () => void;
  onChange: () => void;
};

export const GuidedCareProgramMemberAddModal: FC<GuidedCareProgramMemberAddModalProps> = props => {
  const { isOpen, patient, onClose, onChange } = props;

  const { t } = useTranslation("provider");

  const patientDefaultMember = patient?.activeHealthProgramMembers?.find(item => item?.isDefault);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"sm"}
      DialogTitleProps={{
        title: t("Add Patient to Program"),
        onClose: onClose,
      }}
    >
      <GuidedCareProgramMemberAddForm
        patientId={patient?.id!}
        patientUserId={patient?.user?.id!}
        patientDefaultMemberId={patientDefaultMember?.id!}
        payerId={patientDefaultMember?.payer?.id!}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
