import { VisitDiagnosis } from "@/schema/types";
import { Box, CustomTable, CustomTableColumnProps } from "@toolkit/ui";
import { FC } from "react";
import { DiagnosisForm, IDiagnosisFormEvent } from "./DiagnosisForm/DiagnosisForm";
import { useColumns } from "./useColumns";
import { useVisitDiagnosesHooks } from "./useVisitDiagnosesHooks";

type DiagnosisProps = {
  visitId: string;
  token?: string;
};

export const Diagnosis: FC<DiagnosisProps> = props => {
  const { visitId, token } = props;

  const columns = useColumns();

  const { loading, visitDiagnoses, handleCreate, handleDelete, handleSelectedVisitDiagnosis, isSubmitting } = useVisitDiagnosesHooks(
    visitId,
    token
  );

  const handleEditRow = row => {
    handleSelectedVisitDiagnosis?.(row);
  };

  const onDiagnosisFormChange = (event: IDiagnosisFormEvent) => {
    if (event.type === "SUBMIT") {
      handleCreate(event.payload.values);
    }
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ marginBottom: "8px" }}>
        <DiagnosisForm token={token} isSubmitting={isSubmitting} onChange={onDiagnosisFormChange} />
      </Box>

      <CustomTable
        data={visitDiagnoses || []}
        isLoading={loading}
        columns={(columns || []) as CustomTableColumnProps<VisitDiagnosis>[]}
        hasFooter={false}
        isDeleteVisible
        isRowEditable
        onRowClick={handleEditRow}
        onDeleteRow={handleDelete}
      />
    </Box>
  );
};

Diagnosis.defaultProps = {
  token: undefined,
};
