import { Box, CustomTable, ShowButton, Typography, useTheme, ValueWithLabel, CopyToClipboardButton, MuiDrawer } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { OptimaValidationInfo } from "../OptimaValidationInfo";
import { OptimaValidationInfoHeader } from "../OptimaValidationInfoHeader";
import React, { FC } from "react";
import { useDiagnosesListColumns } from "../useDiagnosesListColumns";
import { useItemsListColumns } from "../useItemsListColumns";
import { useOpenState } from "@toolkit/core";
import { useOptimaValidationRequestDialogStyles } from "./OptimaValidationRequestDialogStyles";
import { OptimaPatientProfileCard } from "@health/domains";
type OptimaValidationRequestDialogProps = {
  request: string;
};

export const OptimaValidationRequestDialog: FC<OptimaValidationRequestDialogProps> = ({ request }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { open, handleOpen, handleToggle } = useOpenState();

  const getRequests = JSON.parse(request);
  const { classes } = useOptimaValidationRequestDialogStyles();

  return (
    <>
      <ShowButton onClick={handleOpen} />
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleToggle}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {t("Patient Info")}
          </Typography>
          <CopyToClipboardButton textToCopy={request} buttonVariant='text' buttonCopyText={t("Copy Request")} />
        </Box>
        <OptimaPatientProfileCard
          withPaper
          patientId={getRequests?.patientId || ""}
          name={getRequests?.patientName || ""}
          dob={getRequests?.patientDoB || ""}
          gender={getRequests?.patientGender || ""}
          extraInfoNode={
            <Box>
              <ValueWithLabel
                fontSize={theme.mixins.fonts.fontSize.sm}
                direction='row'
                label={t("Weight")}
                value={getRequests.patientWeight ? getRequests.patientWeight + t(" kg") : "-"}
              />
              <ValueWithLabel
                fontSize={theme.mixins.fonts.fontSize.sm}
                direction='row'
                label={t("Height")}
                value={getRequests.patientHeight ? getRequests.patientHeight + t(" cm") : "-"}
              />
            </Box>
          }
        />
        <OptimaValidationInfoHeader title={t("Insurance Info")} />
        <Box m={2}>
          <OptimaValidationInfo title={t("Insurance Payer")} info={getRequests.insurancePayer} />
          <OptimaValidationInfo title={t("Insurance Receiver")} info={getRequests.insuranceReceiver} />
          <OptimaValidationInfo title={t("Insurance MemberId")} info={getRequests.insuranceMemberId} />
          <OptimaValidationInfo title={t("Insurance Policy")} info={getRequests.insurancePolicy} />
          <OptimaValidationInfo title={t("Insurance Policy Location")} info={getRequests.insurancePolicyLocation} />
          <OptimaValidationInfo title={t("Insurance Plan")} info={getRequests.insurancePlan} />
        </Box>

        <OptimaValidationInfoHeader title={t("Diagnoses")} />
        <CustomTable
          data={getRequests.diagnoses}
          columns={useDiagnosesListColumns()}
          scrollable
          hasFooter={false}
          TableContainerProps={{
            sx: {
              minHeight: "inherit !important",
            },
          }}
        />

        <OptimaValidationInfoHeader title={t("Items")} />
        <CustomTable
          data={getRequests.items}
          columns={useItemsListColumns()}
          scrollable
          hasFooter={false}
          TableContainerProps={{
            sx: {
              minHeight: "inherit !important",
            },
          }}
        />
      </MuiDrawer>
    </>
  );
};
