import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceProductsAutocompleteQuery } from "./gql";
import { IMarketplaceProductAutocomplete } from "./schema";

export const createMarketplaceProductAutocompleteOption = (product: IMarketplaceProductAutocomplete) => {
  return createAutocompleteOption(
    {
      id: product?.id!,
      name: product?.name!,
      nameAr: product?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getMarketplaceProductsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "marketplaceProducts",
    query: useMarketplaceProductsAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name!, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "nameContains",
  });
};
