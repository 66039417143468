import { CodeSystemCode } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { visitDiagnosisTypeOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormAutocomplete, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { getApolloContextFormToken } from "@/shared/utils";
import { diagnosisFormSchema, diagnosisFormDefaultValues, IDiagnosisFormValues } from "./DiagnosisFormSchema";

export type IDiagnosisFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IDiagnosisFormValues;
  };
};

type DiagnosisFormProps = {
  token?: string;
  isSubmitting?: boolean;
  onChange: (event: IDiagnosisFormEvent) => void;
};

export const DiagnosisForm: FC<DiagnosisFormProps> = props => {
  const { token, isSubmitting, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IDiagnosisFormValues>({
    defaultValues: diagnosisFormDefaultValues,
    schema: diagnosisFormSchema,
  });

  const { handleSubmit, reset } = form;

  const onSubmit = (values: IDiagnosisFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleResetClick = () => {
    reset();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SystemCodeAutocomplete
              name={"code"}
              filter={{ codeSystemCode: CodeSystemCode.Diagnosis }}
              context={getApolloContextFormToken(token!)}
            />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"visitDiagnosisType"} label={t("Visit Diagnosis Type")} options={visitDiagnosisTypeOptions} />
          </Grid>
        </Grid>

        <Button disabled={isSubmitting} type={"submit"} sx={{ margin: "10px" }}>
          {t("Add Diagnosis")}
        </Button>

        <Button variant={"text"} onClick={handleResetClick}>
          {t("Reset Fields")}
        </Button>
      </form>
    </FormProvider>
  );
};
